export const data = [
  {
    id: '26cd0bf0-5fb4-4311-a913-e564681fbef9',
    text: 'A Day in the Life of "tesla cars"',
    videos: [
      {
        id: 'e2ec314d-8a3c-4b4e-a3f2-8d9db4105e1b',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/0n9WDNEeQ1/D9xcT.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.9891250000000005,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_zsrzde.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'A ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 0.184812,
                stop: 0.369562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.369562,
                stop: 0.434812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.434812,
                stop: 0.510875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 0.510875,
                stop: 0.77175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.77175,
                stop: 0.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.826062,
                stop: 0.902188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 0.902188,
                stop: 1.260875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAR ',
                width: 1080,
                height: 1920,
                start: 1.260875,
                stop: 1.532625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LOOKS ',
                width: 1080,
                height: 1920,
                start: 1.532625,
                stop: 1.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIKE* ',
                width: 1080,
                height: 1920,
                start: 1.75,
                stop: 1.945625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS...',
                width: 1080,
                height: 1920,
                start: 1.945625,
                stop: 2.380438
              }
            ]
          },
          {
            duration: 9.717375,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_qvwpil.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*WAKING* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.35868699999999976
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP ',
                width: 1080,
                height: 1920,
                start: 0.35868699999999976,
                stop: 0.47825000000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.47825000000000006,
                stop: 0.5325629999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.5325629999999997,
                stop: 0.5978129999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MORNING,* ',
                width: 1080,
                height: 1920,
                start: 0.5978129999999995,
                stop: 1.1629999999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 1.1629999999999994,
                stop: 1.2608749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BEGIN ',
                width: 1080,
                height: 1920,
                start: 1.2608749999999995,
                stop: 1.52175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 1.52175,
                stop: 1.6413129999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' JOURNEY ',
                width: 1080,
                height: 1920,
                start: 1.6413129999999994,
                stop: 2.0108749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.0108749999999995,
                stop: 2.1195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVE ',
                width: 1080,
                height: 1920,
                start: 2.1195629999999994,
                stop: 2.3804369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HUMANITY.* ',
                width: 1080,
                height: 1920,
                start: 2.3804369999999997,
                stop: 3.673875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 3.673875,
                stop: 3.8913129999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.8913129999999994,
                stop: 3.97825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ROADS* ',
                width: 1080,
                height: 1920,
                start: 3.97825,
                stop: 4.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 4.27175,
                stop: 4.358686999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SILICON ',
                width: 1080,
                height: 1920,
                start: 4.358686999999999,
                stop: 4.749999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' VALLEY ',
                width: 1080,
                height: 1920,
                start: 4.749999999999999,
                stop: 5.130437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 5.130437,
                stop: 5.2391250000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSTLING* ',
                width: 1080,
                height: 1920,
                start: 5.2391250000000005,
                stop: 5.662999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CITY ',
                width: 1080,
                height: 1920,
                start: 5.662999999999999,
                stop: 5.923875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STREETS,* ',
                width: 1080,
                height: 1920,
                start: 5.923875,
                stop: 6.499999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 6.499999999999999,
                stop: 6.630437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 6.630437,
                stop: 6.793437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ALWAYS* ',
                width: 1080,
                height: 1920,
                start: 6.793437,
                stop: 7.130437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ON ',
                width: 1080,
                height: 1920,
                start: 7.130437,
                stop: 7.249999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 7.249999999999999,
                stop: 7.315186999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MOVE*',
                width: 1080,
                height: 1920,
                start: 7.315186999999999,
                stop: 7.717375
              }
            ]
          },
          {
            duration: 6.5651879000000015,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_zsrzde.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FROM ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.15218800000000066
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HELPING* ',
                width: 1080,
                height: 1920,
                start: 0.15218800000000066,
                stop: 0.4891249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 0.4891249999999996,
                stop: 0.7608750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GET ',
                width: 1080,
                height: 1920,
                start: 0.7608750000000004,
                stop: 0.913062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.913062,
                stop: 0.9891249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WORK ',
                width: 1080,
                height: 1920,
                start: 0.9891249999999996,
                stop: 1.2282499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.2282499999999992,
                stop: 1.2934999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TAKING* ',
                width: 1080,
                height: 1920,
                start: 1.2934999999999999,
                stop: 1.6304379999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' KIDS ',
                width: 1080,
                height: 1920,
                start: 1.6304379999999998,
                stop: 1.8695620000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.8695620000000002,
                stop: 1.9565000000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SCHOOL,* ',
                width: 1080,
                height: 1920,
                start: 1.9565000000000001,
                stop: 2.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TESLA ',
                width: 1080,
                height: 1920,
                start: 2.478249999999999,
                stop: 2.815187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS ',
                width: 1080,
                height: 1920,
                start: 2.815187999999999,
                stop: 3.1956249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 3.1956249999999997,
                stop: 3.3804380000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ALWAYS* ',
                width: 1080,
                height: 1920,
                start: 3.3804380000000016,
                stop: 3.7064999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP ',
                width: 1080,
                height: 1920,
                start: 3.7064999999999984,
                stop: 3.8695619999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 3.8695619999999984,
                stop: 3.9564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.9564999999999984,
                stop: 4.0326249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CHALLENGE',
                width: 1080,
                height: 1920,
                start: 4.0326249999999995,
                stop: 4.565249999999999
              }
            ]
          },
          {
            duration: 6.391312099999997,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_qvwpil.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'AND ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.0761240999999977
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AFTER ',
                width: 1080,
                height: 1920,
                start: 0.0761240999999977,
                stop: 0.39131209999999683
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.39131209999999683,
                stop: 0.39131209999999683
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LONG* ',
                width: 1080,
                height: 1920,
                start: 0.39131209999999683,
                stop: 0.6630620999999977
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY,* ',
                width: 1080,
                height: 1920,
                start: 0.6630620999999977,
                stop: 1.1196240999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 1.1196240999999993,
                stop: 1.2283121000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' COME ',
                width: 1080,
                height: 1920,
                start: 1.2283121000000001,
                stop: 1.4456870999999971
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BACK ',
                width: 1080,
                height: 1920,
                start: 1.4456870999999971,
                stop: 1.652187099999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOME ',
                width: 1080,
                height: 1920,
                start: 1.652187099999999,
                stop: 1.858749999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.858749999999997,
                stop: 1.9565620999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RECHARGE* ',
                width: 1080,
                height: 1920,
                start: 1.9565620999999993,
                stop: 2.565249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.565249999999999,
                stop: 2.6630620999999977
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GET ',
                width: 1080,
                height: 1920,
                start: 2.6630620999999977,
                stop: 2.8478740999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' READY ',
                width: 1080,
                height: 1920,
                start: 2.8478740999999985,
                stop: 3.0217499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.0217499999999973,
                stop: 3.0978740999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DO ',
                width: 1080,
                height: 1920,
                start: 3.0978740999999985,
                stop: 3.2608740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 3.2608740999999988,
                stop: 3.3369999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALL ',
                width: 1080,
                height: 1920,
                start: 3.3369999999999997,
                stop: 3.5217499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AGAIN ',
                width: 1080,
                height: 1920,
                start: 3.5217499999999973,
                stop: 3.7608740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.7608740999999988,
                stop: 3.8261240999999977
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NEXT* ',
                width: 1080,
                height: 1920,
                start: 3.8261240999999977,
                stop: 4.0978740999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DAY',
                width: 1080,
                height: 1920,
                start: 4.0978740999999985,
                stop: 4.3913741
              }
            ]
          },
          {
            duration: 3.184812000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_zsrzde.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.3043750000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.3043750000000003,
                stop: 0.43481200000000086
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.43481200000000086,
                stop: 0.532625000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.532625000000003,
                stop: 0.7174379000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORIES* ',
                width: 1080,
                height: 1920,
                start: 0.7174379000000002,
                stop: 1.1304379000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.1304379000000004,
                stop: 1.3804379000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 1.3804379000000004,
                stop: 1.6848120000000009
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS!',
                width: 1080,
                height: 1920,
                start: 1.6848120000000009,
                stop: 2.1739380000000033
              }
            ]
          }
        ],
        meta: {
          title: 'A Day in the Life of a Tesla Car',
          description:
            'An inspiring story about the lives of Tesla cars and how they help people every day.',
          hashtags: '#Tesla #Cars #Automotive #EV #ElectricVehicles #shorts',
          mediaDescription:
            'A portrait of a Tesla car in motion, driving on a busy street in a city.',
          category: 'Day in the Life',
          prompt: 'tesla cars'
        }
      }
    ],
    category: 'Day in the Life',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_zsrzde.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041659/woxo-videos-user-gallery/_dreaming/blob_qvwpil.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'A day in the life of a Tesla car looks like this...',
            captions:
              'A ~ *day* ~ in ~ the ~ *life* ~ of ~ a ~ *Tesla* ~ car ~ looks ~ *like* ~ this...'
          },
          {
            voiceover:
              'Waking up in the morning, they begin their journey to serve humanity. From the roads of Silicon Valley to bustling city streets, they are always on the move.',
            captions:
              '*Waking* ~ up ~ in ~ the ~ *morning,* ~ they ~ begin ~ their ~ journey ~ to ~ serve ~ *humanity.* ~ From ~ the ~ *roads* ~ of ~ Silicon ~ Valley ~ to ~ *bustling* ~ city ~ *streets,* ~ they ~ are ~ *always* ~ on ~ the ~ *move*'
          },
          {
            voiceover:
              'From helping people get to work to taking kids to school, Tesla cars are always up for the challenge.',
            captions:
              'From ~ *helping* ~ people ~ get ~ to ~ work ~ to ~ *taking* ~ kids ~ to ~ *school,* ~ Tesla ~ cars ~ are ~ *always* ~ up ~ for ~ the ~ challenge'
          },
          {
            voiceover:
              'And after a long day, they come back home to recharge and get ready to do it all again the next day.',
            captions:
              'And ~ after ~ a ~ *long* ~ *day,* ~ they ~ come ~ back ~ home ~ to ~ *recharge* ~ and ~ get ~ ready ~ to ~ do ~ it ~ all ~ again ~ the ~ *next* ~ day'
          },
          {
            voiceover: 'Follow us for more stories about Tesla cars!',
            captions: '*Follow* ~ us ~ for ~ more ~ *stories* ~ about ~ *Tesla* ~ cars!'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, about a day in life of tesla cars and return it as a VALID array of JSON objects (Language English)\n  Example:\n  [\n   {\n    "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: tesla cars",\n     "scenes": [\n      {\n        "voiceover": "A hook similar but not the same to the following ->  How does a day in the life of tesla cars look like?"\n      },\n      {\n        "voiceover": "A brief description about some of the activities that tesla cars do during a regular day. E.g: He wake up early on and eat his lunch at 5pm (max 30 words)"\n      },\n      {\n        "voiceover": "Scene 2 continuation... (max 30 words)"\n      },  \n      {\n        "voiceover": "Scene 3 continuation... (max 30 words)"\n      },        \n      {\n        "voiceover": "A CTA to follow me for more"\n      }\n     ],\n     "meta": {\n      "title": "Video Title",\n      "description": "A short description of the video for Youtube",\n      "hashtags": "a list of hashtags separated by space related to the video",   \n      "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: tesla cars"  \n     }\n   }\n]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/0n9WDNEeQ1/D9xcT.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">A  day  in  the  life  of  a  Tesla  car  looks  like  this...</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Waking  up  in  the  morning,  they  begin  their  journey  to  serve  humanity.  From  the  roads  of  Silicon  Valley  to  bustling  city  streets,  they  are  always  on  the  move</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">From  helping  people  get  to  work  to  taking  kids  to  school,  Tesla  cars  are  always  up  for  the  challenge</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">And  after  a  long  day,  they  come  back  home  to  recharge  and  get  ready  to  do  it  all  again  the  next  day</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  us  for  more  stories  about  Tesla  cars!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.282562
              },
              {
                markName: 'scene_1',
                timeSeconds: 11.75
              },
              {
                markName: 'scene_2',
                timeSeconds: 17.31525
              },
              {
                markName: 'scene_3',
                timeSeconds: 22.706562
              },
              {
                markName: 'scene_4',
                timeSeconds: 25.782625
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'A',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 262,
                wordLength: 1
              },
              {
                word: 'day',
                timeSeconds: 0.184812,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 265,
                wordLength: 3
              },
              {
                word: 'in',
                timeSeconds: 0.369562,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 270,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 0.434812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 274,
                wordLength: 3
              },
              {
                word: 'life',
                timeSeconds: 0.510875,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 279,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 0.77175,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 285,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 0.826062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 289,
                wordLength: 1
              },
              {
                word: 'Tesla',
                timeSeconds: 0.902188,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 292,
                wordLength: 5
              },
              {
                word: 'car',
                timeSeconds: 1.260875,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 299,
                wordLength: 3
              },
              {
                word: 'looks',
                timeSeconds: 1.532625,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 304,
                wordLength: 5
              },
              {
                word: 'like',
                timeSeconds: 1.75,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 311,
                wordLength: 4
              },
              {
                word: 'this',
                timeSeconds: 1.945625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 317,
                wordLength: 4
              },
              {
                word: '...',
                timeSeconds: 2.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 321,
                wordLength: 3
              },
              {
                word: 'Waking',
                timeSeconds: 4.032625,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 406,
                wordLength: 6
              },
              {
                word: 'up',
                timeSeconds: 4.391312,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 414,
                wordLength: 2
              },
              {
                word: 'in',
                timeSeconds: 4.510875,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 418,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 4.565188,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 422,
                wordLength: 3
              },
              {
                word: 'morning',
                timeSeconds: 4.630438,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 427,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 5.086938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 434,
                wordLength: 1
              },
              {
                word: 'they',
                timeSeconds: 5.195625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 437,
                wordLength: 4
              },
              {
                word: 'begin',
                timeSeconds: 5.2935,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 443,
                wordLength: 5
              },
              {
                word: 'their',
                timeSeconds: 5.554375,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 450,
                wordLength: 5
              },
              {
                word: 'journey',
                timeSeconds: 5.673938,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 457,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 6.0435,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 466,
                wordLength: 2
              },
              {
                word: 'serve',
                timeSeconds: 6.152188,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 470,
                wordLength: 5
              },
              {
                word: 'humanity',
                timeSeconds: 6.413062,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 477,
                wordLength: 8
              },
              {
                word: '.',
                timeSeconds: 6.967375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 485,
                wordLength: 1
              },
              {
                word: 'From',
                timeSeconds: 7.7065,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 488,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 7.923938,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 494,
                wordLength: 3
              },
              {
                word: 'roads',
                timeSeconds: 8.010875,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 499,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 8.304375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 506,
                wordLength: 2
              },
              {
                word: 'Silicon',
                timeSeconds: 8.391312,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 510,
                wordLength: 7
              },
              {
                word: 'Valley',
                timeSeconds: 8.782625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 519,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 9.163062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 527,
                wordLength: 2
              },
              {
                word: 'bustling',
                timeSeconds: 9.27175,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 531,
                wordLength: 8
              },
              {
                word: 'city',
                timeSeconds: 9.695625,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 541,
                wordLength: 4
              },
              {
                word: 'streets',
                timeSeconds: 9.9565,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 547,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 10.5,
                boundaryType: 'PunctuationBoundary',
                duration: 0.032625,
                textOffset: 554,
                wordLength: 1
              },
              {
                word: 'they',
                timeSeconds: 10.532625,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 557,
                wordLength: 4
              },
              {
                word: 'are',
                timeSeconds: 10.663062,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 563,
                wordLength: 3
              },
              {
                word: 'always',
                timeSeconds: 10.826062,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 568,
                wordLength: 6
              },
              {
                word: 'on',
                timeSeconds: 11.163062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 576,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 11.282625,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 580,
                wordLength: 3
              },
              {
                word: 'move',
                timeSeconds: 11.347812,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 585,
                wordLength: 4
              },
              {
                word: 'From',
                timeSeconds: 12.75,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 671,
                wordLength: 4
              },
              {
                word: 'helping',
                timeSeconds: 12.902188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 677,
                wordLength: 7
              },
              {
                word: 'people',
                timeSeconds: 13.239125,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 686,
                wordLength: 6
              },
              {
                word: 'get',
                timeSeconds: 13.510875,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 694,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 13.663062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 699,
                wordLength: 2
              },
              {
                word: 'work',
                timeSeconds: 13.739125,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 703,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 13.97825,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 709,
                wordLength: 2
              },
              {
                word: 'taking',
                timeSeconds: 14.0435,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 713,
                wordLength: 6
              },
              {
                word: 'kids',
                timeSeconds: 14.380438,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 721,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 14.619562,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 727,
                wordLength: 2
              },
              {
                word: 'school',
                timeSeconds: 14.7065,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 731,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 15.173938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.054312,
                textOffset: 737,
                wordLength: 1
              },
              {
                word: 'Tesla',
                timeSeconds: 15.22825,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 740,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 15.565188,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 747,
                wordLength: 4
              },
              {
                word: 'are',
                timeSeconds: 15.945625,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 753,
                wordLength: 3
              },
              {
                word: 'always',
                timeSeconds: 16.130438,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 758,
                wordLength: 6
              },
              {
                word: 'up',
                timeSeconds: 16.4565,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 766,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 16.619562,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 770,
                wordLength: 3
              },
              {
                word: 'the',
                timeSeconds: 16.7065,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 775,
                wordLength: 3
              },
              {
                word: 'challenge',
                timeSeconds: 16.782625,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 780,
                wordLength: 9
              },
              {
                word: 'And',
                timeSeconds: 18.3151879,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 871,
                wordLength: 3
              },
              {
                word: 'after',
                timeSeconds: 18.391312,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 876,
                wordLength: 5
              },
              {
                word: 'a',
                timeSeconds: 18.641312,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 883,
                wordLength: 1
              },
              {
                word: 'long',
                timeSeconds: 18.7065,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 886,
                wordLength: 4
              },
              {
                word: 'day',
                timeSeconds: 18.97825,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 892,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 19.369562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 895,
                wordLength: 1
              },
              {
                word: 'they',
                timeSeconds: 19.434812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 898,
                wordLength: 4
              },
              {
                word: 'come',
                timeSeconds: 19.5435,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 904,
                wordLength: 4
              },
              {
                word: 'back',
                timeSeconds: 19.760875,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 910,
                wordLength: 4
              },
              {
                word: 'home',
                timeSeconds: 19.967375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 916,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 20.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 922,
                wordLength: 2
              },
              {
                word: 'recharge',
                timeSeconds: 20.27175,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 926,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 20.8804379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 936,
                wordLength: 3
              },
              {
                word: 'get',
                timeSeconds: 20.97825,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 941,
                wordLength: 3
              },
              {
                word: 'ready',
                timeSeconds: 21.163062,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 946,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 21.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 953,
                wordLength: 2
              },
              {
                word: 'do',
                timeSeconds: 21.413062,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 957,
                wordLength: 2
              },
              {
                word: 'it',
                timeSeconds: 21.576062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 961,
                wordLength: 2
              },
              {
                word: 'all',
                timeSeconds: 21.6521879,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 965,
                wordLength: 3
              },
              {
                word: 'again',
                timeSeconds: 21.8369379,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 970,
                wordLength: 5
              },
              {
                word: 'the',
                timeSeconds: 22.076062,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 977,
                wordLength: 3
              },
              {
                word: 'next',
                timeSeconds: 22.141312,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 982,
                wordLength: 4
              },
              {
                word: 'day',
                timeSeconds: 22.413062,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 988,
                wordLength: 3
              },
              {
                word: 'Follow',
                timeSeconds: 23.7065,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1073,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 24.010875,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1081,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 24.141312,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1085,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 24.239125,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 1090,
                wordLength: 4
              },
              {
                word: 'stories',
                timeSeconds: 24.4239379,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 1096,
                wordLength: 7
              },
              {
                word: 'about',
                timeSeconds: 24.8369379,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1105,
                wordLength: 5
              },
              {
                word: 'Tesla',
                timeSeconds: 25.0869379,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1112,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 25.391312,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 1119,
                wordLength: 4
              },
              {
                word: '!',
                timeSeconds: 25.7935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1123,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3'
    ]
  },
  {
    id: 'a85a7930-1810-4c25-ad10-76ddb8cddd80',
    text: 'A Horror Story about "tesla cars"',
    videos: [
      {
        id: '53c0b485-5659-46c6-b07d-7cf39e8d1e67',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/skaters_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/75WJP06wxl/MGO48.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.086938,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_hovvp5.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ARE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1739379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.1739379,
                stop: 0.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *READY* ',
                width: 1080,
                height: 1920,
                start: 0.27175,
                stop: 0.489125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.489125,
                stop: 0.554375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEAR ',
                width: 1080,
                height: 1920,
                start: 0.554375,
                stop: 0.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.804375,
                stop: 0.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HORROR* ',
                width: 1080,
                height: 1920,
                start: 0.804375,
                stop: 1.0978119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STORY ',
                width: 1080,
                height: 1920,
                start: 1.0978119,
                stop: 1.445625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.445625,
                stop: 1.684812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 1.684812,
                stop: 1.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARS?*',
                width: 1080,
                height: 1920,
                start: 1.989125,
                stop: 2.47825
              }
            ]
          },
          {
            duration: 6.869562,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_ws2lyq.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'PEOPLE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.30437400000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TELL ',
                width: 1080,
                height: 1920,
                start: 0.30437400000000014,
                stop: 0.5326240000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TALES* ',
                width: 1080,
                height: 1920,
                start: 0.5326240000000002,
                stop: 0.8369369999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.8369369999999998,
                stop: 0.9347500000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 0.9347500000000002,
                stop: 1.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS ',
                width: 1080,
                height: 1920,
                start: 1.27175,
                stop: 1.5760620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 1.5760620000000003,
                stop: 1.6956240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DRIVE* ',
                width: 1080,
                height: 1920,
                start: 1.6956240000000005,
                stop: 2.02175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITHOUT ',
                width: 1080,
                height: 1920,
                start: 2.02175,
                stop: 2.2935
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DRIVERS, ',
                width: 1080,
                height: 1920,
                start: 2.2935,
                stop: 3
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HAUNTING* ',
                width: 1080,
                height: 1920,
                start: 3,
                stop: 3.336937
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DESOLATE ',
                width: 1080,
                height: 1920,
                start: 3.336937,
                stop: 3.77175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ROADS ',
                width: 1080,
                height: 1920,
                start: 3.77175,
                stop: 4.108687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.108687,
                stop: 4.1956240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.1956240000000005,
                stop: 4.304374000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DEAD* ',
                width: 1080,
                height: 1920,
                start: 4.304374000000001,
                stop: 4.4673739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 4.4673739999999995,
                stop: 4.5435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NIGHT*',
                width: 1080,
                height: 1920,
                start: 4.5435,
                stop: 4.869562
              }
            ]
          },
          {
            duration: 6.695625,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_hovvp5.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "IT'S ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1739379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SAID ',
                width: 1080,
                height: 1920,
                start: 0.1739379999999997,
                stop: 0.36956200000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 0.36956200000000017,
                stop: 0.4891249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THESE ',
                width: 1080,
                height: 1920,
                start: 0.4891249999999996,
                stop: 0.6848120000000009
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS ',
                width: 1080,
                height: 1920,
                start: 0.6848120000000009,
                stop: 1.0760620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 1.0760620000000003,
                stop: 1.1956249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CURSED*, ',
                width: 1080,
                height: 1920,
                start: 1.1956249999999997,
                stop: 1.7282499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SEARCHING* ',
                width: 1080,
                height: 1920,
                start: 1.7282499999999992,
                stop: 2.163062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 2.163062,
                stop: 2.271750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.271750000000001,
                stop: 2.391311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LONG-LOST* ',
                width: 1080,
                height: 1920,
                start: 2.391311999999999,
                stop: 2.891311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MASTERS*, ',
                width: 1080,
                height: 1920,
                start: 2.891311999999999,
                stop: 3.6521880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NEVER ',
                width: 1080,
                height: 1920,
                start: 3.6521880000000007,
                stop: 3.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.9565,
                stop: 4.021750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 4.021750000000001,
                stop: 4.163062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' REUNITED',
                width: 1080,
                height: 1920,
                start: 4.163062,
                stop: 4.6956869999999995
              }
            ]
          },
          {
            duration: 5.630437000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_ws2lyq.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "IT'S ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17393700000000045
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SAID ',
                width: 1080,
                height: 1920,
                start: 0.17393700000000045,
                stop: 0.3804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 0.3804370000000006,
                stop: 0.5326249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ANYONE ',
                width: 1080,
                height: 1920,
                start: 0.5326249999999995,
                stop: 0.8152499999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHO ',
                width: 1080,
                height: 1920,
                start: 0.8152499999999989,
                stop: 0.9021870000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SEES ',
                width: 1080,
                height: 1920,
                start: 0.9021870000000014,
                stop: 1.1630629
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONE ',
                width: 1080,
                height: 1920,
                start: 1.1630629,
                stop: 1.2935000000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.2935000000000016,
                stop: 1.3695629000000018
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THESE ',
                width: 1080,
                height: 1920,
                start: 1.3695629000000018,
                stop: 1.5217500000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GHOSTLY* ',
                width: 1080,
                height: 1920,
                start: 1.5217500000000008,
                stop: 1.9130629
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS ',
                width: 1080,
                height: 1920,
                start: 1.9130629,
                stop: 2.2935000000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WILL ',
                width: 1080,
                height: 1920,
                start: 2.2935000000000016,
                stop: 2.3804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 2.3804370000000006,
                stop: 2.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CURSED* ',
                width: 1080,
                height: 1920,
                start: 2.5,
                stop: 2.869562900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 2.869562900000002,
                stop: 3
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MISFORTUNE*',
                width: 1080,
                height: 1920,
                start: 3,
                stop: 3.608688000000001
              }
            ]
          },
          {
            duration: 2.956562999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_hovvp5.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-rotate'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.3152499999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 0.3152499999999989,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.6195629999999994,
                stop: 0.826125900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SPINE-TINGLING* ',
                width: 1080,
                height: 1920,
                start: 0.826125900000001,
                stop: 1.4565629999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STORIES!',
                width: 1080,
                height: 1920,
                start: 1.4565629999999992,
                stop: 2.076063900000001
              }
            ]
          }
        ],
        meta: {
          title: 'The Cursed Tesla Cars',
          description:
            'A horror story about the mysterious tesla cars that haunt the roads in the dead of night.',
          hashtags: '#tesla #cars #horror #story #ghosts #cursed #shorts',
          mediaDescription:
            'A picture of a tesla car driving on a desolate road in the dead of night.',
          category: 'Horror Story',
          prompt: 'tesla cars'
        }
      }
    ],
    category: 'Horror Story',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_hovvp5.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041663/woxo-videos-user-gallery/_dreaming/blob_ws2lyq.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Are you ready to hear a horror story about tesla cars?',
            captions:
              'Are ~ you ~ *ready* ~ to ~ hear ~ a ~ *horror* ~ story ~ about ~ *Tesla* ~ *cars?*'
          },
          {
            voiceover:
              'People tell tales of tesla cars that drive without drivers, haunting desolate roads in the dead of night.',
            captions:
              'People ~ tell ~ *tales* ~ of ~ *Tesla* ~ cars ~ that ~ *drive* ~ without ~ drivers, ~ *haunting* ~ desolate ~ roads ~ in ~ the ~ *dead* ~ of ~ *night*'
          },
          {
            voiceover:
              "It's said that these cars are cursed, searching for their long-lost masters, never to be reunited.",
            captions:
              "It's ~ said ~ that ~ these ~ cars ~ are ~ *cursed*, ~ *searching* ~ for ~ their ~ *long-lost* ~ *masters*, ~ never ~ to ~ be ~ reunited"
          },
          {
            voiceover:
              "It's said that anyone who sees one of these ghostly cars will be cursed with misfortune.",
            captions:
              "It's ~ said ~ that ~ anyone ~ who ~ sees ~ one ~ of ~ these ~ *ghostly* ~ cars ~ will ~ be ~ *cursed* ~ with ~ *misfortune*"
          },
          {
            voiceover: 'Follow me for more spine-tingling stories!',
            captions: '*Follow* ~ me ~ for ~ more ~ *spine-tingling* ~ stories!'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a horror story about tesla cars and return it as a VALID array of JSON objects (Language English)\n   Example:\n   [\n    {\n      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: tesla cars",          \n      "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an scary story about tesla cars?"\n        },\n        {\n          "voiceover": "Write an horror story about tesla cars (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },          \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n      ],\n      "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: tesla cars"\n      }\n    }\n ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/75WJP06wxl/MGO48.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Are  you  ready  to  hear  a  horror  story  about  Tesla  cars?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">People  tell  tales  of  Tesla  cars  that  drive  without  drivers,  haunting  desolate  roads  in  the  dead  of  night</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">It\'s  said  that  these  cars  are  cursed,  searching  for  their  long-lost  masters,  never  to  be  reunited</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">It\'s  said  that  anyone  who  sees  one  of  these  ghostly  cars  will  be  cursed  with  misfortune</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  me  for  more  spine-tingling  stories!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.380438
              },
              {
                markName: 'scene_1',
                timeSeconds: 9
              },
              {
                markName: 'scene_2',
                timeSeconds: 14.695688
              },
              {
                markName: 'scene_3',
                timeSeconds: 19.304312
              },
              {
                markName: 'scene_4',
                timeSeconds: 22.304375
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Are',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'you',
                timeSeconds: 0.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 267,
                wordLength: 3
              },
              {
                word: 'ready',
                timeSeconds: 0.27175,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 272,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 0.489125,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 279,
                wordLength: 2
              },
              {
                word: 'hear',
                timeSeconds: 0.554375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 283,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.760875,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 289,
                wordLength: 1
              },
              {
                word: 'horror',
                timeSeconds: 0.804375,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 292,
                wordLength: 6
              },
              {
                word: 'story',
                timeSeconds: 1.0978119,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 300,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 1.445625,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 307,
                wordLength: 5
              },
              {
                word: 'Tesla',
                timeSeconds: 1.684812,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 314,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 1.989125,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 321,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.391312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 325,
                wordLength: 1
              },
              {
                word: 'People',
                timeSeconds: 4.130438,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 408,
                wordLength: 6
              },
              {
                word: 'tell',
                timeSeconds: 4.434812,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 416,
                wordLength: 4
              },
              {
                word: 'tales',
                timeSeconds: 4.663062,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 422,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 4.967375,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 429,
                wordLength: 2
              },
              {
                word: 'Tesla',
                timeSeconds: 5.065188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 433,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 5.402188,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 440,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 5.7065,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 446,
                wordLength: 4
              },
              {
                word: 'drive',
                timeSeconds: 5.826062,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 452,
                wordLength: 5
              },
              {
                word: 'without',
                timeSeconds: 6.152188,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 459,
                wordLength: 7
              },
              {
                word: 'drivers',
                timeSeconds: 6.423938,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 468,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 7.032625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 475,
                wordLength: 1
              },
              {
                word: 'haunting',
                timeSeconds: 7.130438,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 478,
                wordLength: 8
              },
              {
                word: 'desolate',
                timeSeconds: 7.467375,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 488,
                wordLength: 8
              },
              {
                word: 'roads',
                timeSeconds: 7.902188,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 498,
                wordLength: 5
              },
              {
                word: 'in',
                timeSeconds: 8.239125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 505,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 8.326062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 509,
                wordLength: 3
              },
              {
                word: 'dead',
                timeSeconds: 8.434812,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 514,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 8.597812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 520,
                wordLength: 2
              },
              {
                word: 'night',
                timeSeconds: 8.673938,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 524,
                wordLength: 5
              },
              {
                word: "It's",
                timeSeconds: 10,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 611,
                wordLength: 4
              },
              {
                word: 'said',
                timeSeconds: 10.173938,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 617,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 10.369562,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 623,
                wordLength: 4
              },
              {
                word: 'these',
                timeSeconds: 10.489125,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 629,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 10.684812,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 636,
                wordLength: 4
              },
              {
                word: 'are',
                timeSeconds: 11.076062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 642,
                wordLength: 3
              },
              {
                word: 'cursed',
                timeSeconds: 11.195625,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 647,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 11.673938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.054312,
                textOffset: 653,
                wordLength: 1
              },
              {
                word: 'searching',
                timeSeconds: 11.72825,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 656,
                wordLength: 9
              },
              {
                word: 'for',
                timeSeconds: 12.163062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 667,
                wordLength: 3
              },
              {
                word: 'their',
                timeSeconds: 12.27175,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 672,
                wordLength: 5
              },
              {
                word: 'long-lost',
                timeSeconds: 12.391312,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 679,
                wordLength: 9
              },
              {
                word: 'masters',
                timeSeconds: 12.891312,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 690,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 13.489125,
                boundaryType: 'PunctuationBoundary',
                duration: 0.163062,
                textOffset: 697,
                wordLength: 1
              },
              {
                word: 'never',
                timeSeconds: 13.652188,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 700,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 13.9565,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 707,
                wordLength: 2
              },
              {
                word: 'be',
                timeSeconds: 14.02175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 711,
                wordLength: 2
              },
              {
                word: 'reunited',
                timeSeconds: 14.163062,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 715,
                wordLength: 8
              },
              {
                word: "It's",
                timeSeconds: 15.695625,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 805,
                wordLength: 4
              },
              {
                word: 'said',
                timeSeconds: 15.869562,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 811,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 16.076062,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 817,
                wordLength: 4
              },
              {
                word: 'anyone',
                timeSeconds: 16.22825,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 823,
                wordLength: 6
              },
              {
                word: 'who',
                timeSeconds: 16.510875,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 831,
                wordLength: 3
              },
              {
                word: 'sees',
                timeSeconds: 16.597812,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 836,
                wordLength: 4
              },
              {
                word: 'one',
                timeSeconds: 16.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 842,
                wordLength: 3
              },
              {
                word: 'of',
                timeSeconds: 16.989125,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 847,
                wordLength: 2
              },
              {
                word: 'these',
                timeSeconds: 17.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 851,
                wordLength: 5
              },
              {
                word: 'ghostly',
                timeSeconds: 17.217375,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 858,
                wordLength: 7
              },
              {
                word: 'cars',
                timeSeconds: 17.6086879,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 867,
                wordLength: 4
              },
              {
                word: 'will',
                timeSeconds: 17.989125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 873,
                wordLength: 4
              },
              {
                word: 'be',
                timeSeconds: 18.076062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 879,
                wordLength: 2
              },
              {
                word: 'cursed',
                timeSeconds: 18.195625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 883,
                wordLength: 6
              },
              {
                word: 'with',
                timeSeconds: 18.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 891,
                wordLength: 4
              },
              {
                word: 'misfortune',
                timeSeconds: 18.695625,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 897,
                wordLength: 10
              },
              {
                word: 'Follow',
                timeSeconds: 20.326062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 989,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 20.641312,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 997,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 20.826062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1001,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 20.945625,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1006,
                wordLength: 4
              },
              {
                word: 'spine-tingling',
                timeSeconds: 21.1521879,
                boundaryType: 'WordBoundary',
                duration: 0.619562,
                textOffset: 1012,
                wordLength: 14
              },
              {
                word: 'stories',
                timeSeconds: 21.782625,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 1028,
                wordLength: 7
              },
              {
                word: '!',
                timeSeconds: 22.3151879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1035,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/skaters_MOODHALO.mp3'
    ]
  },
  {
    id: 'efd87177-447b-49aa-896f-b121a992a453',
    text: 'A Bedstory about "tesla cars"',
    videos: [
      {
        id: '93490183-dd30-484d-a469-cf7463267295',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Orbits_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/JqYwdIX11r/4zgR9.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 6.315188,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_fq3rd3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.239125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KIDS!* ',
                width: 1080,
                height: 1920,
                start: 0.239125,
                stop: 1.4565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WANT ',
                width: 1080,
                height: 1920,
                start: 1.4565,
                stop: 1.6413119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.6413119,
                stop: 1.695625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEAR ',
                width: 1080,
                height: 1920,
                start: 1.695625,
                stop: 1.858688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 1.858688,
                stop: 1.913062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 1.913062,
                stop: 2.2935
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORY* ',
                width: 1080,
                height: 1920,
                start: 2.2935,
                stop: 2.641312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 2.641312,
                stop: 2.891312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 2.891312,
                stop: 3.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARS?*',
                width: 1080,
                height: 1920,
                start: 3.2065,
                stop: 3.7065
              }
            ]
          },
          {
            duration: 8.619561999999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_mayiam.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ONCE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.2608740000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UPON ',
                width: 1080,
                height: 1920,
                start: 0.2608740000000003,
                stop: 0.5108740000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.5108740000000003,
                stop: 0.5652499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME, ',
                width: 1080,
                height: 1920,
                start: 0.5652499999999998,
                stop: 1.0652499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THERE ',
                width: 1080,
                height: 1920,
                start: 1.0652499999999998,
                stop: 1.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 1.184812,
                stop: 1.3804369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.3804369999999997,
                stop: 1.3804369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FAMILY* ',
                width: 1080,
                height: 1920,
                start: 1.3804369999999997,
                stop: 1.826124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 1.826124,
                stop: 1.9673740000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WANTED ',
                width: 1080,
                height: 1920,
                start: 1.9673740000000004,
                stop: 2.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.2065,
                stop: 2.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SAVE* ',
                width: 1080,
                height: 1920,
                start: 2.27175,
                stop: 2.52175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.52175,
                stop: 2.6086869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLANET*. ',
                width: 1080,
                height: 1920,
                start: 2.6086869999999998,
                stop: 3.7935000000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO, ',
                width: 1080,
                height: 1920,
                start: 3.7935000000000008,
                stop: 4.347812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 4.347812,
                stop: 4.456499999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DECIDED* ',
                width: 1080,
                height: 1920,
                start: 4.456499999999999,
                stop: 4.858687000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 4.858687000000001,
                stop: 4.923937
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUY ',
                width: 1080,
                height: 1920,
                start: 4.923937,
                stop: 5.086937
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 5.086937,
                stop: 5.163062000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 5.163062000000001,
                stop: 5.510874
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAR!',
                width: 1080,
                height: 1920,
                start: 5.510874,
                stop: 5.967373999999999
              }
            ]
          },
          {
            duration: 8.97825,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_fq3rd3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.10868800000000078
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 0.10868800000000078,
                stop: 0.44568800000000053
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAR ',
                width: 1080,
                height: 1920,
                start: 0.44568800000000053,
                stop: 0.7065620000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 0.7065620000000017,
                stop: 0.8261250000000011
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ELECTRIC*, ',
                width: 1080,
                height: 1920,
                start: 0.8261250000000011,
                stop: 1.4565620000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO ',
                width: 1080,
                height: 1920,
                start: 1.4565620000000017,
                stop: 1.641312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 1.641312000000001,
                stop: 1.7065620000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DID ',
                width: 1080,
                height: 1920,
                start: 1.7065620000000017,
                stop: 1.836938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NOT ',
                width: 1080,
                height: 1920,
                start: 1.836938,
                stop: 2.0543750000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PRODUCE* ',
                width: 1080,
                height: 1920,
                start: 2.0543750000000003,
                stop: 2.4565620000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ANY ',
                width: 1080,
                height: 1920,
                start: 2.4565620000000017,
                stop: 2.6521880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SMOG ',
                width: 1080,
                height: 1920,
                start: 2.6521880000000007,
                stop: 3.086938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OR ',
                width: 1080,
                height: 1920,
                start: 3.086938,
                stop: 3.2173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' POLLUTION. ',
                width: 1080,
                height: 1920,
                start: 3.2173750000000005,
                stop: 4.456562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 4.456562000000002,
                stop: 4.586937900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 4.586937900000002,
                stop: 4.7173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALSO ',
                width: 1080,
                height: 1920,
                start: 4.7173750000000005,
                stop: 5.010875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SUPER* ',
                width: 1080,
                height: 1920,
                start: 5.010875000000002,
                stop: 5.3586879
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COOL* ',
                width: 1080,
                height: 1920,
                start: 5.3586879,
                stop: 5.6304379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.6304379,
                stop: 5.7173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FAST!*',
                width: 1080,
                height: 1920,
                start: 5.7173750000000005,
                stop: 6.326063000000001
              }
            ]
          },
          {
            duration: 6.923938000000003,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_mayiam.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.11956200000000194
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FAMILY* ',
                width: 1080,
                height: 1920,
                start: 0.11956200000000194,
                stop: 0.48912500000000136
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 0.48912500000000136,
                stop: 0.6413120000000028
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO ',
                width: 1080,
                height: 1920,
                start: 0.6413120000000028,
                stop: 0.8478750000000019
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HAPPY* ',
                width: 1080,
                height: 1920,
                start: 0.8478750000000019,
                stop: 1.163062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 1.163062,
                stop: 1.282625000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 1.282625000000003,
                stop: 1.3804379000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DROVE ',
                width: 1080,
                height: 1920,
                start: 1.3804379000000004,
                stop: 1.663062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 1.663062,
                stop: 1.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EVERYWHERE*, ',
                width: 1080,
                height: 1920,
                start: 1.75,
                stop: 2.4674379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.4674379,
                stop: 2.532625000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVEN ',
                width: 1080,
                height: 1920,
                start: 2.532625000000003,
                stop: 2.7283120000000025
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HELPED* ',
                width: 1080,
                height: 1920,
                start: 2.7283120000000025,
                stop: 3.021750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OTHER ',
                width: 1080,
                height: 1920,
                start: 3.021750000000001,
                stop: 3.2174379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FAMILIES* ',
                width: 1080,
                height: 1920,
                start: 3.2174379,
                stop: 3.695687900000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GET* ',
                width: 1080,
                height: 1920,
                start: 3.695687900000003,
                stop: 3.858687900000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 3.858687900000003,
                stop: 4.032625000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OWN ',
                width: 1080,
                height: 1920,
                start: 4.032625000000003,
                stop: 4.184812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TESLA* ',
                width: 1080,
                height: 1920,
                start: 4.184812000000001,
                stop: 4.521750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CARS',
                width: 1080,
                height: 1920,
                start: 4.521750000000001,
                stop: 4.923938
              }
            ]
          },
          {
            duration: 2.9673739999999995,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_fq3rd3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.30437399999999926
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.30437399999999926,
                stop: 0.4347499999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.4347499999999975,
                stop: 0.5217499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.5217499999999973,
                stop: 0.7064999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORIES* ',
                width: 1080,
                height: 1920,
                start: 0.7064999999999984,
                stop: 1.1195619999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LIKE ',
                width: 1080,
                height: 1920,
                start: 1.1195619999999984,
                stop: 1.3043739999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS ',
                width: 1080,
                height: 1920,
                start: 1.3043739999999993,
                stop: 1.4673739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONE!',
                width: 1080,
                height: 1920,
                start: 1.4673739999999995,
                stop: 1.836938
              }
            ]
          }
        ],
        meta: {
          title: 'Tesla Cars: A Family Story',
          description: 'Learn about the amazing Tesla cars in this family story!',
          hashtags: '#tesla #teslacars #electriccars #familystory #amazingstory #shorts',
          mediaDescription: 'A family of four smiling and standing near their Tesla car.',
          category: 'Bedstory',
          prompt: 'tesla cars'
        }
      }
    ],
    category: 'Bedstory',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_fq3rd3.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041664/woxo-videos-user-gallery/_dreaming/blob_mayiam.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Hey kids! Want to hear an amazing story about Tesla cars?',
            captions:
              'Hey ~ *kids!* ~ Want ~ to ~ hear ~ an ~ *amazing* ~ *story* ~ about ~ *Tesla* ~ *cars?*'
          },
          {
            voiceover:
              'Once upon a time, there was a family that wanted to save the planet. So, they decided to buy a Tesla car!',
            captions:
              'Once ~ upon ~ a ~ time, ~ there ~ was ~ a ~ *family* ~ that ~ wanted ~ to ~ *save* ~ the ~ *planet*. ~ So, ~ they ~ *decided* ~ to ~ buy ~ a ~ *Tesla* ~ car!'
          },
          {
            voiceover:
              'The Tesla car was electric, so it did not produce any smog or pollution. It was also super cool and fast!',
            captions:
              'The ~ *Tesla* ~ car ~ was ~ *electric*, ~ so ~ it ~ did ~ not ~ *produce* ~ any ~ smog ~ or ~ pollution. ~ It ~ was ~ also ~ *super* ~ *cool* ~ and ~ *fast!*'
          },
          {
            voiceover:
              'The family was so happy that they drove it everywhere, and even helped other families get their own Tesla cars.',
            captions:
              'The ~ *family* ~ was ~ so ~ *happy* ~ that ~ they ~ drove ~ it ~ *everywhere*, ~ and ~ even ~ *helped* ~ other ~ *families* ~ *get* ~ their ~ own ~ *Tesla* ~ cars'
          },
          {
            voiceover: 'Follow us for more stories like this one!',
            captions: '*Follow* ~ us ~ for ~ more ~ *stories* ~ like ~ this ~ one!'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a bedstory for kids about tesla cars and return it as a VALID array of JSON objects (Language English)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: tesla cars",          \n       "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an amazing story about tesla cars?"\n        },\n        {\n        "voiceover": "Tell a bedstory for kids about tesla cars (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },                 \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: tesla cars"\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/JqYwdIX11r/4zgR9.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Hey  kids!  Want  to  hear  an  amazing  story  about  Tesla  cars?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Once  upon  a  time,  there  was  a  family  that  wanted  to  save  the  planet.  So,  they  decided  to  buy  a  Tesla  car!</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">The  Tesla  car  was  electric,  so  it  did  not  produce  any  smog  or  pollution.  It  was  also  super  cool  and  fast!</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">The  family  was  so  happy  that  they  drove  it  everywhere,  and  even  helped  other  families  get  their  own  Tesla  cars</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  us  for  more  stories  like  this  one!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 3.608688
              },
              {
                markName: 'scene_1',
                timeSeconds: 11.217375
              },
              {
                markName: 'scene_2',
                timeSeconds: 19.2065
              },
              {
                markName: 'scene_3',
                timeSeconds: 25.8804379
              },
              {
                markName: 'scene_4',
                timeSeconds: 28.619562
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Hey',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'kids',
                timeSeconds: 0.239125,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 267,
                wordLength: 4
              },
              {
                word: '!',
                timeSeconds: 0.717375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 271,
                wordLength: 1
              },
              {
                word: 'Want',
                timeSeconds: 1.4565,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 274,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 1.6413119,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 280,
                wordLength: 2
              },
              {
                word: 'hear',
                timeSeconds: 1.695625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 284,
                wordLength: 4
              },
              {
                word: 'an',
                timeSeconds: 1.858688,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 290,
                wordLength: 2
              },
              {
                word: 'amazing',
                timeSeconds: 1.913062,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 294,
                wordLength: 7
              },
              {
                word: 'story',
                timeSeconds: 2.2935,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 303,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 2.641312,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 310,
                wordLength: 5
              },
              {
                word: 'Tesla',
                timeSeconds: 2.891312,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 317,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 3.2065,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 324,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 3.619562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 328,
                wordLength: 1
              },
              {
                word: 'Once',
                timeSeconds: 5.358688,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 411,
                wordLength: 4
              },
              {
                word: 'upon',
                timeSeconds: 5.619562,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 417,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 5.869562,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 423,
                wordLength: 1
              },
              {
                word: 'time',
                timeSeconds: 5.923938,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 426,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 6.358688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 430,
                wordLength: 1
              },
              {
                word: 'there',
                timeSeconds: 6.423938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 433,
                wordLength: 5
              },
              {
                word: 'was',
                timeSeconds: 6.5435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 440,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 6.673938,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 445,
                wordLength: 1
              },
              {
                word: 'family',
                timeSeconds: 6.739125,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 448,
                wordLength: 6
              },
              {
                word: 'that',
                timeSeconds: 7.184812,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 456,
                wordLength: 4
              },
              {
                word: 'wanted',
                timeSeconds: 7.326062,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 462,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 7.565188,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 470,
                wordLength: 2
              },
              {
                word: 'save',
                timeSeconds: 7.630438,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 474,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 7.880438,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 480,
                wordLength: 3
              },
              {
                word: 'planet',
                timeSeconds: 7.967375,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 485,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 8.413062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 491,
                wordLength: 1
              },
              {
                word: 'So',
                timeSeconds: 9.152188,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 494,
                wordLength: 2
              },
              {
                word: ',',
                timeSeconds: 9.597812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 496,
                wordLength: 1
              },
              {
                word: 'they',
                timeSeconds: 9.7065,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 499,
                wordLength: 4
              },
              {
                word: 'decided',
                timeSeconds: 9.815188,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 505,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 10.217375,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 514,
                wordLength: 2
              },
              {
                word: 'buy',
                timeSeconds: 10.282625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 518,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 10.445625,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 523,
                wordLength: 1
              },
              {
                word: 'Tesla',
                timeSeconds: 10.52175,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 526,
                wordLength: 5
              },
              {
                word: 'car',
                timeSeconds: 10.869562,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 533,
                wordLength: 3
              },
              {
                word: '!',
                timeSeconds: 11.22825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 536,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 12.97825,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 619,
                wordLength: 3
              },
              {
                word: 'Tesla',
                timeSeconds: 13.086938,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 624,
                wordLength: 5
              },
              {
                word: 'car',
                timeSeconds: 13.423938,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 631,
                wordLength: 3
              },
              {
                word: 'was',
                timeSeconds: 13.684812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 636,
                wordLength: 3
              },
              {
                word: 'electric',
                timeSeconds: 13.804375,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 641,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 14.391312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.0435,
                textOffset: 649,
                wordLength: 1
              },
              {
                word: 'so',
                timeSeconds: 14.434812,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 652,
                wordLength: 2
              },
              {
                word: 'it',
                timeSeconds: 14.619562,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 656,
                wordLength: 2
              },
              {
                word: 'did',
                timeSeconds: 14.684812,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 660,
                wordLength: 3
              },
              {
                word: 'not',
                timeSeconds: 14.815188,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 665,
                wordLength: 3
              },
              {
                word: 'produce',
                timeSeconds: 15.032625,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 670,
                wordLength: 7
              },
              {
                word: 'any',
                timeSeconds: 15.434812,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 679,
                wordLength: 3
              },
              {
                word: 'smog',
                timeSeconds: 15.630438,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 684,
                wordLength: 4
              },
              {
                word: 'or',
                timeSeconds: 16.065188,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 690,
                wordLength: 2
              },
              {
                word: 'pollution',
                timeSeconds: 16.195625,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 694,
                wordLength: 9
              },
              {
                word: '.',
                timeSeconds: 16.695625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 703,
                wordLength: 1
              },
              {
                word: 'It',
                timeSeconds: 17.434812,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 706,
                wordLength: 2
              },
              {
                word: 'was',
                timeSeconds: 17.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 710,
                wordLength: 3
              },
              {
                word: 'also',
                timeSeconds: 17.695625,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 715,
                wordLength: 4
              },
              {
                word: 'super',
                timeSeconds: 17.989125,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 721,
                wordLength: 5
              },
              {
                word: 'cool',
                timeSeconds: 18.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 728,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 18.6086879,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 734,
                wordLength: 3
              },
              {
                word: 'fast',
                timeSeconds: 18.695625,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 739,
                wordLength: 4
              },
              {
                word: '!',
                timeSeconds: 19.217375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 743,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 20.9565,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 826,
                wordLength: 3
              },
              {
                word: 'family',
                timeSeconds: 21.076062,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 831,
                wordLength: 6
              },
              {
                word: 'was',
                timeSeconds: 21.445625,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 839,
                wordLength: 3
              },
              {
                word: 'so',
                timeSeconds: 21.597812,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 844,
                wordLength: 2
              },
              {
                word: 'happy',
                timeSeconds: 21.804375,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 848,
                wordLength: 5
              },
              {
                word: 'that',
                timeSeconds: 22.119562,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 855,
                wordLength: 4
              },
              {
                word: 'they',
                timeSeconds: 22.239125,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 861,
                wordLength: 4
              },
              {
                word: 'drove',
                timeSeconds: 22.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 867,
                wordLength: 5
              },
              {
                word: 'it',
                timeSeconds: 22.619562,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 874,
                wordLength: 2
              },
              {
                word: 'everywhere',
                timeSeconds: 22.7065,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 878,
                wordLength: 10
              },
              {
                word: ',',
                timeSeconds: 23.326062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 888,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 23.4239379,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 891,
                wordLength: 3
              },
              {
                word: 'even',
                timeSeconds: 23.489125,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 896,
                wordLength: 4
              },
              {
                word: 'helped',
                timeSeconds: 23.684812,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 902,
                wordLength: 6
              },
              {
                word: 'other',
                timeSeconds: 23.97825,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 910,
                wordLength: 5
              },
              {
                word: 'families',
                timeSeconds: 24.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 917,
                wordLength: 8
              },
              {
                word: 'get',
                timeSeconds: 24.6521879,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 927,
                wordLength: 3
              },
              {
                word: 'their',
                timeSeconds: 24.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 932,
                wordLength: 5
              },
              {
                word: 'own',
                timeSeconds: 24.989125,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 939,
                wordLength: 3
              },
              {
                word: 'Tesla',
                timeSeconds: 25.141312,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 944,
                wordLength: 5
              },
              {
                word: 'cars',
                timeSeconds: 25.47825,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 951,
                wordLength: 4
              },
              {
                word: 'Follow',
                timeSeconds: 26.880438,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1037,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 27.184812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1045,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 27.315188,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1049,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 27.402188,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 1054,
                wordLength: 4
              },
              {
                word: 'stories',
                timeSeconds: 27.586938,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 1060,
                wordLength: 7
              },
              {
                word: 'like',
                timeSeconds: 28,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 1069,
                wordLength: 4
              },
              {
                word: 'this',
                timeSeconds: 28.184812,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 1075,
                wordLength: 4
              },
              {
                word: 'one',
                timeSeconds: 28.347812,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 1081,
                wordLength: 3
              },
              {
                word: '!',
                timeSeconds: 28.630438,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1084,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/Orbits_MOODHALO.mp3'
    ]
  },
  {
    id: 'bf98538e-f010-4674-afb0-39aff7c39753',
    text: 'Free creation',
    videos: [
      {
        id: 'e9766dac-c75c-47ba-82e4-466aa904f228',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Elephants_on_Parade_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/S6ckTDRW6e/KJE13.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 9.086938,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_bg3dxf.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1521879
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FUTURE* ',
                width: 1080,
                height: 1920,
                start: 0.1521879,
                stop: 0.510875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.510875,
                stop: 0.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TRANSPORTATION* ',
                width: 1080,
                height: 1920,
                start: 0.586938,
                stop: 1.315188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.315188,
                stop: 1.423938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HERE. ',
                width: 1080,
                height: 1920,
                start: 1.423938,
                stop: 2.510875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' INTRODUCING ',
                width: 1080,
                height: 1920,
                start: 2.510875,
                stop: 3.076062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " *TESLA'S* ",
                width: 1080,
                height: 1920,
                start: 3.076062,
                stop: 3.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ELECTRIC* ',
                width: 1080,
                height: 1920,
                start: 3.47825,
                stop: 3.891312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARS*, ',
                width: 1080,
                height: 1920,
                start: 3.891312,
                stop: 4.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 4.5,
                stop: 4.576062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ECO-FRIENDLY* ',
                width: 1080,
                height: 1920,
                start: 4.576062,
                stop: 5.347812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.347812,
                stop: 5.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RELIABLE* ',
                width: 1080,
                height: 1920,
                start: 5.47825,
                stop: 5.945625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MODE ',
                width: 1080,
                height: 1920,
                start: 5.945625,
                stop: 6.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 6.184812,
                stop: 6.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TRANSPORTATION*',
                width: 1080,
                height: 1920,
                start: 6.27175,
                stop: 7.108688
              }
            ]
          },
          {
            duration: 9.902187,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_brnvcg.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'BE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09781199999999934
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PART ',
                width: 1080,
                height: 1920,
                start: 0.09781199999999934,
                stop: 0.3151869999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.3151869999999999,
                stop: 0.36956200000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.36956200000000017,
                stop: 0.42393700000000045
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REVOLUTION* ',
                width: 1080,
                height: 1920,
                start: 0.42393700000000045,
                stop: 1
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 1.1086869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.1086869999999998,
                stop: 1.1956240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TRANSFORMING* ',
                width: 1080,
                height: 1920,
                start: 1.1956240000000005,
                stop: 1.8151869999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.8151869999999999,
                stop: 1.9021869999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAY ',
                width: 1080,
                height: 1920,
                start: 1.9021869999999996,
                stop: 2.0760620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WE ',
                width: 1080,
                height: 1920,
                start: 2.0760620000000003,
                stop: 2.1956240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DRIVE*. ',
                width: 1080,
                height: 1920,
                start: 2.1956240000000005,
                stop: 3.4456240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 3.4456240000000005,
                stop: 3.641312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " *TESLA'S* ",
                width: 1080,
                height: 1920,
                start: 3.641312000000001,
                stop: 4.0435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ELECTRIC* ',
                width: 1080,
                height: 1920,
                start: 4.0435,
                stop: 4.4456240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARS*, ',
                width: 1080,
                height: 1920,
                start: 4.4456240000000005,
                stop: 5.032624
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 5.032624,
                stop: 5.152187
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAN ',
                width: 1080,
                height: 1920,
                start: 5.152187,
                stop: 5.260873999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EXPERIENCE* ',
                width: 1080,
                height: 1920,
                start: 5.260873999999999,
                stop: 5.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 5.826062,
                stop: 5.902187
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EFFICIENT* ',
                width: 1080,
                height: 1920,
                start: 5.902187,
                stop: 6.413062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 6.413062,
                stop: 6.5435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SUSTAINABLE* ',
                width: 1080,
                height: 1920,
                start: 6.5435,
                stop: 7.141312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAY ',
                width: 1080,
                height: 1920,
                start: 7.141312000000001,
                stop: 7.315187
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 7.315187,
                stop: 7.391312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DRIVING',
                width: 1080,
                height: 1920,
                start: 7.391312000000001,
                stop: 7.880437000000002
              }
            ]
          },
          {
            duration: 9.336936999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_bg3dxf.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'CHOOSE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.25
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 0.25,
                stop: 0.4021870000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " *TESLA'S* ",
                width: 1080,
                height: 1920,
                start: 0.4021870000000014,
                stop: 0.8151870000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RANGE* ',
                width: 1080,
                height: 1920,
                start: 0.8151870000000017,
                stop: 1.0543128999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.0543128999999993,
                stop: 1.141312899999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARS* ',
                width: 1080,
                height: 1920,
                start: 1.141312899999999,
                stop: 1.5651870000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.5651870000000017,
                stop: 1.684750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' JOIN ',
                width: 1080,
                height: 1920,
                start: 1.684750000000001,
                stop: 1.9130000000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.9130000000000003,
                stop: 2
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOURNEY* ',
                width: 1080,
                height: 1920,
                start: 2,
                stop: 2.369562900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.369562900000002,
                stop: 2.4673750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HELP ',
                width: 1080,
                height: 1920,
                start: 2.4673750000000005,
                stop: 2.684750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.684750000000001,
                stop: 2.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ENVIRONMENT*. ',
                width: 1080,
                height: 1920,
                start: 2.75,
                stop: 4.108687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 4.108687,
                stop: 4.304312899999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TESLA, ',
                width: 1080,
                height: 1920,
                start: 4.304312899999999,
                stop: 4.880437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 4.880437000000001,
                stop: 4.989125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAN ',
                width: 1080,
                height: 1920,
                start: 4.989125000000001,
                stop: 5.119562900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 5.119562900000002,
                stop: 5.228249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SURE ',
                width: 1080,
                height: 1920,
                start: 5.228249999999999,
                stop: 5.543437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 5.543437000000001,
                stop: 5.663
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 5.663,
                stop: 5.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 5.75,
                stop: 5.858687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOING ',
                width: 1080,
                height: 1920,
                start: 5.858687,
                stop: 6.0760629
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 6.0760629,
                stop: 6.206500000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PART ',
                width: 1080,
                height: 1920,
                start: 6.206500000000002,
                stop: 6.445625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 6.445625,
                stop: 6.586936999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 6.586936999999999,
                stop: 6.641312899999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BETTER* ',
                width: 1080,
                height: 1920,
                start: 6.641312899999999,
                stop: 6.891312899999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TOMORROW*',
                width: 1080,
                height: 1920,
                start: 6.891312899999999,
                stop: 7.336937899999999
              }
            ]
          },
          {
            duration: 4.402188000000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_brnvcg.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*EXPERIENCE* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.5543759000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.5543759000000001,
                stop: 0.6630630000000011
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FUTURE* ',
                width: 1080,
                height: 1920,
                start: 0.6630630000000011,
                stop: 1.0326259000000029
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.0326259000000029,
                stop: 1.1086880000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DRIVING* ',
                width: 1080,
                height: 1920,
                start: 1.1086880000000008,
                stop: 1.4782500000000027
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TODAY*. ',
                width: 1080,
                height: 1920,
                start: 1.4782500000000027,
                stop: 2.608688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CHOOSE* ',
                width: 1080,
                height: 1920,
                start: 2.608688000000001,
                stop: 2.9021880000000024
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TESLA',
                width: 1080,
                height: 1920,
                start: 2.9021880000000024,
                stop: 3.3370000000000033
              }
            ]
          }
        ],
        meta: {
          title: 'Tesla: Experience the Future of Driving',
          description:
            "Tesla's electric cars are revolutionizing the way we drive. Experience an efficient and sustainable way of driving with Tesla now!",
          hashtags:
            '#tesla #electriccars #sustainabledriving #drivingfuture #ecofriendlycars #shorts',
          mediaDescription: 'A picture of a Tesla car driving in a city at night',
          category: 'Free Style',
          prompt: 'tesla cars'
        }
      }
    ],
    category: 'Free Style',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_bg3dxf.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041671/woxo-videos-user-gallery/_dreaming/blob_brnvcg.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover:
              "The future of transportation is here. Introducing Tesla's electric cars, an eco-friendly and reliable mode of transportation.",
            captions:
              "The ~ *future* ~ of ~ *transportation* ~ is ~ here. ~ Introducing ~ *Tesla's* ~ *electric* ~ *cars*, ~ an ~ *eco-friendly* ~ and ~ *reliable* ~ mode ~ of ~ *transportation*"
          },
          {
            voiceover:
              "Be part of a revolution that is transforming the way we drive. With Tesla's electric cars, you can experience an efficient and sustainable way of driving.",
            captions:
              "Be ~ part ~ of ~ a ~ *revolution* ~ that ~ is ~ *transforming* ~ the ~ way ~ we ~ *drive*. ~ With ~ *Tesla's* ~ *electric* ~ *cars*, ~ you ~ can ~ *experience* ~ an ~ *efficient* ~ and ~ *sustainable* ~ way ~ of ~ driving"
          },
          {
            voiceover:
              "Choose from Tesla's range of cars and join the journey to help the environment. With Tesla, you can be sure that you are doing your part for a better tomorrow.",
            captions:
              "Choose ~ from ~ *Tesla's* ~ *range* ~ of ~ *cars* ~ and ~ join ~ the ~ *journey* ~ to ~ help ~ the ~ *environment*. ~ With ~ Tesla, ~ you ~ can ~ be ~ sure ~ that ~ you ~ are ~ doing ~ your ~ part ~ for ~ a ~ *better* ~ *tomorrow*"
          },
          {
            voiceover: 'Experience the future of driving today. Choose Tesla.',
            captions: '*Experience* ~ the ~ *future* ~ of ~ *driving* ~ *today*. ~ *Choose* ~ Tesla'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script, following this prompt: tesla cars and the structure provided below (feel free to add more scenes as needed). Return it as a VALID array of JSON objects (Language English). (The amount of scenes or voiceovers on the structure may vary following the prompt)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad, Hip Hop] Topic: tesla cars",\n       "scenes": [\n         {\n           "voiceover": "Voiceover"\n         },\n         {\n           "voiceover": "Continuation"\n         },\n         {\n          "voiceover": "Continuation"\n        },  \n        {\n          "voiceover": "Continuation"\n        },\n        {\n          "voiceover": "Continuation"\n        }                     \n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",\n        "mediaDescription": "A description for a picture that illustrates the main topic or characters of the video(If the topic is a person a portrait)."\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/S6ckTDRW6e/KJE13.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">The  future  of  transportation  is  here.  Introducing  Tesla\'s  electric  cars,  an  eco-friendly  and  reliable  mode  of  transportation</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Be  part  of  a  revolution  that  is  transforming  the  way  we  drive.  With  Tesla\'s  electric  cars,  you  can  experience  an  efficient  and  sustainable  way  of  driving</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">Choose  from  Tesla\'s  range  of  cars  and  join  the  journey  to  help  the  environment.  With  Tesla,  you  can  be  sure  that  you  are  doing  your  part  for  a  better  tomorrow</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Experience  the  future  of  driving  today.  Choose  Tesla</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 7.108688
              },
              {
                markName: 'scene_1',
                timeSeconds: 16.010875
              },
              {
                markName: 'scene_2',
                timeSeconds: 24.369562
              },
              {
                markName: 'scene_3',
                timeSeconds: 28.706562
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'The',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'future',
                timeSeconds: 0.1521879,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 267,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 0.510875,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 275,
                wordLength: 2
              },
              {
                word: 'transportation',
                timeSeconds: 0.586938,
                boundaryType: 'WordBoundary',
                duration: 0.695625,
                textOffset: 279,
                wordLength: 14
              },
              {
                word: 'is',
                timeSeconds: 1.315188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 295,
                wordLength: 2
              },
              {
                word: 'here',
                timeSeconds: 1.423938,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 299,
                wordLength: 4
              },
              {
                word: '.',
                timeSeconds: 1.77175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 303,
                wordLength: 1
              },
              {
                word: 'Introducing',
                timeSeconds: 2.510875,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 306,
                wordLength: 11
              },
              {
                word: "Tesla's",
                timeSeconds: 3.076062,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 319,
                wordLength: 7
              },
              {
                word: 'electric',
                timeSeconds: 3.47825,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 328,
                wordLength: 8
              },
              {
                word: 'cars',
                timeSeconds: 3.891312,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 338,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 4.391312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 342,
                wordLength: 1
              },
              {
                word: 'an',
                timeSeconds: 4.5,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 345,
                wordLength: 2
              },
              {
                word: 'eco-friendly',
                timeSeconds: 4.576062,
                boundaryType: 'WordBoundary',
                duration: 0.684812,
                textOffset: 349,
                wordLength: 12
              },
              {
                word: 'and',
                timeSeconds: 5.347812,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 363,
                wordLength: 3
              },
              {
                word: 'reliable',
                timeSeconds: 5.47825,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 368,
                wordLength: 8
              },
              {
                word: 'mode',
                timeSeconds: 5.945625,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 378,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 6.184812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 384,
                wordLength: 2
              },
              {
                word: 'transportation',
                timeSeconds: 6.27175,
                boundaryType: 'WordBoundary',
                duration: 0.836938,
                textOffset: 388,
                wordLength: 14
              },
              {
                word: 'Be',
                timeSeconds: 8.130438,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 484,
                wordLength: 2
              },
              {
                word: 'part',
                timeSeconds: 8.22825,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 488,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 8.445625,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 494,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 8.5,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 498,
                wordLength: 1
              },
              {
                word: 'revolution',
                timeSeconds: 8.554375,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 501,
                wordLength: 10
              },
              {
                word: 'that',
                timeSeconds: 9.130438,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 513,
                wordLength: 4
              },
              {
                word: 'is',
                timeSeconds: 9.239125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 519,
                wordLength: 2
              },
              {
                word: 'transforming',
                timeSeconds: 9.326062,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 523,
                wordLength: 12
              },
              {
                word: 'the',
                timeSeconds: 9.945625,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 537,
                wordLength: 3
              },
              {
                word: 'way',
                timeSeconds: 10.032625,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 542,
                wordLength: 3
              },
              {
                word: 'we',
                timeSeconds: 10.2065,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 547,
                wordLength: 2
              },
              {
                word: 'drive',
                timeSeconds: 10.326062,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 551,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 10.836938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 556,
                wordLength: 1
              },
              {
                word: 'With',
                timeSeconds: 11.576062,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 559,
                wordLength: 4
              },
              {
                word: "Tesla's",
                timeSeconds: 11.77175,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 565,
                wordLength: 7
              },
              {
                word: 'electric',
                timeSeconds: 12.173938,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 574,
                wordLength: 8
              },
              {
                word: 'cars',
                timeSeconds: 12.576062,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 584,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 13.076062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 588,
                wordLength: 1
              },
              {
                word: 'you',
                timeSeconds: 13.163062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 591,
                wordLength: 3
              },
              {
                word: 'can',
                timeSeconds: 13.282625,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 596,
                wordLength: 3
              },
              {
                word: 'experience',
                timeSeconds: 13.391312,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 601,
                wordLength: 10
              },
              {
                word: 'an',
                timeSeconds: 13.9565,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 613,
                wordLength: 2
              },
              {
                word: 'efficient',
                timeSeconds: 14.032625,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 617,
                wordLength: 9
              },
              {
                word: 'and',
                timeSeconds: 14.5435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 628,
                wordLength: 3
              },
              {
                word: 'sustainable',
                timeSeconds: 14.673938,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 633,
                wordLength: 11
              },
              {
                word: 'way',
                timeSeconds: 15.27175,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 646,
                wordLength: 3
              },
              {
                word: 'of',
                timeSeconds: 15.445625,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 651,
                wordLength: 2
              },
              {
                word: 'driving',
                timeSeconds: 15.52175,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 655,
                wordLength: 7
              },
              {
                word: 'Choose',
                timeSeconds: 17.032625,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 744,
                wordLength: 6
              },
              {
                word: 'from',
                timeSeconds: 17.282625,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 752,
                wordLength: 4
              },
              {
                word: "Tesla's",
                timeSeconds: 17.434812,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 758,
                wordLength: 7
              },
              {
                word: 'range',
                timeSeconds: 17.847812,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 767,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 18.0869379,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 774,
                wordLength: 2
              },
              {
                word: 'cars',
                timeSeconds: 18.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 778,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 18.597812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 784,
                wordLength: 3
              },
              {
                word: 'join',
                timeSeconds: 18.717375,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 789,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 18.945625,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 795,
                wordLength: 3
              },
              {
                word: 'journey',
                timeSeconds: 19.032625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 800,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 19.4021879,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 809,
                wordLength: 2
              },
              {
                word: 'help',
                timeSeconds: 19.5,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 813,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 19.717375,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 819,
                wordLength: 3
              },
              {
                word: 'environment',
                timeSeconds: 19.782625,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 824,
                wordLength: 11
              },
              {
                word: '.',
                timeSeconds: 20.4021879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 835,
                wordLength: 1
              },
              {
                word: 'With',
                timeSeconds: 21.141312,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 838,
                wordLength: 4
              },
              {
                word: 'Tesla',
                timeSeconds: 21.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 844,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 21.8151879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 849,
                wordLength: 1
              },
              {
                word: 'you',
                timeSeconds: 21.913062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 852,
                wordLength: 3
              },
              {
                word: 'can',
                timeSeconds: 22.02175,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 857,
                wordLength: 3
              },
              {
                word: 'be',
                timeSeconds: 22.1521879,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 862,
                wordLength: 2
              },
              {
                word: 'sure',
                timeSeconds: 22.260875,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 866,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 22.576062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 872,
                wordLength: 4
              },
              {
                word: 'you',
                timeSeconds: 22.695625,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 878,
                wordLength: 3
              },
              {
                word: 'are',
                timeSeconds: 22.782625,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 883,
                wordLength: 3
              },
              {
                word: 'doing',
                timeSeconds: 22.891312,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 888,
                wordLength: 5
              },
              {
                word: 'your',
                timeSeconds: 23.1086879,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 895,
                wordLength: 4
              },
              {
                word: 'part',
                timeSeconds: 23.239125,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 901,
                wordLength: 4
              },
              {
                word: 'for',
                timeSeconds: 23.47825,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 907,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 23.619562,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 912,
                wordLength: 1
              },
              {
                word: 'better',
                timeSeconds: 23.6739379,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 915,
                wordLength: 6
              },
              {
                word: 'tomorrow',
                timeSeconds: 23.9239379,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 923,
                wordLength: 8
              },
              {
                word: 'Experience',
                timeSeconds: 25.369562,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 1013,
                wordLength: 10
              },
              {
                word: 'the',
                timeSeconds: 25.9239379,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1025,
                wordLength: 3
              },
              {
                word: 'future',
                timeSeconds: 26.032625,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 1030,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 26.4021879,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1038,
                wordLength: 2
              },
              {
                word: 'driving',
                timeSeconds: 26.47825,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 1042,
                wordLength: 7
              },
              {
                word: 'today',
                timeSeconds: 26.847812,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 1051,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 27.239125,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1056,
                wordLength: 1
              },
              {
                word: 'Choose',
                timeSeconds: 27.97825,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 1059,
                wordLength: 6
              },
              {
                word: 'Tesla',
                timeSeconds: 28.27175,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 1067,
                wordLength: 5
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Elephants_on_Parade_MOODHALO.mp3'
    ]
  }
];
