export const data = [
  {
    id: '5e9f72d8-ab2a-452e-9f00-91af17e8925c',
    text: 'Free creation',
    videos: [
      {
        id: 'bdd42fb6-f560-4d8b-b553-ede7cf555028',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/WINDMILLS_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/YN1R0BK_mt/wlaPH.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.141312,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_pmk0pf.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*STEVE* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.423938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOBS* ',
                width: 1080,
                height: 1920,
                start: 0.423938,
                stop: 0.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 0.804375,
                stop: 0.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.989125,
                stop: 0.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *VISIONARY,* ',
                width: 1080,
                height: 1920,
                start: 0.989125,
                stop: 1.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.804375,
                stop: 1.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATOR,* ',
                width: 1080,
                height: 1920,
                start: 1.804375,
                stop: 2.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.5,
                stop: 2.663062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 2.663062,
                stop: 2.663062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INNOVATOR*',
                width: 1080,
                height: 1920,
                start: 2.663062,
                stop: 3.184812
              }
            ]
          },
          {
            duration: 4.826063,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_robqfm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.11956300000000031
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CHANGED* ',
                width: 1080,
                height: 1920,
                start: 0.11956300000000031,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.5760630000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAY ',
                width: 1080,
                height: 1920,
                start: 0.5760630000000004,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 1.0760630000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMMUNICATE,* ',
                width: 1080,
                height: 1920,
                start: 1.0760630000000004,
                stop: 1.8478130000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WORK,* ',
                width: 1080,
                height: 1920,
                start: 1.8478130000000004,
                stop: 2.315188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.315188,
                stop: 2.423876
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLAY*',
                width: 1080,
                height: 1920,
                start: 2.423876,
                stop: 2.8260639999999997
              }
            ]
          },
          {
            duration: 4.413062999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_pmk0pf.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HIS ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1304369999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *IDEAS* ',
                width: 1080,
                height: 1920,
                start: 0.1304369999999988,
                stop: 0.6630629999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 0.6630629999999993,
                stop: 0.771749999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PRODUCTS* ',
                width: 1080,
                height: 1920,
                start: 0.771749999999999,
                stop: 1.2608750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REVOLUTIONIZED* ',
                width: 1080,
                height: 1920,
                start: 1.2608750000000004,
                stop: 1.9565000000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.9565000000000001,
                stop: 2.0326249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WORLD*',
                width: 1080,
                height: 1920,
                start: 2.0326249999999995,
                stop: 2.4130629999999993
              }
            ]
          },
          {
            duration: 6.260874000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_robqfm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HIS ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.14124999999999943
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GREATEST* ',
                width: 1080,
                height: 1920,
                start: 0.14124999999999943,
                stop: 0.5651869999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LEGACY:* ',
                width: 1080,
                height: 1920,
                start: 0.5651869999999999,
                stop: 1.184750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' INSPIRING ',
                width: 1080,
                height: 1920,
                start: 1.184750000000001,
                stop: 1.728250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 1.728250000000001,
                stop: 1.8369370000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.8369370000000007,
                stop: 1.9238739999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *THINK* ',
                width: 1080,
                height: 1920,
                start: 1.9238739999999996,
                stop: 2.2173739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DIFFERENTLY,* ',
                width: 1080,
                height: 1920,
                start: 2.2173739999999995,
                stop: 2.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.869562,
                stop: 2.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 2.9565,
                stop: 3.0869370000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATIVE,* ',
                width: 1080,
                height: 1920,
                start: 3.0869370000000007,
                stop: 3.793437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.793437000000001,
                stop: 3.8804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.8804370000000006,
                stop: 3.9673739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MAKE ',
                width: 1080,
                height: 1920,
                start: 3.9673739999999995,
                stop: 4.239124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 4.239124,
                stop: 4.239124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DIFFERENCE* ',
                width: 1080,
                height: 1920,
                start: 4.239124,
                stop: 4.619562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.619562000000002,
                stop: 4.684750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.684750000000001,
                stop: 4.760874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WORLD*',
                width: 1080,
                height: 1920,
                start: 4.760874000000001,
                stop: 5.141312000000003
              }
            ]
          }
        ],
        meta: {
          title: 'Steve Jobs: A Visionary Innovator',
          description:
            'This video is a tribute to the life and legacy of Steve Jobs. It showcases his innovation and creativity, and inspires us to follow in his footsteps.',
          hashtags: '#SteveJobs #Innovation #Creativity #shorts',
          mediaDescription: 'A portrait of Steve Jobs, looking thoughtful and determined.',
          category: 'Free Style',
          prompt: 'Steve Jobs'
        }
      }
    ],
    category: 'Free Style',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_pmk0pf.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041019/woxo-videos-user-gallery/_dreaming/blob_robqfm.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Steve Jobs was a visionary, a creator, and an innovator.',
            captions:
              '*Steve* ~ *Jobs* ~ was ~ a ~ *visionary,* ~ a ~ *creator,* ~ and ~ an ~ *innovator*'
          },
          {
            voiceover: 'He changed the way people communicate, work, and play.',
            captions:
              'He ~ *changed* ~ the ~ way ~ people ~ *communicate,* ~ *work,* ~ and ~ *play*'
          },
          {
            voiceover: 'His ideas and products revolutionized the world.',
            captions: 'His ~ *ideas* ~ and ~ *products* ~ *revolutionized* ~ the ~ *world*'
          },
          {
            voiceover:
              'His greatest legacy: inspiring us to think differently, to be creative, and to make a difference in the world.',
            captions:
              'His ~ *greatest* ~ *legacy:* ~ inspiring ~ us ~ to ~ *think* ~ *differently,* ~ to ~ be ~ *creative,* ~ and ~ to ~ make ~ a ~ *difference* ~ in ~ the ~ *world*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script, following this prompt: Steve Jobs and the structure provided below (feel free to add more scenes as needed). Return it as a VALID array of JSON objects (Language English). (The amount of scenes or voiceovers on the structure may vary following the prompt)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad, Hip Hop] Topic: Steve Jobs",\n       "scenes": [\n         {\n           "voiceover": "Voiceover"\n         },\n         {\n           "voiceover": "Continuation"\n         },\n         {\n          "voiceover": "Continuation"\n        },  \n        {\n          "voiceover": "Continuation"\n        },\n        {\n          "voiceover": "Continuation"\n        }                     \n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",\n        "mediaDescription": "A description for a picture that illustrates the main topic or characters of the video(If the topic is a person a portrait)."\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/YN1R0BK_mt/wlaPH.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Steve  Jobs  was  a  visionary,  a  creator,  and  an  innovator</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">He  changed  the  way  people  communicate,  work,  and  play</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">His  ideas  and  products  revolutionized  the  world</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">His  greatest  legacy:  inspiring  us  to  think  differently,  to  be  creative,  and  to  make  a  difference  in  the  world</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 3.184812
              },
              {
                markName: 'scene_1',
                timeSeconds: 7.010875
              },
              {
                markName: 'scene_2',
                timeSeconds: 10.423938
              },
              {
                markName: 'scene_3',
                timeSeconds: 16.56525
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Steve',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 262,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 0.423938,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 269,
                wordLength: 4
              },
              {
                word: 'was',
                timeSeconds: 0.804375,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 275,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 0.945625,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 280,
                wordLength: 1
              },
              {
                word: 'visionary',
                timeSeconds: 0.989125,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 283,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 1.6413119,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 292,
                wordLength: 1
              },
              {
                word: 'a',
                timeSeconds: 1.739125,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 295,
                wordLength: 1
              },
              {
                word: 'creator',
                timeSeconds: 1.804375,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 298,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 2.413062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 305,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 2.5,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 308,
                wordLength: 3
              },
              {
                word: 'an',
                timeSeconds: 2.576062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 313,
                wordLength: 2
              },
              {
                word: 'innovator',
                timeSeconds: 2.663062,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 317,
                wordLength: 9
              },
              {
                word: 'He',
                timeSeconds: 4.184812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 408,
                wordLength: 2
              },
              {
                word: 'changed',
                timeSeconds: 4.304375,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 412,
                wordLength: 7
              },
              {
                word: 'the',
                timeSeconds: 4.684812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 421,
                wordLength: 3
              },
              {
                word: 'way',
                timeSeconds: 4.760875,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 426,
                wordLength: 3
              },
              {
                word: 'people',
                timeSeconds: 4.934812,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 431,
                wordLength: 6
              },
              {
                word: 'communicate',
                timeSeconds: 5.260875,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 439,
                wordLength: 11
              },
              {
                word: ',',
                timeSeconds: 5.891312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.141312,
                textOffset: 450,
                wordLength: 1
              },
              {
                word: 'work',
                timeSeconds: 6.032625,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 453,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 6.423938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 457,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 6.5,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 460,
                wordLength: 3
              },
              {
                word: 'play',
                timeSeconds: 6.608688,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 465,
                wordLength: 4
              },
              {
                word: 'His',
                timeSeconds: 8.010875,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 551,
                wordLength: 3
              },
              {
                word: 'ideas',
                timeSeconds: 8.141312,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 556,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 8.673938,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 563,
                wordLength: 3
              },
              {
                word: 'products',
                timeSeconds: 8.782625,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 568,
                wordLength: 8
              },
              {
                word: 'revolutionized',
                timeSeconds: 9.27175,
                boundaryType: 'WordBoundary',
                duration: 0.684812,
                textOffset: 578,
                wordLength: 14
              },
              {
                word: 'the',
                timeSeconds: 9.967375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 594,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 10.0435,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 599,
                wordLength: 5
              },
              {
                word: 'His',
                timeSeconds: 11.423938,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 686,
                wordLength: 3
              },
              {
                word: 'greatest',
                timeSeconds: 11.565188,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 691,
                wordLength: 8
              },
              {
                word: 'legacy',
                timeSeconds: 11.989125,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 701,
                wordLength: 6
              },
              {
                word: ':',
                timeSeconds: 12.5,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 707,
                wordLength: 1
              },
              {
                word: 'inspiring',
                timeSeconds: 12.608688,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 710,
                wordLength: 9
              },
              {
                word: 'us',
                timeSeconds: 13.152188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 721,
                wordLength: 2
              },
              {
                word: 'to',
                timeSeconds: 13.260875,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 725,
                wordLength: 2
              },
              {
                word: 'think',
                timeSeconds: 13.347812,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 729,
                wordLength: 5
              },
              {
                word: 'differently',
                timeSeconds: 13.641312,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 736,
                wordLength: 11
              },
              {
                word: ',',
                timeSeconds: 14.184812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 747,
                wordLength: 1
              },
              {
                word: 'to',
                timeSeconds: 14.2935,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 750,
                wordLength: 2
              },
              {
                word: 'be',
                timeSeconds: 14.380438,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 754,
                wordLength: 2
              },
              {
                word: 'creative',
                timeSeconds: 14.510875,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 758,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 15.086938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 766,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 15.217375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 769,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 15.304375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 774,
                wordLength: 2
              },
              {
                word: 'make',
                timeSeconds: 15.391312,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 778,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 15.576062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 784,
                wordLength: 1
              },
              {
                word: 'difference',
                timeSeconds: 15.663062,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 787,
                wordLength: 10
              },
              {
                word: 'in',
                timeSeconds: 16.0435,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 799,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 16.108688,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 803,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 16.184812,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 808,
                wordLength: 5
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/WINDMILLS_MOODHALO.mp3'
    ]
  },
  {
    id: 'b49c9aaa-f304-4590-9ad5-56c8149efb52',
    text: 'A Day in the Life of "Steve Jobs"',
    videos: [
      {
        id: 'a4ccbeb3-4b95-427c-ba16-0f25701a427e',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/Village_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/mNXXxqjmRg/IsXQB.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.880438,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_tnfzyl.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'WHAT ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.195625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOES ',
                width: 1080,
                height: 1920,
                start: 0.195625,
                stop: 0.326062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.326062,
                stop: 0.413062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 0.413062,
                stop: 0.608688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.608688,
                stop: 0.673938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.673938,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 0.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.989125,
                stop: 1.0760619
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STEVE JOBS* ',
                width: 1080,
                height: 1920,
                start: 1.0760619,
                stop: 1.695625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOOK* ',
                width: 1080,
                height: 1920,
                start: 1.695625,
                stop: 1.858688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIKE?*',
                width: 1080,
                height: 1920,
                start: 1.858688,
                stop: 2.27175
              }
            ]
          },
          {
            duration: 16.239124,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_rkfwph.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*STEVE JOBS* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.7716869999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAKES ',
                width: 1080,
                height: 1920,
                start: 0.7716869999999996,
                stop: 1.054312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP ',
                width: 1080,
                height: 1920,
                start: 1.054312,
                stop: 1.195624
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EACH ',
                width: 1080,
                height: 1920,
                start: 1.195624,
                stop: 1.4129999999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 1.4129999999999998,
                stop: 1.5760619999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 1.5760619999999999,
                stop: 1.7064999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.7064999999999997,
                stop: 1.7499999999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MISSION* ',
                width: 1080,
                height: 1920,
                start: 1.7499999999999996,
                stop: 2.1195619999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.1195619999999997,
                stop: 2.2282499999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CREATE ',
                width: 1080,
                height: 1920,
                start: 2.2282499999999996,
                stop: 2.5434369999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOMETHING ',
                width: 1080,
                height: 1920,
                start: 2.5434369999999995,
                stop: 2.902124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REVOLUTIONARY*. ',
                width: 1080,
                height: 1920,
                start: 2.902124,
                stop: 4.413
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 4.413,
                stop: 4.532562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BEGINS ',
                width: 1080,
                height: 1920,
                start: 4.532562,
                stop: 4.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 4.869562,
                stop: 5.010874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 5.010874000000001,
                stop: 5.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 5.2065,
                stop: 5.336937000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 5.336937000000001,
                stop: 5.380437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NUTRITIOUS* ',
                width: 1080,
                height: 1920,
                start: 5.380437000000001,
                stop: 5.880437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BREAKFAST*, ',
                width: 1080,
                height: 1920,
                start: 5.880437000000001,
                stop: 6.521687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEN ',
                width: 1080,
                height: 1920,
                start: 6.521687,
                stop: 6.6956240000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MOVES ',
                width: 1080,
                height: 1920,
                start: 6.6956240000000005,
                stop: 6.9673739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONTO ',
                width: 1080,
                height: 1920,
                start: 6.9673739999999995,
                stop: 7.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 7.2065,
                stop: 7.336937000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OFFICE, ',
                width: 1080,
                height: 1920,
                start: 7.336937000000001,
                stop: 7.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHERE ',
                width: 1080,
                height: 1920,
                start: 7.9565,
                stop: 8.163
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 8.163,
                stop: 8.163
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SPENDS ',
                width: 1080,
                height: 1920,
                start: 8.163,
                stop: 8.543437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME ',
                width: 1080,
                height: 1920,
                start: 8.543437,
                stop: 8.793437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RESEARCHING* ',
                width: 1080,
                height: 1920,
                start: 8.793437,
                stop: 9.402124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 9.402124,
                stop: 9.532562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INNOVATING*. ',
                width: 1080,
                height: 1920,
                start: 9.532562,
                stop: 10.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 10.826062,
                stop: 10.978250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TAKES ',
                width: 1080,
                height: 1920,
                start: 10.978250000000001,
                stop: 11.271687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FREQUENT* ',
                width: 1080,
                height: 1920,
                start: 11.271687,
                stop: 11.64125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BREAKS* ',
                width: 1080,
                height: 1920,
                start: 11.64125,
                stop: 11.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THROUGHOUT ',
                width: 1080,
                height: 1920,
                start: 11.9565,
                stop: 12.217374
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 12.217374,
                stop: 12.315187000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 12.315187000000002,
                stop: 12.532561999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 12.532561999999999,
                stop: 12.641249900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CLEAR* ',
                width: 1080,
                height: 1920,
                start: 12.641249900000002,
                stop: 12.902124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 12.902124,
                stop: 13.010874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MIND ',
                width: 1080,
                height: 1920,
                start: 13.010874000000001,
                stop: 13.402124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 13.402124,
                stop: 13.510874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GET ',
                width: 1080,
                height: 1920,
                start: 13.510874000000001,
                stop: 13.662999899999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INSPIRED*',
                width: 1080,
                height: 1920,
                start: 13.662999899999999,
                stop: 14.2390619
              }
            ]
          },
          {
            duration: 12.413,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_tnfzyl.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*LUNCHTIME* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.5108758999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 0.5108758999999985,
                stop: 0.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.6195629999999994,
                stop: 0.6847500000000011
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME ',
                width: 1080,
                height: 1920,
                start: 0.6847500000000011,
                stop: 0.9564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.9564999999999984,
                stop: 1.0434379999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REFLECT* ',
                width: 1080,
                height: 1920,
                start: 1.0434379999999983,
                stop: 1.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.5,
                stop: 1.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RELAX*, ',
                width: 1080,
                height: 1920,
                start: 1.586938,
                stop: 2.25
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOLLOWED ',
                width: 1080,
                height: 1920,
                start: 2.25,
                stop: 2.608688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BY ',
                width: 1080,
                height: 1920,
                start: 2.608688000000001,
                stop: 2.7064999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 2.7064999999999984,
                stop: 2.9673759000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RESEARCH* ',
                width: 1080,
                height: 1920,
                start: 2.9673759000000004,
                stop: 3.3695629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.3695629999999994,
                stop: 3.4673759000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WORK*. ',
                width: 1080,
                height: 1920,
                start: 3.4673759000000004,
                stop: 4.543437999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STEVE JOBS* ',
                width: 1080,
                height: 1920,
                start: 4.543437999999998,
                stop: 5.358688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 5.358688000000001,
                stop: 5.4673759
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CONSTANTLY ',
                width: 1080,
                height: 1920,
                start: 5.4673759,
                stop: 6.021750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NETWORKING* ',
                width: 1080,
                height: 1920,
                start: 6.021750000000001,
                stop: 6.608688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 6.608688000000001,
                stop: 6.6956259
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CONNECTING ',
                width: 1080,
                height: 1920,
                start: 6.6956259,
                stop: 7.119562999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 7.119562999999999,
                stop: 7.2608758999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OTHER ',
                width: 1080,
                height: 1920,
                start: 7.2608758999999985,
                stop: 7.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INNOVATORS*, ',
                width: 1080,
                height: 1920,
                start: 7.478249999999999,
                stop: 8.239125999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 8.239125999999999,
                stop: 8.391313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ENDS ',
                width: 1080,
                height: 1920,
                start: 8.391313,
                stop: 8.597812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 8.597812999999999,
                stop: 8.717376000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 8.717376000000002,
                stop: 8.913
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 8.913,
                stop: 9.054313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 9.054313,
                stop: 9.108688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *QUIET* ',
                width: 1080,
                height: 1920,
                start: 9.108688,
                stop: 9.467376000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DINNER ',
                width: 1080,
                height: 1920,
                start: 9.467376000000002,
                stop: 9.739125999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 9.739125999999999,
                stop: 9.858688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 9.858688,
                stop: 10
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FAMILY*',
                width: 1080,
                height: 1920,
                start: 10,
                stop: 10.413062
              }
            ]
          },
          {
            duration: 4.880437999999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_rkfwph.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FOLLOW ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.3152499999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.3152499999999989,
                stop: 0.4021879999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.4021879999999989,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LEARN* ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.7174380000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.7174380000000014,
                stop: 0.8913130000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 0.8913130000000002,
                stop: 1.130437999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " *STEVE JOBS'* ",
                width: 1080,
                height: 1920,
                start: 1.130437999999998,
                stop: 1.7608760000000032
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 1.7608760000000032,
                stop: 2.1195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.1195629999999994,
                stop: 2.195687999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOW ',
                width: 1080,
                height: 1920,
                start: 2.195687999999997,
                stop: 2.336999999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.336999999999996,
                stop: 2.413063000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BECOME ',
                width: 1080,
                height: 1920,
                start: 2.413063000000001,
                stop: 2.6848129999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 2.6848129999999983,
                stop: 2.760876000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INNOVATOR* ',
                width: 1080,
                height: 1920,
                start: 2.760876000000003,
                stop: 3.163063000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIKE* ',
                width: 1080,
                height: 1920,
                start: 3.163063000000001,
                stop: 3.380437999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HIM*',
                width: 1080,
                height: 1920,
                start: 3.380437999999998,
                stop: 3.597812999999995
              }
            ]
          }
        ],
        meta: {
          title: 'A Day in the Life of Steve Jobs',
          description:
            'Take a look at a typical day in the life of Steve Jobs, and find out how to become an innovator like him!',
          hashtags: '#stevejobs #innovator #technology #apple #inspiration #shorts',
          mediaDescription: 'A portrait of Steve Jobs, the co-founder of Apple, Inc.',
          category: 'Day in the Life',
          prompt: 'Steve Jobs'
        }
      }
    ],
    category: 'Day in the Life',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_tnfzyl.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041035/woxo-videos-user-gallery/_dreaming/blob_rkfwph.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'What does a day in the life of Steve Jobs look like?',
            captions:
              'What ~ does ~ a ~ *day* ~ in ~ the ~ *life* ~ of ~ *Steve Jobs* ~ *look* ~ *like?*'
          },
          {
            voiceover:
              'Steve Jobs wakes up each day with a mission to create something revolutionary. He begins his day with a nutritious breakfast, then moves onto his office, where he spends time researching and innovating. He takes frequent breaks throughout the day to clear his mind and get inspired.',
            captions:
              '*Steve Jobs* ~ wakes ~ up ~ each ~ *day* ~ with ~ a ~ *mission* ~ to ~ create ~ something ~ *revolutionary*. ~ He ~ begins ~ his ~ *day* ~ with ~ a ~ *nutritious* ~ *breakfast*, ~ then ~ moves ~ onto ~ his ~ office, ~ where ~ he ~ spends ~ time ~ *researching* ~ and ~ *innovating*. ~ He ~ takes ~ *frequent* ~ *breaks* ~ throughout ~ the ~ *day* ~ to ~ *clear* ~ his ~ mind ~ and ~ get ~ *inspired*'
          },
          {
            voiceover:
              'Lunchtime is a time to reflect and relax, followed by more research and work. Steve Jobs is constantly networking and connecting with other innovators, and ends the day with a quiet dinner with his family.',
            captions:
              '*Lunchtime* ~ is ~ a ~ time ~ to ~ *reflect* ~ and ~ *relax*, ~ followed ~ by ~ more ~ *research* ~ and ~ *work*. ~ *Steve Jobs* ~ is ~ constantly ~ *networking* ~ and ~ connecting ~ with ~ other ~ *innovators*, ~ and ~ ends ~ the ~ *day* ~ with ~ a ~ *quiet* ~ dinner ~ with ~ his ~ *family*'
          },
          {
            voiceover:
              "Follow us to learn more about Steve Jobs' life and how to become an innovator like him.",
            captions:
              "Follow ~ us ~ to ~ *learn* ~ more ~ about ~ *Steve Jobs'* ~ *life* ~ and ~ how ~ to ~ become ~ an ~ *innovator* ~ *like* ~ *him*"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, about a day in life of Steve Jobs and return it as a VALID array of JSON objects (Language English)\n  Example:\n  [\n   {\n    "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Steve Jobs",\n     "scenes": [\n      {\n        "voiceover": "A hook similar but not the same to the following ->  How does a day in the life of Steve Jobs look like?"\n      },\n      {\n        "voiceover": "A brief description about some of the activities that Steve Jobs do during a regular day. E.g: He wake up early on and eat his lunch at 5pm (max 30 words)"\n      },\n      {\n        "voiceover": "Scene 2 continuation... (max 30 words)"\n      },  \n      {\n        "voiceover": "Scene 3 continuation... (max 30 words)"\n      },        \n      {\n        "voiceover": "A CTA to follow me for more"\n      }\n     ],\n     "meta": {\n      "title": "Video Title",\n      "description": "A short description of the video for Youtube",\n      "hashtags": "a list of hashtags separated by space related to the video",   \n      "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Steve Jobs"  \n     }\n   }\n]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/mNXXxqjmRg/IsXQB.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">What  does  a  day  in  the  life  of  Steve Jobs  look  like?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Steve Jobs  wakes  up  each  day  with  a  mission  to  create  something  revolutionary.  He  begins  his  day  with  a  nutritious  breakfast,  then  moves  onto  his  office,  where  he  spends  time  researching  and  innovating.  He  takes  frequent  breaks  throughout  the  day  to  clear  his  mind  and  get  inspired</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">Lunchtime  is  a  time  to  reflect  and  relax,  followed  by  more  research  and  work.  Steve Jobs  is  constantly  networking  and  connecting  with  other  innovators,  and  ends  the  day  with  a  quiet  dinner  with  his  family</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Follow  us  to  learn  more  about  Steve Jobs\'  life  and  how  to  become  an  innovator  like  him</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.163062
              },
              {
                markName: 'scene_1',
                timeSeconds: 18.163
              },
              {
                markName: 'scene_2',
                timeSeconds: 29.576125
              },
              {
                markName: 'scene_3',
                timeSeconds: 34.173875
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'What',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 262,
                wordLength: 4
              },
              {
                word: 'does',
                timeSeconds: 0.195625,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 268,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.326062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 274,
                wordLength: 1
              },
              {
                word: 'day',
                timeSeconds: 0.413062,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 277,
                wordLength: 3
              },
              {
                word: 'in',
                timeSeconds: 0.608688,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 282,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 0.673938,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 286,
                wordLength: 3
              },
              {
                word: 'life',
                timeSeconds: 0.75,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 291,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 0.989125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 297,
                wordLength: 2
              },
              {
                word: 'Steve',
                timeSeconds: 1.0760619,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 301,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 1.358688,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 307,
                wordLength: 4
              },
              {
                word: 'look',
                timeSeconds: 1.695625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 313,
                wordLength: 4
              },
              {
                word: 'like',
                timeSeconds: 1.858688,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 319,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.173938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 323,
                wordLength: 1
              },
              {
                word: 'Steve',
                timeSeconds: 3.923938,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 406,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 4.326062,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 412,
                wordLength: 4
              },
              {
                word: 'wakes',
                timeSeconds: 4.695625,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 418,
                wordLength: 5
              },
              {
                word: 'up',
                timeSeconds: 4.97825,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 425,
                wordLength: 2
              },
              {
                word: 'each',
                timeSeconds: 5.119562,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 429,
                wordLength: 4
              },
              {
                word: 'day',
                timeSeconds: 5.336938,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 435,
                wordLength: 3
              },
              {
                word: 'with',
                timeSeconds: 5.5,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 440,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 5.630438,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 446,
                wordLength: 1
              },
              {
                word: 'mission',
                timeSeconds: 5.673938,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 449,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 6.0435,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 458,
                wordLength: 2
              },
              {
                word: 'create',
                timeSeconds: 6.152188,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 462,
                wordLength: 6
              },
              {
                word: 'something',
                timeSeconds: 6.467375,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 470,
                wordLength: 9
              },
              {
                word: 'revolutionary',
                timeSeconds: 6.826062,
                boundaryType: 'WordBoundary',
                duration: 0.75,
                textOffset: 481,
                wordLength: 13
              },
              {
                word: '.',
                timeSeconds: 7.586938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 494,
                wordLength: 1
              },
              {
                word: 'He',
                timeSeconds: 8.336938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 497,
                wordLength: 2
              },
              {
                word: 'begins',
                timeSeconds: 8.4565,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 501,
                wordLength: 6
              },
              {
                word: 'his',
                timeSeconds: 8.7935,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 509,
                wordLength: 3
              },
              {
                word: 'day',
                timeSeconds: 8.934812,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 514,
                wordLength: 3
              },
              {
                word: 'with',
                timeSeconds: 9.130438,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 519,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 9.260875,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 525,
                wordLength: 1
              },
              {
                word: 'nutritious',
                timeSeconds: 9.304375,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 528,
                wordLength: 10
              },
              {
                word: 'breakfast',
                timeSeconds: 9.804375,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 540,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 10.402188,
                boundaryType: 'PunctuationBoundary',
                duration: 0.043438,
                textOffset: 549,
                wordLength: 1
              },
              {
                word: 'then',
                timeSeconds: 10.445625,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 552,
                wordLength: 4
              },
              {
                word: 'moves',
                timeSeconds: 10.619562,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 558,
                wordLength: 5
              },
              {
                word: 'onto',
                timeSeconds: 10.891312,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 565,
                wordLength: 4
              },
              {
                word: 'his',
                timeSeconds: 11.130438,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 571,
                wordLength: 3
              },
              {
                word: 'office',
                timeSeconds: 11.260875,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 576,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 11.7935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 582,
                wordLength: 1
              },
              {
                word: 'where',
                timeSeconds: 11.880438,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 585,
                wordLength: 5
              },
              {
                word: 'he',
                timeSeconds: 12,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 592,
                wordLength: 2
              },
              {
                word: 'spends',
                timeSeconds: 12.086938,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 596,
                wordLength: 6
              },
              {
                word: 'time',
                timeSeconds: 12.467375,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 604,
                wordLength: 4
              },
              {
                word: 'researching',
                timeSeconds: 12.717375,
                boundaryType: 'WordBoundary',
                duration: 0.576062,
                textOffset: 610,
                wordLength: 11
              },
              {
                word: 'and',
                timeSeconds: 13.326062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 623,
                wordLength: 3
              },
              {
                word: 'innovating',
                timeSeconds: 13.4565,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 628,
                wordLength: 10
              },
              {
                word: '.',
                timeSeconds: 14.010875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 638,
                wordLength: 1
              },
              {
                word: 'He',
                timeSeconds: 14.75,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 641,
                wordLength: 2
              },
              {
                word: 'takes',
                timeSeconds: 14.902188,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 645,
                wordLength: 5
              },
              {
                word: 'frequent',
                timeSeconds: 15.195625,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 652,
                wordLength: 8
              },
              {
                word: 'breaks',
                timeSeconds: 15.565188,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 662,
                wordLength: 6
              },
              {
                word: 'throughout',
                timeSeconds: 15.880438,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 670,
                wordLength: 10
              },
              {
                word: 'the',
                timeSeconds: 16.141312,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 682,
                wordLength: 3
              },
              {
                word: 'day',
                timeSeconds: 16.239125,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 687,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 16.4565,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 692,
                wordLength: 2
              },
              {
                word: 'clear',
                timeSeconds: 16.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 696,
                wordLength: 5
              },
              {
                word: 'his',
                timeSeconds: 16.826062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 703,
                wordLength: 3
              },
              {
                word: 'mind',
                timeSeconds: 16.934812,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 708,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 17.326062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 714,
                wordLength: 3
              },
              {
                word: 'get',
                timeSeconds: 17.434812,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 719,
                wordLength: 3
              },
              {
                word: 'inspired',
                timeSeconds: 17.5869379,
                boundaryType: 'WordBoundary',
                duration: 0.576062,
                textOffset: 724,
                wordLength: 8
              },
              {
                word: 'Lunchtime',
                timeSeconds: 19.163062,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 814,
                wordLength: 9
              },
              {
                word: 'is',
                timeSeconds: 19.6739379,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 825,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 19.782625,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 829,
                wordLength: 1
              },
              {
                word: 'time',
                timeSeconds: 19.847812,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 832,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 20.119562,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 838,
                wordLength: 2
              },
              {
                word: 'reflect',
                timeSeconds: 20.2065,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 842,
                wordLength: 7
              },
              {
                word: 'and',
                timeSeconds: 20.663062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 851,
                wordLength: 3
              },
              {
                word: 'relax',
                timeSeconds: 20.75,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 856,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 21.347812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 861,
                wordLength: 1
              },
              {
                word: 'followed',
                timeSeconds: 21.413062,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 864,
                wordLength: 8
              },
              {
                word: 'by',
                timeSeconds: 21.77175,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 874,
                wordLength: 2
              },
              {
                word: 'more',
                timeSeconds: 21.869562,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 878,
                wordLength: 4
              },
              {
                word: 'research',
                timeSeconds: 22.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 884,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 22.532625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 894,
                wordLength: 3
              },
              {
                word: 'work',
                timeSeconds: 22.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 899,
                wordLength: 4
              },
              {
                word: '.',
                timeSeconds: 22.9565,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 903,
                wordLength: 1
              },
              {
                word: 'Steve',
                timeSeconds: 23.7065,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 906,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 24.1086879,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 912,
                wordLength: 4
              },
              {
                word: 'is',
                timeSeconds: 24.52175,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 918,
                wordLength: 2
              },
              {
                word: 'constantly',
                timeSeconds: 24.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 922,
                wordLength: 10
              },
              {
                word: 'networking',
                timeSeconds: 25.184812,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 934,
                wordLength: 10
              },
              {
                word: 'and',
                timeSeconds: 25.77175,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 946,
                wordLength: 3
              },
              {
                word: 'connecting',
                timeSeconds: 25.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 951,
                wordLength: 10
              },
              {
                word: 'with',
                timeSeconds: 26.282625,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 963,
                wordLength: 4
              },
              {
                word: 'other',
                timeSeconds: 26.4239379,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 969,
                wordLength: 5
              },
              {
                word: 'innovators',
                timeSeconds: 26.641312,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 976,
                wordLength: 10
              },
              {
                word: ',',
                timeSeconds: 27.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 986,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 27.402188,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 989,
                wordLength: 3
              },
              {
                word: 'ends',
                timeSeconds: 27.554375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 994,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 27.760875,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1000,
                wordLength: 3
              },
              {
                word: 'day',
                timeSeconds: 27.880438,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 1005,
                wordLength: 3
              },
              {
                word: 'with',
                timeSeconds: 28.076062,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1010,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 28.217375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 1016,
                wordLength: 1
              },
              {
                word: 'quiet',
                timeSeconds: 28.27175,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 1019,
                wordLength: 5
              },
              {
                word: 'dinner',
                timeSeconds: 28.630438,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1026,
                wordLength: 6
              },
              {
                word: 'with',
                timeSeconds: 28.902188,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1034,
                wordLength: 4
              },
              {
                word: 'his',
                timeSeconds: 29.02175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1040,
                wordLength: 3
              },
              {
                word: 'family',
                timeSeconds: 29.163062,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 1045,
                wordLength: 6
              },
              {
                word: 'Follow',
                timeSeconds: 30.576062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1133,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 30.891312,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1141,
                wordLength: 2
              },
              {
                word: 'to',
                timeSeconds: 30.97825,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1145,
                wordLength: 2
              },
              {
                word: 'learn',
                timeSeconds: 31.076062,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1149,
                wordLength: 5
              },
              {
                word: 'more',
                timeSeconds: 31.2935,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1156,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 31.467375,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 1162,
                wordLength: 5
              },
              {
                word: 'Steve',
                timeSeconds: 31.7065,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 1169,
                wordLength: 5
              },
              {
                word: "Jobs'",
                timeSeconds: 32,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 1175,
                wordLength: 5
              },
              {
                word: 'life',
                timeSeconds: 32.336938,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1182,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 32.695625,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1188,
                wordLength: 3
              },
              {
                word: 'how',
                timeSeconds: 32.77175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1193,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 32.913062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1198,
                wordLength: 2
              },
              {
                word: 'become',
                timeSeconds: 32.989125,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 1202,
                wordLength: 6
              },
              {
                word: 'an',
                timeSeconds: 33.260875,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1210,
                wordLength: 2
              },
              {
                word: 'innovator',
                timeSeconds: 33.336938,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 1214,
                wordLength: 9
              },
              {
                word: 'like',
                timeSeconds: 33.739125,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1225,
                wordLength: 4
              },
              {
                word: 'him',
                timeSeconds: 33.9565,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 1231,
                wordLength: 3
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Epic/Village_-_MOODHALO.mp3'
    ]
  },
  {
    id: 'f25af4a8-320f-40ed-ace3-d86a72750f1b',
    text: 'A Bedstory about "Steve Jobs"',
    videos: [
      {
        id: '3d0b4405-a643-445b-9f6a-38b22e512afb',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Bad_Love_Niwel_Remix_-_Niwel.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/uJEDHRzt8a/lp_RP.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.68475,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_gmv0sg.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.260875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KIDS*, ',
                width: 1080,
                height: 1920,
                start: 0.260875,
                stop: 0.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WANNA ',
                width: 1080,
                height: 1920,
                start: 0.826062,
                stop: 1.010875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEAR ',
                width: 1080,
                height: 1920,
                start: 1.010875,
                stop: 1.1848119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 1.1848119,
                stop: 1.239125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 1.239125,
                stop: 1.630438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STORY ',
                width: 1080,
                height: 1920,
                start: 1.630438,
                stop: 1.97825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.97825,
                stop: 2.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STEVE* ',
                width: 1080,
                height: 1920,
                start: 2.2065,
                stop: 2.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOBS?*',
                width: 1080,
                height: 1920,
                start: 2.47825,
                stop: 3.076062
              }
            ]
          },
          {
            duration: 12.22825,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_nhdehm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*STEVE* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.36956200000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOBS* ',
                width: 1080,
                height: 1920,
                start: 0.36956200000000017,
                stop: 0.7391249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 0.7391249999999996,
                stop: 0.934812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.934812,
                stop: 0.934812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MAN ',
                width: 1080,
                height: 1920,
                start: 0.934812,
                stop: 1.1956879999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 1.1956879999999996,
                stop: 1.3152499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.3152499999999998,
                stop: 1.3804379999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DREAM*. ',
                width: 1080,
                height: 1920,
                start: 1.3804379999999998,
                stop: 2.52175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 2.52175,
                stop: 2.641312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WANTED ',
                width: 1080,
                height: 1920,
                start: 2.641312,
                stop: 2.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.869562,
                stop: 2.934812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MAKE ',
                width: 1080,
                height: 1920,
                start: 2.934812,
                stop: 3.119562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.119562,
                stop: 3.206562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WORLD ',
                width: 1080,
                height: 1920,
                start: 3.206562,
                stop: 3.4891250000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 3.4891250000000005,
                stop: 3.5326250000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BETTER ',
                width: 1080,
                height: 1920,
                start: 3.5326250000000003,
                stop: 3.7935000000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PLACE ',
                width: 1080,
                height: 1920,
                start: 3.7935000000000008,
                stop: 4.163061999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.163061999999999,
                stop: 4.2608749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 4.2608749999999995,
                stop: 4.358688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DID ',
                width: 1080,
                height: 1920,
                start: 4.358688,
                stop: 4.532625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 4.532625,
                stop: 4.630438000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BY ',
                width: 1080,
                height: 1920,
                start: 4.630438000000001,
                stop: 4.77175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CREATING ',
                width: 1080,
                height: 1920,
                start: 4.77175,
                stop: 5.173938000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *APPLE*. ',
                width: 1080,
                height: 1920,
                start: 5.173938000000001,
                stop: 6.31525
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 6.31525,
                stop: 6.467375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TAUGHT ',
                width: 1080,
                height: 1920,
                start: 6.467375,
                stop: 6.717375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 6.717375,
                stop: 6.869561999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 6.869561999999999,
                stop: 6.97825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'IF ',
                width: 1080,
                height: 1920,
                start: 6.97825,
                stop: 7.076125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 7.076125,
                stop: 7.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAVE ',
                width: 1080,
                height: 1920,
                start: 7.184812,
                stop: 7.402188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 7.402188,
                stop: 7.402188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DREAM*, ',
                width: 1080,
                height: 1920,
                start: 7.402188,
                stop: 7.967375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NO ',
                width: 1080,
                height: 1920,
                start: 7.967375,
                stop: 8.065249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MATTER ',
                width: 1080,
                height: 1920,
                start: 8.065249999999999,
                stop: 8.336938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOW ',
                width: 1080,
                height: 1920,
                start: 8.336938,
                stop: 8.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BIG*, ',
                width: 1080,
                height: 1920,
                start: 8.5,
                stop: 8.989125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 8.989125000000001,
                stop: 9.097812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAN ',
                width: 1080,
                height: 1920,
                start: 9.097812000000001,
                stop: 9.22825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MAKE ',
                width: 1080,
                height: 1920,
                start: 9.22825,
                stop: 9.434812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 9.434812,
                stop: 9.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' INTO ',
                width: 1080,
                height: 1920,
                start: 9.5,
                stop: 9.706562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REALITY*',
                width: 1080,
                height: 1920,
                start: 9.706562000000002,
                stop: 10.228312000000003
              }
            ]
          },
          {
            duration: 12.173938000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_gmv0sg.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09787500000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAD ',
                width: 1080,
                height: 1920,
                start: 0.09787500000000016,
                stop: 0.22831200000000074
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.22831200000000074,
                stop: 0.29349999999999987
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FACE ',
                width: 1080,
                height: 1920,
                start: 0.29349999999999987,
                stop: 0.5652500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MANY ',
                width: 1080,
                height: 1920,
                start: 0.5652500000000007,
                stop: 0.8586879000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*OBSTACLES* ',
                width: 1080,
                height: 1920,
                start: 0.8586879000000014,
                stop: 1.391312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALONG ',
                width: 1080,
                height: 1920,
                start: 1.391312000000001,
                stop: 1.6630619999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.6630619999999983,
                stop: 1.7391249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAY, ',
                width: 1080,
                height: 1920,
                start: 1.7391249999999996,
                stop: 2.2174378999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUT ',
                width: 1080,
                height: 1920,
                start: 2.2174378999999984,
                stop: 2.3152500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 2.3152500000000007,
                stop: 2.434811999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COURAGE* ',
                width: 1080,
                height: 1920,
                start: 2.434811999999999,
                stop: 2.8586879000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.8586879000000014,
                stop: 2.934811999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DETERMINATION* ',
                width: 1080,
                height: 1920,
                start: 2.934811999999999,
                stop: 3.6521878999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WERE ',
                width: 1080,
                height: 1920,
                start: 3.6521878999999995,
                stop: 3.7499999999999982
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STRONGER* ',
                width: 1080,
                height: 1920,
                start: 3.7499999999999982,
                stop: 4.206562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAN ',
                width: 1080,
                height: 1920,
                start: 4.206562,
                stop: 4.3370000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALL ',
                width: 1080,
                height: 1920,
                start: 4.3370000000000015,
                stop: 4.499999999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 4.499999999999998,
                stop: 4.5870000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEM. ',
                width: 1080,
                height: 1920,
                start: 4.5870000000000015,
                stop: 5.5870000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVEN ',
                width: 1080,
                height: 1920,
                start: 5.5870000000000015,
                stop: 5.847875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AFTER ',
                width: 1080,
                height: 1920,
                start: 5.847875,
                stop: 6.108687900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 6.108687900000001,
                stop: 6.1739379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 6.1739379,
                stop: 6.326124999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FIRED ',
                width: 1080,
                height: 1920,
                start: 6.326124999999999,
                stop: 6.6739379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FROM ',
                width: 1080,
                height: 1920,
                start: 6.6739379,
                stop: 6.815250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 6.815250000000001,
                stop: 6.956562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OWN ',
                width: 1080,
                height: 1920,
                start: 6.956562,
                stop: 7.108687900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' COMPANY, ',
                width: 1080,
                height: 1920,
                start: 7.108687900000001,
                stop: 7.728312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 7.728312000000001,
                stop: 7.8370000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' KEPT ',
                width: 1080,
                height: 1920,
                start: 7.8370000000000015,
                stop: 8.097875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BELIEVING ',
                width: 1080,
                height: 1920,
                start: 8.097875,
                stop: 8.554374999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 8.554374999999999,
                stop: 8.554374999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 8.554374999999999,
                stop: 8.706562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OWN ',
                width: 1080,
                height: 1920,
                start: 8.706562,
                stop: 8.869562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' VISION ',
                width: 1080,
                height: 1920,
                start: 8.869562,
                stop: 9.304374999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 9.304374999999999,
                stop: 9.434811999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NEVER ',
                width: 1080,
                height: 1920,
                start: 9.434811999999999,
                stop: 9.717437899999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GAVE ',
                width: 1080,
                height: 1920,
                start: 9.717437899999998,
                stop: 9.934811999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP',
                width: 1080,
                height: 1920,
                start: 9.934811999999999,
                stop: 10.173937
              }
            ]
          },
          {
            duration: 11.489124,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_nhdehm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*STEVE* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.34781199999999757
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOBS* ',
                width: 1080,
                height: 1920,
                start: 0.34781199999999757,
                stop: 0.7282499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SHOWED ',
                width: 1080,
                height: 1920,
                start: 0.7282499999999992,
                stop: 0.9347499999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.9347499999999975,
                stop: 1.043499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 1.043499999999998,
                stop: 1.2173739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ANYTHING ',
                width: 1080,
                height: 1920,
                start: 1.2173739999999995,
                stop: 1.5326239999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.5326239999999984,
                stop: 1.630436999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *POSSIBLE* ',
                width: 1080,
                height: 1920,
                start: 1.630436999999997,
                stop: 2.1086869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IF ',
                width: 1080,
                height: 1920,
                start: 2.1086869999999998,
                stop: 2.2064999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 2.2064999999999984,
                stop: 2.293499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' JUST ',
                width: 1080,
                height: 1920,
                start: 2.293499999999998,
                stop: 2.5217499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BELIEVE* ',
                width: 1080,
                height: 1920,
                start: 2.5217499999999973,
                stop: 2.8260619999999967
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 2.8260619999999967,
                stop: 2.891311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOURSELF ',
                width: 1080,
                height: 1920,
                start: 2.891311999999999,
                stop: 3.3478119999999976
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.3478119999999976,
                stop: 3.4456239999999987
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NEVER ',
                width: 1080,
                height: 1920,
                start: 3.4456239999999987,
                stop: 3.7173739999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GIVE ',
                width: 1080,
                height: 1920,
                start: 3.7173739999999995,
                stop: 3.891311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP. ',
                width: 1080,
                height: 1920,
                start: 3.891311999999999,
                stop: 4.880436999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO ',
                width: 1080,
                height: 1920,
                start: 4.880436999999997,
                stop: 5.108687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHAT ',
                width: 1080,
                height: 1920,
                start: 5.108687,
                stop: 5.228249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 5.228249999999999,
                stop: 5.282623999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 5.282623999999995,
                stop: 5.369561999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAITING ',
                width: 1080,
                height: 1920,
                start: 5.369561999999998,
                stop: 5.6847499999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR? ',
                width: 1080,
                height: 1920,
                start: 5.6847499999999975,
                stop: 6.793499999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 6.793499999999998,
                stop: 7.119561999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 7.119561999999998,
                stop: 7.217373999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DREAMS* ',
                width: 1080,
                height: 1920,
                start: 7.217373999999996,
                stop: 7.717373999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 7.717373999999996,
                stop: 7.815186999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MAKE ',
                width: 1080,
                height: 1920,
                start: 7.815186999999998,
                stop: 7.978249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 7.978249999999999,
                stop: 8.119561999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OWN ',
                width: 1080,
                height: 1920,
                start: 8.119561999999998,
                stop: 8.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORY!*',
                width: 1080,
                height: 1920,
                start: 8.27175,
                stop: 8.836935999999998
              }
            ]
          },
          {
            duration: 3.347812999999995,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_gmv0sg.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*LIKE* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.29349999999999454
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 0.29349999999999454,
                stop: 0.38043799999999806
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SUBSCRIBE* ',
                width: 1080,
                height: 1920,
                start: 0.38043799999999806,
                stop: 0.9239379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.9239379999999997,
                stop: 1.0434999999999945
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 1.0434999999999945,
                stop: 1.260875999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INSPIRATIONAL* ',
                width: 1080,
                height: 1920,
                start: 1.260875999999996,
                stop: 1.847812999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STORIES!',
                width: 1080,
                height: 1920,
                start: 1.847812999999995,
                stop: 2.510876000000003
              }
            ]
          }
        ],
        meta: {
          title: 'The Inspiring Story of Steve Jobs',
          description:
            'The story of Steve Jobs is an inspiration for all of us. From his struggles to his success, he taught us to never give up and to always follow our dreams.',
          hashtags: '#SteveJobs #Apple #InspirationalStory #shorts',
          mediaDescription:
            'A portrait of Steve Jobs looking determined and determined to pursue his dream.',
          category: 'Bedstory',
          prompt: 'Steve Jobs'
        }
      }
    ],
    category: 'Bedstory',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_gmv0sg.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688041046/woxo-videos-user-gallery/_dreaming/blob_nhdehm.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Hey kids, wanna hear an amazing story about Steve Jobs?',
            captions:
              'Hey ~ *kids*, ~ wanna ~ hear ~ an ~ *amazing* ~ story ~ about ~ *Steve* ~ *Jobs?*'
          },
          {
            voiceover:
              'Steve Jobs was a man with a dream. He wanted to make the world a better place and he did it by creating Apple. He taught us that if you have a dream, no matter how big, you can make it into reality.',
            captions:
              '*Steve* ~ *Jobs* ~ was ~ a ~ man ~ with ~ a ~ *dream*. ~ He ~ wanted ~ to ~ make ~ the ~ world ~ a ~ better ~ place ~ and ~ he ~ did ~ it ~ by ~ creating ~ *Apple*. ~ He ~ taught ~ us ~ that ~if ~ you ~ have ~ a ~ *dream*, ~ no ~ matter ~ how ~ *big*, ~ you ~ can ~ make ~ it ~ into ~ *reality*'
          },
          {
            voiceover:
              'He had to face many obstacles along the way, but his courage and determination were stronger than all of them. Even after he was fired from his own company, he kept believing in his own vision and never gave up.',
            captions:
              'He ~ had ~ to ~ face ~ many ~*obstacles* ~ along ~ the ~ way, ~ but ~ his ~ *courage* ~ and ~ *determination* ~ were ~ *stronger* ~ than ~ all ~ of ~ them. ~ Even ~ after ~ he ~ was ~ fired ~ from ~ his ~ own ~ company, ~ he ~ kept ~ believing ~ in ~ his ~ own ~ vision ~ and ~ never ~ gave ~ up'
          },
          {
            voiceover:
              'Steve Jobs showed us that anything is possible if you just believe in yourself and never give up. So what are you waiting for? Follow your dreams and make your own story!',
            captions:
              '*Steve* ~ *Jobs* ~ showed ~ us ~ that ~ anything ~ is ~ *possible* ~ if ~ you ~ just ~ *believe* ~ in ~ yourself ~ and ~ never ~ give ~ up. ~ So ~ what ~ are ~ you ~ waiting ~ for? ~ *Follow* ~ your ~ *dreams* ~ and ~ make ~ your ~ own ~ *story!*'
          },
          {
            voiceover: 'Like and subscribe for more inspirational stories!',
            captions: '*Like* ~ and ~ *subscribe* ~ for ~ more ~ *inspirational* ~ stories!'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a bedstory for kids about Steve Jobs and return it as a VALID array of JSON objects (Language English)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Steve Jobs",          \n       "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an amazing story about Steve Jobs?"\n        },\n        {\n        "voiceover": "Tell a bedstory for kids about Steve Jobs (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },                 \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Steve Jobs"\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/uJEDHRzt8a/lp_RP.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Hey  kids,  wanna  hear  an  amazing  story  about  Steve  Jobs?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Steve  Jobs  was  a  man  with  a  dream.  He  wanted  to  make  the  world  a  better  place  and  he  did  it  by  creating  Apple.  He  taught  us  that if  you  have  a  dream,  no  matter  how  big,  you  can  make  it  into  reality</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">He  had  to  face  many obstacles  along  the  way,  but  his  courage  and  determination  were  stronger  than  all  of  them.  Even  after  he  was  fired  from  his  own  company,  he  kept  believing  in  his  own  vision  and  never  gave  up</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Steve  Jobs  showed  us  that  anything  is  possible  if  you  just  believe  in  yourself  and  never  give  up.  So  what  are  you  waiting  for?  Follow  your  dreams  and  make  your  own  story!</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Like  and  subscribe  for  more  inspirational  stories!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.967375
              },
              {
                markName: 'scene_1',
                timeSeconds: 14.956562
              },
              {
                markName: 'scene_2',
                timeSeconds: 26.1304379
              },
              {
                markName: 'scene_3',
                timeSeconds: 35.858688
              },
              {
                markName: 'scene_4',
                timeSeconds: 40.032562
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Hey',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'kids',
                timeSeconds: 0.260875,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 267,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 0.75,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 271,
                wordLength: 1
              },
              {
                word: 'wanna',
                timeSeconds: 0.826062,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 274,
                wordLength: 5
              },
              {
                word: 'hear',
                timeSeconds: 1.010875,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 281,
                wordLength: 4
              },
              {
                word: 'an',
                timeSeconds: 1.1848119,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 287,
                wordLength: 2
              },
              {
                word: 'amazing',
                timeSeconds: 1.239125,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 291,
                wordLength: 7
              },
              {
                word: 'story',
                timeSeconds: 1.630438,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 300,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 1.97825,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 307,
                wordLength: 5
              },
              {
                word: 'Steve',
                timeSeconds: 2.2065,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 314,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 2.47825,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 321,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.97825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 325,
                wordLength: 1
              },
              {
                word: 'Steve',
                timeSeconds: 4.72825,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 408,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 5.097812,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 415,
                wordLength: 4
              },
              {
                word: 'was',
                timeSeconds: 5.467375,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 421,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 5.608688,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 426,
                wordLength: 1
              },
              {
                word: 'man',
                timeSeconds: 5.663062,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 429,
                wordLength: 3
              },
              {
                word: 'with',
                timeSeconds: 5.923938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 434,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 6.0435,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 440,
                wordLength: 1
              },
              {
                word: 'dream',
                timeSeconds: 6.108688,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 443,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 6.510875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 448,
                wordLength: 1
              },
              {
                word: 'He',
                timeSeconds: 7.25,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 451,
                wordLength: 2
              },
              {
                word: 'wanted',
                timeSeconds: 7.369562,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 455,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 7.597812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 463,
                wordLength: 2
              },
              {
                word: 'make',
                timeSeconds: 7.663062,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 467,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 7.847812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 473,
                wordLength: 3
              },
              {
                word: 'world',
                timeSeconds: 7.934812,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 478,
                wordLength: 5
              },
              {
                word: 'a',
                timeSeconds: 8.217375,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 485,
                wordLength: 1
              },
              {
                word: 'better',
                timeSeconds: 8.260875,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 488,
                wordLength: 6
              },
              {
                word: 'place',
                timeSeconds: 8.52175,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 496,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 8.891312,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 503,
                wordLength: 3
              },
              {
                word: 'he',
                timeSeconds: 8.989125,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 508,
                wordLength: 2
              },
              {
                word: 'did',
                timeSeconds: 9.086938,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 512,
                wordLength: 3
              },
              {
                word: 'it',
                timeSeconds: 9.260875,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 517,
                wordLength: 2
              },
              {
                word: 'by',
                timeSeconds: 9.358688,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 521,
                wordLength: 2
              },
              {
                word: 'creating',
                timeSeconds: 9.5,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 525,
                wordLength: 8
              },
              {
                word: 'Apple',
                timeSeconds: 9.902188,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 535,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 10.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 540,
                wordLength: 1
              },
              {
                word: 'He',
                timeSeconds: 11.0435,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 543,
                wordLength: 2
              },
              {
                word: 'taught',
                timeSeconds: 11.195625,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 547,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 11.445625,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 555,
                wordLength: 2
              },
              {
                word: 'that',
                timeSeconds: 11.597812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 559,
                wordLength: 4
              },
              {
                word: 'if',
                timeSeconds: 11.7065,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 564,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 11.804375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 568,
                wordLength: 3
              },
              {
                word: 'have',
                timeSeconds: 11.913062,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 573,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 12.054375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 579,
                wordLength: 1
              },
              {
                word: 'dream',
                timeSeconds: 12.130438,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 582,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 12.597812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 587,
                wordLength: 1
              },
              {
                word: 'no',
                timeSeconds: 12.695625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 590,
                wordLength: 2
              },
              {
                word: 'matter',
                timeSeconds: 12.7935,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 594,
                wordLength: 6
              },
              {
                word: 'how',
                timeSeconds: 13.065188,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 602,
                wordLength: 3
              },
              {
                word: 'big',
                timeSeconds: 13.22825,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 607,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 13.608688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 610,
                wordLength: 1
              },
              {
                word: 'you',
                timeSeconds: 13.717375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 613,
                wordLength: 3
              },
              {
                word: 'can',
                timeSeconds: 13.826062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 618,
                wordLength: 3
              },
              {
                word: 'make',
                timeSeconds: 13.9565,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 623,
                wordLength: 4
              },
              {
                word: 'it',
                timeSeconds: 14.163062,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 629,
                wordLength: 2
              },
              {
                word: 'into',
                timeSeconds: 14.22825,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 633,
                wordLength: 4
              },
              {
                word: 'reality',
                timeSeconds: 14.434812,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 639,
                wordLength: 7
              },
              {
                word: 'He',
                timeSeconds: 15.9565,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 728,
                wordLength: 2
              },
              {
                word: 'had',
                timeSeconds: 16.054375,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 732,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 16.184812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 737,
                wordLength: 2
              },
              {
                word: 'face',
                timeSeconds: 16.25,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 741,
                wordLength: 4
              },
              {
                word: 'many',
                timeSeconds: 16.52175,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 747,
                wordLength: 4
              },
              {
                word: 'obstacles',
                timeSeconds: 16.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 752,
                wordLength: 9
              },
              {
                word: 'along',
                timeSeconds: 17.347812,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 763,
                wordLength: 5
              },
              {
                word: 'the',
                timeSeconds: 17.619562,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 770,
                wordLength: 3
              },
              {
                word: 'way',
                timeSeconds: 17.695625,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 775,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 18.0435,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 778,
                wordLength: 1
              },
              {
                word: 'but',
                timeSeconds: 18.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 781,
                wordLength: 3
              },
              {
                word: 'his',
                timeSeconds: 18.27175,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 786,
                wordLength: 3
              },
              {
                word: 'courage',
                timeSeconds: 18.391312,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 791,
                wordLength: 7
              },
              {
                word: 'and',
                timeSeconds: 18.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 800,
                wordLength: 3
              },
              {
                word: 'determination',
                timeSeconds: 18.891312,
                boundaryType: 'WordBoundary',
                duration: 0.684812,
                textOffset: 805,
                wordLength: 13
              },
              {
                word: 'were',
                timeSeconds: 19.6086879,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 820,
                wordLength: 4
              },
              {
                word: 'stronger',
                timeSeconds: 19.7065,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 826,
                wordLength: 8
              },
              {
                word: 'than',
                timeSeconds: 20.163062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 836,
                wordLength: 4
              },
              {
                word: 'all',
                timeSeconds: 20.2935,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 842,
                wordLength: 3
              },
              {
                word: 'of',
                timeSeconds: 20.4565,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 847,
                wordLength: 2
              },
              {
                word: 'them',
                timeSeconds: 20.5435,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 851,
                wordLength: 4
              },
              {
                word: '.',
                timeSeconds: 20.804375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 855,
                wordLength: 1
              },
              {
                word: 'Even',
                timeSeconds: 21.5435,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 858,
                wordLength: 4
              },
              {
                word: 'after',
                timeSeconds: 21.804375,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 864,
                wordLength: 5
              },
              {
                word: 'he',
                timeSeconds: 22.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 871,
                wordLength: 2
              },
              {
                word: 'was',
                timeSeconds: 22.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 875,
                wordLength: 3
              },
              {
                word: 'fired',
                timeSeconds: 22.282625,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 880,
                wordLength: 5
              },
              {
                word: 'from',
                timeSeconds: 22.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 887,
                wordLength: 4
              },
              {
                word: 'his',
                timeSeconds: 22.77175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 893,
                wordLength: 3
              },
              {
                word: 'own',
                timeSeconds: 22.913062,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 898,
                wordLength: 3
              },
              {
                word: 'company',
                timeSeconds: 23.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 903,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 23.554375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 910,
                wordLength: 1
              },
              {
                word: 'he',
                timeSeconds: 23.684812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 913,
                wordLength: 2
              },
              {
                word: 'kept',
                timeSeconds: 23.7935,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 917,
                wordLength: 4
              },
              {
                word: 'believing',
                timeSeconds: 24.054375,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 923,
                wordLength: 9
              },
              {
                word: 'in',
                timeSeconds: 24.445625,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 934,
                wordLength: 2
              },
              {
                word: 'his',
                timeSeconds: 24.510875,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 938,
                wordLength: 3
              },
              {
                word: 'own',
                timeSeconds: 24.663062,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 943,
                wordLength: 3
              },
              {
                word: 'vision',
                timeSeconds: 24.826062,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 948,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 25.260875,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 956,
                wordLength: 3
              },
              {
                word: 'never',
                timeSeconds: 25.391312,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 961,
                wordLength: 5
              },
              {
                word: 'gave',
                timeSeconds: 25.6739379,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 968,
                wordLength: 4
              },
              {
                word: 'up',
                timeSeconds: 25.891312,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 974,
                wordLength: 2
              },
              {
                word: 'Steve',
                timeSeconds: 27.130438,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 1058,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 27.47825,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 1065,
                wordLength: 4
              },
              {
                word: 'showed',
                timeSeconds: 27.858688,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1071,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 28.065188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1079,
                wordLength: 2
              },
              {
                word: 'that',
                timeSeconds: 28.173938,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1083,
                wordLength: 4
              },
              {
                word: 'anything',
                timeSeconds: 28.347812,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1089,
                wordLength: 8
              },
              {
                word: 'is',
                timeSeconds: 28.663062,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1099,
                wordLength: 2
              },
              {
                word: 'possible',
                timeSeconds: 28.760875,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 1103,
                wordLength: 8
              },
              {
                word: 'if',
                timeSeconds: 29.239125,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1113,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 29.336938,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1117,
                wordLength: 3
              },
              {
                word: 'just',
                timeSeconds: 29.423938,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 1122,
                wordLength: 4
              },
              {
                word: 'believe',
                timeSeconds: 29.652188,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1128,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 29.9565,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 1137,
                wordLength: 2
              },
              {
                word: 'yourself',
                timeSeconds: 30.02175,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 1141,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 30.47825,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1151,
                wordLength: 3
              },
              {
                word: 'never',
                timeSeconds: 30.576062,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 1156,
                wordLength: 5
              },
              {
                word: 'give',
                timeSeconds: 30.847812,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1163,
                wordLength: 4
              },
              {
                word: 'up',
                timeSeconds: 31.02175,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1169,
                wordLength: 2
              },
              {
                word: '.',
                timeSeconds: 31.27175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1171,
                wordLength: 1
              },
              {
                word: 'So',
                timeSeconds: 32.010875,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 1174,
                wordLength: 2
              },
              {
                word: 'what',
                timeSeconds: 32.239125,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1178,
                wordLength: 4
              },
              {
                word: 'are',
                timeSeconds: 32.358688,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 1184,
                wordLength: 3
              },
              {
                word: 'you',
                timeSeconds: 32.413062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1189,
                wordLength: 3
              },
              {
                word: 'waiting',
                timeSeconds: 32.5,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1194,
                wordLength: 7
              },
              {
                word: 'for',
                timeSeconds: 32.815188,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 1203,
                wordLength: 3
              },
              {
                word: '?',
                timeSeconds: 33.173938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1206,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 33.923938,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 1209,
                wordLength: 6
              },
              {
                word: 'your',
                timeSeconds: 34.25,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1217,
                wordLength: 4
              },
              {
                word: 'dreams',
                timeSeconds: 34.347812,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 1223,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 34.847812,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1231,
                wordLength: 3
              },
              {
                word: 'make',
                timeSeconds: 34.945625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 1236,
                wordLength: 4
              },
              {
                word: 'your',
                timeSeconds: 35.108688,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1242,
                wordLength: 4
              },
              {
                word: 'own',
                timeSeconds: 35.25,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1248,
                wordLength: 3
              },
              {
                word: 'story',
                timeSeconds: 35.402188,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 1253,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 35.869562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1258,
                wordLength: 1
              },
              {
                word: 'Like',
                timeSeconds: 37.619562,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 1341,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 37.913062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1347,
                wordLength: 3
              },
              {
                word: 'subscribe',
                timeSeconds: 38,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 1352,
                wordLength: 9
              },
              {
                word: 'for',
                timeSeconds: 38.5435,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1363,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 38.663062,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1368,
                wordLength: 4
              },
              {
                word: 'inspirational',
                timeSeconds: 38.880438,
                boundaryType: 'WordBoundary',
                duration: 0.576062,
                textOffset: 1374,
                wordLength: 13
              },
              {
                word: 'stories',
                timeSeconds: 39.467375,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 1389,
                wordLength: 7
              },
              {
                word: '!',
                timeSeconds: 40.0435,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1396,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Bad_Love_Niwel_Remix_-_Niwel.mp3'
    ]
  },
  {
    id: '8b652967-e746-445c-a9ff-26985bd9e608',
    text: 'A Horror Story about "Steve Jobs"',
    videos: [
      {
        id: 'f1d3c6ea-34e9-45b8-96ab-d2baa3bb7d07',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/skaters_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/1XUXJdOtj1/iXOjC.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.869562,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_o0kcnd.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*EVER* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.260875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HEARD* ',
                width: 1080,
                height: 1920,
                start: 0.260875,
                stop: 0.434812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 0.434812,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.554375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HORRIFIC* ',
                width: 1080,
                height: 1920,
                start: 0.554375,
                stop: 0.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TALE* ',
                width: 1080,
                height: 1920,
                start: 0.9565,
                stop: 1.173938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.173938,
                stop: 1.402188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STEVE* ',
                width: 1080,
                height: 1920,
                start: 1.402188,
                stop: 1.6630619
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *JOBS?*',
                width: 1080,
                height: 1920,
                start: 1.6630619,
                stop: 2.260874
              }
            ]
          },
          {
            duration: 8.836938,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_pkgmtp.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*RUMORS* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.38043799999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SAY ',
                width: 1080,
                height: 1920,
                start: 0.38043799999999983,
                stop: 0.6304379999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 0.6304379999999998,
                stop: 0.7391259999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HE ',
                width: 1080,
                height: 1920,
                start: 0.7391259999999997,
                stop: 0.815188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STILL ',
                width: 1080,
                height: 1920,
                start: 0.815188,
                stop: 1.0978130000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HAUNTS* ',
                width: 1080,
                height: 1920,
                start: 1.0978130000000004,
                stop: 1.336938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.336938,
                stop: 1.4347500000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *APPLE* ',
                width: 1080,
                height: 1920,
                start: 1.4347500000000002,
                stop: 1.72825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CAMPUS.* ',
                width: 1080,
                height: 1920,
                start: 1.72825,
                stop: 2.9891259999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 2.9891259999999997,
                stop: 3.18475
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GHOSTLY* ',
                width: 1080,
                height: 1920,
                start: 3.18475,
                stop: 3.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FIGURE ',
                width: 1080,
                height: 1920,
                start: 3.586938,
                stop: 3.945626
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' APPEARS ',
                width: 1080,
                height: 1920,
                start: 3.945626,
                stop: 4.3043130000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.3043130000000005,
                stop: 4.358688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.358688000000001,
                stop: 4.423876
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SHADOWS,* ',
                width: 1080,
                height: 1920,
                start: 4.423876,
                stop: 5.065187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WITH* ',
                width: 1080,
                height: 1920,
                start: 5.065187999999999,
                stop: 5.217376
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 5.217376,
                stop: 5.271750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MENACING ',
                width: 1080,
                height: 1920,
                start: 5.271750000000001,
                stop: 5.673876
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GLARE.*',
                width: 1080,
                height: 1920,
                start: 5.673876,
                stop: 6.184749999999999
              }
            ]
          },
          {
            duration: 8.554375,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_o0kcnd.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.13043799999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SAY ',
                width: 1080,
                height: 1920,
                start: 0.13043799999999983,
                stop: 0.4239379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 0.4239379999999997,
                stop: 0.5434999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 0.5434999999999999,
                stop: 0.6521880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SPIRIT* ',
                width: 1080,
                height: 1920,
                start: 0.6521880000000007,
                stop: 1.0217500000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SEEKS* ',
                width: 1080,
                height: 1920,
                start: 1.0217500000000008,
                stop: 1.3586880000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *VENGEANCE* ',
                width: 1080,
                height: 1920,
                start: 1.3586880000000008,
                stop: 1.7934999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ON ',
                width: 1080,
                height: 1920,
                start: 1.7934999999999999,
                stop: 1.9239379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THOSE ',
                width: 1080,
                height: 1920,
                start: 1.9239379999999997,
                stop: 2.130438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHO ',
                width: 1080,
                height: 1920,
                start: 2.130438,
                stop: 2.228249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CROSS ',
                width: 1080,
                height: 1920,
                start: 2.228249999999999,
                stop: 2.5435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIM. ',
                width: 1080,
                height: 1920,
                start: 2.5435,
                stop: 3.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " HE'LL ",
                width: 1080,
                height: 1920,
                start: 3.5,
                stop: 3.7065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NEVER ',
                width: 1080,
                height: 1920,
                start: 3.7065,
                stop: 4
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REST* ',
                width: 1080,
                height: 1920,
                start: 4,
                stop: 4.2826249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UNTIL ',
                width: 1080,
                height: 1920,
                start: 4.2826249999999995,
                stop: 4.5326249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIS ',
                width: 1080,
                height: 1920,
                start: 4.5326249999999995,
                stop: 4.652187900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ORDERS* ',
                width: 1080,
                height: 1920,
                start: 4.652187900000001,
                stop: 5.043500000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ARE ',
                width: 1080,
                height: 1920,
                start: 5.043500000000002,
                stop: 5.1739378999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CARRIED* ',
                width: 1080,
                height: 1920,
                start: 5.1739378999999985,
                stop: 5.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OUT.',
                width: 1080,
                height: 1920,
                start: 5.478249999999999,
                stop: 5.902187000000001
              }
            ]
          },
          {
            duration: 5.630437000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_pkgmtp.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'IF ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.14124999999999943
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.14124999999999943,
                stop: 0.23912500000000136
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EVER* ',
                width: 1080,
                height: 1920,
                start: 0.23912500000000136,
                stop: 0.4238749999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' COME ',
                width: 1080,
                height: 1920,
                start: 0.4238749999999989,
                stop: 0.5869369999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ACROSS* ',
                width: 1080,
                height: 1920,
                start: 0.5869369999999989,
                stop: 0.9238749999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HIM, ',
                width: 1080,
                height: 1920,
                start: 0.9238749999999989,
                stop: 1.2608129000000012
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " YOU'LL ",
                width: 1080,
                height: 1920,
                start: 1.2608129000000012,
                stop: 1.423874999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KNOW* ',
                width: 1080,
                height: 1920,
                start: 1.423874999999999,
                stop: 1.6086869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " YOU'VE ",
                width: 1080,
                height: 1920,
                start: 1.6086869999999998,
                stop: 1.7825628999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MADE ',
                width: 1080,
                height: 1920,
                start: 1.7825628999999985,
                stop: 2.043437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 2.043437000000001,
                stop: 2.043437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TERRIBLE* ',
                width: 1080,
                height: 1920,
                start: 2.043437000000001,
                stop: 2.402124999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MISTAKE.*',
                width: 1080,
                height: 1920,
                start: 2.402124999999998,
                stop: 2.9782490000000017
              }
            ]
          },
          {
            duration: 2.902125899999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_o0kcnd.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.33693799999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 0.33693799999999996,
                stop: 0.45649999999999835
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.45649999999999835,
                stop: 0.5760629999999978
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.5760629999999978,
                stop: 0.7825629999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SPINE-CHILLING* ',
                width: 1080,
                height: 1920,
                start: 0.7825629999999997,
                stop: 1.402125899999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORIES!*',
                width: 1080,
                height: 1920,
                start: 1.402125899999998,
                stop: 2.0108138999999987
              }
            ]
          }
        ],
        meta: {
          title: 'The Terrifying Tale of Steve Jobs',
          description:
            'The Apple Campus has a dark secret. Steve Jobs still haunts it, seeking vengeance on those who cross him. Will you cross him? Find out more by watching this video!',
          hashtags: '#apple #stevejobs #horror #mystery #scary #shorts',
          mediaDescription: 'A dark portrait of Steve Jobs, with a menacing glare.',
          category: 'Horror Story',
          prompt: 'Steve Jobs'
        }
      }
    ],
    category: 'Horror Story',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_o0kcnd.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688040455/woxo-videos-user-gallery/_dreaming/blob_pkgmtp.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Ever heard of a horrific tale about Steve Jobs?',
            captions: '*Ever* ~ *heard* ~ of ~ a ~ *horrific* ~ *tale* ~ about ~ *Steve* ~ *Jobs?*'
          },
          {
            voiceover:
              'Rumors say that he still haunts the Apple Campus. His ghostly figure appears in the shadows, with a menacing glare.',
            captions:
              '*Rumors* ~ say ~ that ~ he ~ still ~ *haunts* ~ the ~ *Apple* ~ *Campus.* ~ His ~ *ghostly* ~ figure ~ appears ~ in ~ the ~ *shadows,* ~ *with* ~ a ~ menacing ~ *glare.*'
          },
          {
            voiceover:
              "They say that his spirit seeks vengeance on those who cross him. He'll never rest until his orders are carried out.",
            captions:
              "They ~ say ~ that ~ his ~ *spirit* ~ *seeks* ~ *vengeance* ~ on ~ those ~ who ~ cross ~ him. ~ He'll ~ never ~ *rest* ~ until ~ his ~ *orders* ~ are ~ *carried* ~ out."
          },
          {
            voiceover: "If you ever come across him, you'll know you've made a terrible mistake.",
            captions:
              "If ~ you ~ *ever* ~ come ~ *across* ~ him, ~ you'll ~ *know* ~ you've ~ made ~ a ~ *terrible* ~ *mistake.*"
          },
          {
            voiceover: 'Follow me for more spine-chilling stories!',
            captions: '*Follow* ~ me ~ for ~ more ~ *spine-chilling* ~ *stories!*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a horror story about Steve Jobs and return it as a VALID array of JSON objects (Language English)\n   Example:\n   [\n    {\n      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Steve Jobs",          \n      "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an scary story about Steve Jobs?"\n        },\n        {\n          "voiceover": "Write an horror story about Steve Jobs (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },          \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n      ],\n      "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Steve Jobs"\n      }\n    }\n ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/1XUXJdOtj1/iXOjC.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Ever  heard  of  a  horrific  tale  about  Steve  Jobs?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Rumors  say  that  he  still  haunts  the  Apple  Campus.  His  ghostly  figure  appears  in  the  shadows,  with  a  menacing  glare.</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">They  say  that  his  spirit  seeks  vengeance  on  those  who  cross  him.  He\'ll  never  rest  until  his  orders  are  carried  out.</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">If  you  ever  come  across  him,  you\'ll  know  you\'ve  made  a  terrible  mistake.</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  me  for  more  spine-chilling  stories!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.152188
              },
              {
                markName: 'scene_1',
                timeSeconds: 9.989125
              },
              {
                markName: 'scene_2',
                timeSeconds: 17.5434379
              },
              {
                markName: 'scene_3',
                timeSeconds: 22.173875
              },
              {
                markName: 'scene_4',
                timeSeconds: 25.847812
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Ever',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 262,
                wordLength: 4
              },
              {
                word: 'heard',
                timeSeconds: 0.260875,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 268,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 0.434812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 275,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 0.5,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 279,
                wordLength: 1
              },
              {
                word: 'horrific',
                timeSeconds: 0.554375,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 282,
                wordLength: 8
              },
              {
                word: 'tale',
                timeSeconds: 0.9565,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 292,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 1.173938,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 298,
                wordLength: 5
              },
              {
                word: 'Steve',
                timeSeconds: 1.402188,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 305,
                wordLength: 5
              },
              {
                word: 'Jobs',
                timeSeconds: 1.6630619,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 312,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.163062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 316,
                wordLength: 1
              },
              {
                word: 'Rumors',
                timeSeconds: 3.913062,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 399,
                wordLength: 6
              },
              {
                word: 'say',
                timeSeconds: 4.2935,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 407,
                wordLength: 3
              },
              {
                word: 'that',
                timeSeconds: 4.5435,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 412,
                wordLength: 4
              },
              {
                word: 'he',
                timeSeconds: 4.652188,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 418,
                wordLength: 2
              },
              {
                word: 'still',
                timeSeconds: 4.72825,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 422,
                wordLength: 5
              },
              {
                word: 'haunts',
                timeSeconds: 5.010875,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 429,
                wordLength: 6
              },
              {
                word: 'the',
                timeSeconds: 5.25,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 437,
                wordLength: 3
              },
              {
                word: 'Apple',
                timeSeconds: 5.347812,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 442,
                wordLength: 5
              },
              {
                word: 'Campus',
                timeSeconds: 5.641312,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 449,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 6.163062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 455,
                wordLength: 1
              },
              {
                word: 'His',
                timeSeconds: 6.902188,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 458,
                wordLength: 3
              },
              {
                word: 'ghostly',
                timeSeconds: 7.097812,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 463,
                wordLength: 7
              },
              {
                word: 'figure',
                timeSeconds: 7.5,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 472,
                wordLength: 6
              },
              {
                word: 'appears',
                timeSeconds: 7.858688,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 480,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 8.217375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 489,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 8.27175,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 493,
                wordLength: 3
              },
              {
                word: 'shadows',
                timeSeconds: 8.336938,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 498,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 8.913062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 505,
                wordLength: 1
              },
              {
                word: 'with',
                timeSeconds: 8.97825,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 508,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 9.130438,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 514,
                wordLength: 1
              },
              {
                word: 'menacing',
                timeSeconds: 9.184812,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 517,
                wordLength: 8
              },
              {
                word: 'glare',
                timeSeconds: 9.586938,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 527,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 10,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 532,
                wordLength: 1
              },
              {
                word: 'They',
                timeSeconds: 11.75,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 615,
                wordLength: 4
              },
              {
                word: 'say',
                timeSeconds: 11.880438,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 621,
                wordLength: 3
              },
              {
                word: 'that',
                timeSeconds: 12.173938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 626,
                wordLength: 4
              },
              {
                word: 'his',
                timeSeconds: 12.2935,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 632,
                wordLength: 3
              },
              {
                word: 'spirit',
                timeSeconds: 12.402188,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 637,
                wordLength: 6
              },
              {
                word: 'seeks',
                timeSeconds: 12.77175,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 645,
                wordLength: 5
              },
              {
                word: 'vengeance',
                timeSeconds: 13.108688,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 652,
                wordLength: 9
              },
              {
                word: 'on',
                timeSeconds: 13.5435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 663,
                wordLength: 2
              },
              {
                word: 'those',
                timeSeconds: 13.673938,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 667,
                wordLength: 5
              },
              {
                word: 'who',
                timeSeconds: 13.880438,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 674,
                wordLength: 3
              },
              {
                word: 'cross',
                timeSeconds: 13.97825,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 679,
                wordLength: 5
              },
              {
                word: 'him',
                timeSeconds: 14.2935,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 686,
                wordLength: 3
              },
              {
                word: '.',
                timeSeconds: 14.510875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 689,
                wordLength: 1
              },
              {
                word: "He'll",
                timeSeconds: 15.25,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 692,
                wordLength: 5
              },
              {
                word: 'never',
                timeSeconds: 15.4565,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 699,
                wordLength: 5
              },
              {
                word: 'rest',
                timeSeconds: 15.75,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 706,
                wordLength: 4
              },
              {
                word: 'until',
                timeSeconds: 16.032625,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 712,
                wordLength: 5
              },
              {
                word: 'his',
                timeSeconds: 16.282625,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 719,
                wordLength: 3
              },
              {
                word: 'orders',
                timeSeconds: 16.4021879,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 724,
                wordLength: 6
              },
              {
                word: 'are',
                timeSeconds: 16.7935,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 732,
                wordLength: 3
              },
              {
                word: 'carried',
                timeSeconds: 16.9239379,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 737,
                wordLength: 7
              },
              {
                word: 'out',
                timeSeconds: 17.22825,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 746,
                wordLength: 3
              },
              {
                word: '.',
                timeSeconds: 17.554375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 749,
                wordLength: 1
              },
              {
                word: 'If',
                timeSeconds: 19.304375,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 832,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 19.445625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 836,
                wordLength: 3
              },
              {
                word: 'ever',
                timeSeconds: 19.5435,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 841,
                wordLength: 4
              },
              {
                word: 'come',
                timeSeconds: 19.72825,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 847,
                wordLength: 4
              },
              {
                word: 'across',
                timeSeconds: 19.891312,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 853,
                wordLength: 6
              },
              {
                word: 'him',
                timeSeconds: 20.22825,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 861,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 20.489125,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 864,
                wordLength: 1
              },
              {
                word: "you'll",
                timeSeconds: 20.5651879,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 867,
                wordLength: 6
              },
              {
                word: 'know',
                timeSeconds: 20.72825,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 875,
                wordLength: 4
              },
              {
                word: "you've",
                timeSeconds: 20.913062,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 881,
                wordLength: 6
              },
              {
                word: 'made',
                timeSeconds: 21.0869379,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 889,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 21.282625,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 895,
                wordLength: 1
              },
              {
                word: 'terrible',
                timeSeconds: 21.347812,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 898,
                wordLength: 8
              },
              {
                word: 'mistake',
                timeSeconds: 21.7065,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 908,
                wordLength: 7
              },
              {
                word: '.',
                timeSeconds: 22.184812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 915,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 23.934812,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 998,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 24.27175,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1006,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 24.391312,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1010,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 24.510875,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1015,
                wordLength: 4
              },
              {
                word: 'spine-chilling',
                timeSeconds: 24.717375,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 1021,
                wordLength: 14
              },
              {
                word: 'stories',
                timeSeconds: 25.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 1037,
                wordLength: 7
              },
              {
                word: '!',
                timeSeconds: 25.8586879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1044,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/skaters_MOODHALO.mp3'
    ]
  }
];
