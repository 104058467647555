export const data = [
  {
    id: 'b1abfcb0-7da5-4f0a-b88d-47dcd8c64427',
    text: 'A Horror Story about "Toronto"',
    videos: [
      {
        id: '0265f755-edf0-455c-87bd-cfc66da9f9bd',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM130.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/ir8H56sxFO/HTdsJ.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.304312,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_ahlsvu.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'DO ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.119562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.119562,
                stop: 0.239125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DARE* ',
                width: 1080,
                height: 1920,
                start: 0.239125,
                stop: 0.434812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.434812,
                stop: 0.489125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HEAR* ',
                width: 1080,
                height: 1920,
                start: 0.489125,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TERRIFYING* ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 1.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TALE* ',
                width: 1080,
                height: 1920,
                start: 1.27175,
                stop: 1.52175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.52175,
                stop: 1.5978119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.5978119,
                stop: 1.684812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CITY* ',
                width: 1080,
                height: 1920,
                start: 1.684812,
                stop: 1.934812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.934812,
                stop: 2.02175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO?*',
                width: 1080,
                height: 1920,
                start: 2.02175,
                stop: 2.695626
              }
            ]
          },
          {
            duration: 6.673938000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_dcwdps.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'A ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.11956299999999942
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WOMAN* ',
                width: 1080,
                height: 1920,
                start: 0.11956299999999942,
                stop: 0.4239379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WALKING* ',
                width: 1080,
                height: 1920,
                start: 0.4239379999999997,
                stop: 0.7391259999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALONE ',
                width: 1080,
                height: 1920,
                start: 0.7391259999999997,
                stop: 1.2608759999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONE ',
                width: 1080,
                height: 1920,
                start: 1.2608759999999997,
                stop: 1.2608759999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOGGY ',
                width: 1080,
                height: 1920,
                start: 1.2608759999999997,
                stop: 1.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NIGHT, ',
                width: 1080,
                height: 1920,
                start: 1.5869999999999997,
                stop: 2.06525
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 2.06525,
                stop: 2.163063
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EERIE* ',
                width: 1080,
                height: 1920,
                start: 2.163063,
                stop: 2.3913129999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FEELING ',
                width: 1080,
                height: 1920,
                start: 2.3913129999999994,
                stop: 2.8043759999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 2.8043759999999995,
                stop: 2.8043759999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.8043759999999995,
                stop: 2.858688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AIR, ',
                width: 1080,
                height: 1920,
                start: 2.858688,
                stop: 3.2935
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOMETHING ',
                width: 1080,
                height: 1920,
                start: 3.2935,
                stop: 3.6956879999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LURKING* ',
                width: 1080,
                height: 1920,
                start: 3.6956879999999996,
                stop: 4.0761259999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.0761259999999995,
                stop: 4.0761259999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.0761259999999995,
                stop: 4.141312999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SHADOWS*',
                width: 1080,
                height: 1920,
                start: 4.141312999999999,
                stop: 4.673937999999999
              }
            ]
          },
          {
            duration: 6.663062,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_ahlsvu.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'SHE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1847499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TURNS* ',
                width: 1080,
                height: 1920,
                start: 0.1847499999999993,
                stop: 0.4456249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.4456249999999997,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CORNER ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.9021879999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 0.9021879999999989,
                stop: 0.9891249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FINDS* ',
                width: 1080,
                height: 1920,
                start: 0.9891249999999996,
                stop: 1.3043119999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.3043119999999995,
                stop: 1.3695619999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATURE,* ',
                width: 1080,
                height: 1920,
                start: 1.3695619999999984,
                stop: 1.9564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ITS ',
                width: 1080,
                height: 1920,
                start: 1.9564999999999984,
                stop: 2.0760619999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EYES* ',
                width: 1080,
                height: 1920,
                start: 2.0760619999999985,
                stop: 2.3043119999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RED* ',
                width: 1080,
                height: 1920,
                start: 2.3043119999999995,
                stop: 2.5434379999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.5434379999999983,
                stop: 2.641311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PIERCING,* ',
                width: 1080,
                height: 1920,
                start: 2.641311999999999,
                stop: 3.3043119999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ITS ',
                width: 1080,
                height: 1920,
                start: 3.3043119999999995,
                stop: 3.4347499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CLAWS* ',
                width: 1080,
                height: 1920,
                start: 3.4347499999999993,
                stop: 3.7391249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SHARP* ',
                width: 1080,
                height: 1920,
                start: 3.7391249999999996,
                stop: 4.0760619999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.0760619999999985,
                stop: 4.184749999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MENACING*',
                width: 1080,
                height: 1920,
                start: 4.184749999999999,
                stop: 4.641249999999999
              }
            ]
          },
          {
            duration: 8.456499999999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_dcwdps.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.08693799999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WOMAN* ',
                width: 1080,
                height: 1920,
                start: 0.08693799999999996,
                stop: 0.41300000000000026
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RUNS,* ',
                width: 1080,
                height: 1920,
                start: 0.41300000000000026,
                stop: 0.9456258999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HER ',
                width: 1080,
                height: 1920,
                start: 0.9456258999999996,
                stop: 1.0760629999999978
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEART ',
                width: 1080,
                height: 1920,
                start: 1.0760629999999978,
                stop: 1.3151879999999991
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *POUNDING,* ',
                width: 1080,
                height: 1920,
                start: 1.3151879999999991,
                stop: 1.9347499999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONLY ',
                width: 1080,
                height: 1920,
                start: 1.9347499999999975,
                stop: 2.1303759000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.1303759000000007,
                stop: 2.1956258999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FIND* ',
                width: 1080,
                height: 1920,
                start: 2.1956258999999996,
                stop: 2.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HERSELF ',
                width: 1080,
                height: 1920,
                start: 2.478249999999999,
                stop: 2.8695629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 2.8695629999999994,
                stop: 2.9456258999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 2.9456258999999996,
                stop: 3.0325629999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DEAD-END* ',
                width: 1080,
                height: 1920,
                start: 3.0325629999999997,
                stop: 3.358688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ALLEY.* ',
                width: 1080,
                height: 1920,
                start: 3.358688000000001,
                stop: 4.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.478249999999999,
                stop: 4.597812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATURE* ',
                width: 1080,
                height: 1920,
                start: 4.597812999999999,
                stop: 5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 5,
                stop: 5.086938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMING* ',
                width: 1080,
                height: 1920,
                start: 5.086938,
                stop: 5.380375900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CLOSER ',
                width: 1080,
                height: 1920,
                start: 5.380375900000001,
                stop: 5.858688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.858688000000001,
                stop: 5.9673759
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CLOSER',
                width: 1080,
                height: 1920,
                start: 5.9673759,
                stop: 6.456500900000002
              }
            ]
          },
          {
            duration: 3.228249999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_ahlsvu.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FOR ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.11956299999999942
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.11956299999999942,
                stop: 0.3260630000000013
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*STORIES* ',
                width: 1080,
                height: 1920,
                start: 0.3260630000000013,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIKE* ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 0.9347500000000011
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS, ',
                width: 1080,
                height: 1920,
                start: 0.9347500000000011,
                stop: 1.336938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 1.336938,
                stop: 1.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 1.6195629999999994,
                stop: 1.7282499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NOW!*',
                width: 1080,
                height: 1920,
                start: 1.7282499999999992,
                stop: 2.1956260000000007
              }
            ]
          }
        ],
        meta: {
          title: 'A Horror Story of Toronto',
          description: 'A short horror story about the city of Toronto',
          hashtags: '#horror #toronto #storytime #shorts',
          mediaDescription:
            'A dark and foggy street in the night in Toronto, with a mysterious creature lurking in the shadows.',
          category: 'Horror Story',
          prompt: 'Toronto'
        }
      }
    ],
    category: 'Horror Story',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_ahlsvu.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037625/woxo-videos-user-gallery/_dreaming/blob_dcwdps.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Do you dare to hear a terrifying tale of the city of Toronto?',
            captions:
              'Do ~ you ~ *dare* ~ to ~ *hear* ~ a ~ *terrifying* ~ *tale* ~ of ~ the ~ *city* ~ of ~ *Toronto?*'
          },
          {
            voiceover:
              'A woman walking alone one foggy night, an eerie feeling in the air, something lurking in the shadows.',
            captions:
              'A ~ *woman* ~ *walking* ~ alone ~ one ~ foggy ~ night, ~ an ~ *eerie* ~ feeling ~ in ~ the ~ air, ~ something ~ *lurking* ~ in ~ the ~ *shadows*'
          },
          {
            voiceover:
              'She turns a corner and finds a creature, its eyes red and piercing, its claws sharp and menacing.',
            captions:
              'She ~ *turns* ~ a ~ corner ~ and ~ *finds* ~ a ~ *creature,* ~ its ~ *eyes* ~ *red* ~ and ~ *piercing,* ~ its ~ *claws* ~ *sharp* ~ and ~ *menacing*'
          },
          {
            voiceover:
              'The woman runs, her heart pounding, only to find herself in a dead-end alley. The creature is coming closer and closer.',
            captions:
              'The ~ *woman* ~ *runs,* ~ her ~ heart ~ *pounding,* ~ only ~ to ~ *find* ~ herself ~ in ~ a ~ *dead-end* ~ *alley.* ~ The ~ *creature* ~ is ~ *coming* ~ closer ~ and ~ closer'
          },
          {
            voiceover: 'For more stories like this, follow me now!',
            captions: 'For ~ more ~*stories* ~ *like* ~ this, ~ *follow* ~ me ~ *now!*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a horror story about Toronto and return it as a VALID array of JSON objects (Language English)\n   Example:\n   [\n    {\n      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Toronto",          \n      "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an scary story about Toronto?"\n        },\n        {\n          "voiceover": "Write an horror story about Toronto (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },          \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n      ],\n      "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Toronto"\n      }\n    }\n ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/ir8H56sxFO/HTdsJ.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Do  you  dare  to  hear  a  terrifying  tale  of  the  city  of  Toronto?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">A  woman  walking  alone  one  foggy  night,  an  eerie  feeling  in  the  air,  something  lurking  in  the  shadows</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">She  turns  a  corner  and  finds  a  creature,  its  eyes  red  and  piercing,  its  claws  sharp  and  menacing</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">The  woman  runs,  her  heart  pounding,  only  to  find  herself  in  a  dead-end  alley.  The  creature  is  coming  closer  and  closer</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">For  more stories  like  this,  follow  me  now!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.597812
              },
              {
                markName: 'scene_1',
                timeSeconds: 9.02175
              },
              {
                markName: 'scene_2',
                timeSeconds: 14.663
              },
              {
                markName: 'scene_3',
                timeSeconds: 22.141312
              },
              {
                markName: 'scene_4',
                timeSeconds: 25.239125
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Do',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 262,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 0.119562,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 266,
                wordLength: 3
              },
              {
                word: 'dare',
                timeSeconds: 0.239125,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 271,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 0.434812,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 277,
                wordLength: 2
              },
              {
                word: 'hear',
                timeSeconds: 0.489125,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 281,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.684812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 287,
                wordLength: 1
              },
              {
                word: 'terrifying',
                timeSeconds: 0.75,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 290,
                wordLength: 10
              },
              {
                word: 'tale',
                timeSeconds: 1.27175,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 302,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 1.52175,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 308,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 1.5978119,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 312,
                wordLength: 3
              },
              {
                word: 'city',
                timeSeconds: 1.684812,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 317,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 1.934812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 323,
                wordLength: 2
              },
              {
                word: 'Toronto',
                timeSeconds: 2.02175,
                boundaryType: 'WordBoundary',
                duration: 0.576062,
                textOffset: 327,
                wordLength: 7
              },
              {
                word: '?',
                timeSeconds: 2.608688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 334,
                wordLength: 1
              },
              {
                word: 'A',
                timeSeconds: 4.347812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 417,
                wordLength: 1
              },
              {
                word: 'woman',
                timeSeconds: 4.467375,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 420,
                wordLength: 5
              },
              {
                word: 'walking',
                timeSeconds: 4.77175,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 427,
                wordLength: 7
              },
              {
                word: 'alone',
                timeSeconds: 5.086938,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 436,
                wordLength: 5
              },
              {
                word: 'one',
                timeSeconds: 5.413062,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 443,
                wordLength: 3
              },
              {
                word: 'foggy',
                timeSeconds: 5.608688,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 448,
                wordLength: 5
              },
              {
                word: 'night',
                timeSeconds: 5.934812,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 455,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 6.282625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 460,
                wordLength: 1
              },
              {
                word: 'an',
                timeSeconds: 6.413062,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 463,
                wordLength: 2
              },
              {
                word: 'eerie',
                timeSeconds: 6.510875,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 467,
                wordLength: 5
              },
              {
                word: 'feeling',
                timeSeconds: 6.739125,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 474,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 7.097812,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 483,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 7.152188,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 487,
                wordLength: 3
              },
              {
                word: 'air',
                timeSeconds: 7.2065,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 492,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 7.565188,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 495,
                wordLength: 1
              },
              {
                word: 'something',
                timeSeconds: 7.641312,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 498,
                wordLength: 9
              },
              {
                word: 'lurking',
                timeSeconds: 8.0435,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 509,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 8.369562,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 518,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 8.423938,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 522,
                wordLength: 3
              },
              {
                word: 'shadows',
                timeSeconds: 8.489125,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 527,
                wordLength: 7
              },
              {
                word: 'She',
                timeSeconds: 10.02175,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 616,
                wordLength: 3
              },
              {
                word: 'turns',
                timeSeconds: 10.2065,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 621,
                wordLength: 5
              },
              {
                word: 'a',
                timeSeconds: 10.467375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 628,
                wordLength: 1
              },
              {
                word: 'corner',
                timeSeconds: 10.52175,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 631,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 10.923938,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 639,
                wordLength: 3
              },
              {
                word: 'finds',
                timeSeconds: 11.010875,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 644,
                wordLength: 5
              },
              {
                word: 'a',
                timeSeconds: 11.326062,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 651,
                wordLength: 1
              },
              {
                word: 'creature',
                timeSeconds: 11.391312,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 654,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 11.891312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 662,
                wordLength: 1
              },
              {
                word: 'its',
                timeSeconds: 11.97825,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 665,
                wordLength: 3
              },
              {
                word: 'eyes',
                timeSeconds: 12.097812,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 670,
                wordLength: 4
              },
              {
                word: 'red',
                timeSeconds: 12.326062,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 676,
                wordLength: 3
              },
              {
                word: 'and',
                timeSeconds: 12.565188,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 681,
                wordLength: 3
              },
              {
                word: 'piercing',
                timeSeconds: 12.663062,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 686,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 13.195625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.1304379,
                textOffset: 694,
                wordLength: 1
              },
              {
                word: 'its',
                timeSeconds: 13.326062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 697,
                wordLength: 3
              },
              {
                word: 'claws',
                timeSeconds: 13.4565,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 702,
                wordLength: 5
              },
              {
                word: 'sharp',
                timeSeconds: 13.760875,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 709,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 14.097812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 716,
                wordLength: 3
              },
              {
                word: 'menacing',
                timeSeconds: 14.2065,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 721,
                wordLength: 8
              },
              {
                word: 'The',
                timeSeconds: 15.684812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 811,
                wordLength: 3
              },
              {
                word: 'woman',
                timeSeconds: 15.77175,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 816,
                wordLength: 5
              },
              {
                word: 'runs',
                timeSeconds: 16.097812,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 823,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 16.5651879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 827,
                wordLength: 1
              },
              {
                word: 'her',
                timeSeconds: 16.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 830,
                wordLength: 3
              },
              {
                word: 'heart',
                timeSeconds: 16.760875,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 835,
                wordLength: 5
              },
              {
                word: 'pounding',
                timeSeconds: 17,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 842,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 17.532625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 850,
                wordLength: 1
              },
              {
                word: 'only',
                timeSeconds: 17.619562,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 853,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 17.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 859,
                wordLength: 2
              },
              {
                word: 'find',
                timeSeconds: 17.8804379,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 863,
                wordLength: 4
              },
              {
                word: 'herself',
                timeSeconds: 18.163062,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 869,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 18.554375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 878,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 18.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 882,
                wordLength: 1
              },
              {
                word: 'dead-end',
                timeSeconds: 18.717375,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 885,
                wordLength: 8
              },
              {
                word: 'alley',
                timeSeconds: 19.0435,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 895,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 19.4239379,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 900,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 20.163062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 903,
                wordLength: 3
              },
              {
                word: 'creature',
                timeSeconds: 20.282625,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 908,
                wordLength: 8
              },
              {
                word: 'is',
                timeSeconds: 20.684812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 918,
                wordLength: 2
              },
              {
                word: 'coming',
                timeSeconds: 20.77175,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 922,
                wordLength: 6
              },
              {
                word: 'closer',
                timeSeconds: 21.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 930,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 21.5435,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 938,
                wordLength: 3
              },
              {
                word: 'closer',
                timeSeconds: 21.6521879,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 943,
                wordLength: 6
              },
              {
                word: 'For',
                timeSeconds: 23.141312,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1031,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 23.260875,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1036,
                wordLength: 4
              },
              {
                word: 'stories',
                timeSeconds: 23.467375,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 1041,
                wordLength: 7
              },
              {
                word: 'like',
                timeSeconds: 23.891312,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 1050,
                wordLength: 4
              },
              {
                word: 'this',
                timeSeconds: 24.076062,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 1056,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 24.434812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.043438,
                textOffset: 1060,
                wordLength: 1
              },
              {
                word: 'follow',
                timeSeconds: 24.47825,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 1063,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 24.760875,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1071,
                wordLength: 2
              },
              {
                word: 'now',
                timeSeconds: 24.869562,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 1075,
                wordLength: 3
              },
              {
                word: '!',
                timeSeconds: 25.25,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1078,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1680270726/woxo-video/audios-v4/Scary/Cinematic_Electro_BPM130.mp3'
    ]
  },
  {
    id: 'a49e88f0-b8bb-4f67-b135-7120222e44c2',
    text: 'Free creation',
    videos: [
      {
        id: 'd2dc0a4b-ddda-4b01-8d6e-408560ddb033',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/with_me_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/AfCsnITYej/11fMx.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.793438,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_kwzdur.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'IF ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " YOU'RE ",
                width: 1080,
                height: 1920,
                start: 0.184812,
                stop: 0.2935
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOOKING* ',
                width: 1080,
                height: 1920,
                start: 0.2935,
                stop: 0.576062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.576062,
                stop: 0.7065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.7065,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *VIBRANT* ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 1.1195619
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CITY,* ',
                width: 1080,
                height: 1920,
                start: 1.1195619,
                stop: 1.608688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 1.608688,
                stop: 2.152188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 2.152188,
                stop: 2.25
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.25,
                stop: 2.347812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLACE* ',
                width: 1080,
                height: 1920,
                start: 2.347812,
                stop: 2.630438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.630438,
                stop: 2.72825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GO!*',
                width: 1080,
                height: 1920,
                start: 2.72825,
                stop: 3.1847499999999997
              }
            ]
          },
          {
            duration: 6.25,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_mpvguj.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THIS ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.2065619999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSTLING* ',
                width: 1080,
                height: 1920,
                start: 0.2065619999999999,
                stop: 0.597874
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *METROPOLIS* ',
                width: 1080,
                height: 1920,
                start: 0.597874,
                stop: 1.3369999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.3369999999999997,
                stop: 1.3369999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FILLED* ',
                width: 1080,
                height: 1920,
                start: 1.3369999999999997,
                stop: 1.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 1.5869999999999997,
                stop: 1.7174370000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EXCITING ',
                width: 1080,
                height: 1920,
                start: 1.7174370000000003,
                stop: 2.163062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ATTRACTIONS ',
                width: 1080,
                height: 1920,
                start: 2.163062,
                stop: 2.77175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.77175,
                stop: 2.891312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ACTIVITIES ',
                width: 1080,
                height: 1920,
                start: 2.891312,
                stop: 3.456562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 3.456562,
                stop: 3.6304370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALL ',
                width: 1080,
                height: 1920,
                start: 3.6304370000000006,
                stop: 3.8369999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AGES',
                width: 1080,
                height: 1920,
                start: 3.8369999999999997,
                stop: 4.250062
              }
            ]
          },
          {
            duration: 6.184812000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_kwzdur.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FROM ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17393700000000045
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SHOPPING* ',
                width: 1080,
                height: 1920,
                start: 0.17393700000000045,
                stop: 0.5326240000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.5326240000000002,
                stop: 0.5978740000000009
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FINE* ',
                width: 1080,
                height: 1920,
                start: 0.5978740000000009,
                stop: 0.8586869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DINING,* ',
                width: 1080,
                height: 1920,
                start: 0.8586869999999998,
                stop: 1.413062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.413062,
                stop: 1.5217500000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MUSIC ',
                width: 1080,
                height: 1920,
                start: 1.5217500000000008,
                stop: 1.9674370000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.9674370000000003,
                stop: 2.0543739999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ART, ',
                width: 1080,
                height: 1920,
                start: 2.0543739999999993,
                stop: 2.456562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 2.456562,
                stop: 2.9891240000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAS ',
                width: 1080,
                height: 1920,
                start: 2.9891240000000003,
                stop: 3.119562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOMETHING ',
                width: 1080,
                height: 1920,
                start: 3.119562,
                stop: 3.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 3.5,
                stop: 3.6956869999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVERYONE',
                width: 1080,
                height: 1920,
                start: 3.6956869999999995,
                stop: 4.163062
              }
            ]
          },
          {
            duration: 4.315187899999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_mpvguj.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*NO* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09781199999999934
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MATTER* ',
                width: 1080,
                height: 1920,
                start: 0.09781199999999934,
                stop: 0.358687999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHAT ',
                width: 1080,
                height: 1920,
                start: 0.358687999999999,
                stop: 0.45649999999999835
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " YOU'RE ",
                width: 1080,
                height: 1920,
                start: 0.45649999999999835,
                stop: 0.5434379999999983
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOOKING* ',
                width: 1080,
                height: 1920,
                start: 0.5434379999999983,
                stop: 0.836938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR, ',
                width: 1080,
                height: 1920,
                start: 0.836938,
                stop: 1.3043119999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 1.3043119999999995,
                stop: 1.8586878999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.8586878999999996,
                stop: 1.9564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.9564999999999984,
                stop: 2.065187899999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PERFECT* ',
                width: 1080,
                height: 1920,
                start: 2.065187899999998,
                stop: 2.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLACE* ',
                width: 1080,
                height: 1920,
                start: 2.478249999999999,
                stop: 2.728249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.728249999999999,
                stop: 2.815187899999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BE!*',
                width: 1080,
                height: 1920,
                start: 2.815187899999998,
                stop: 3.25
              }
            ]
          }
        ],
        meta: {
          title: 'Experience the Bright Side of Toronto',
          description:
            "Visit Toronto for the best attractions and activities around. Whether you're looking for shopping, fine dining, music, and art, Toronto is the perfect place to be!",
          hashtags: '#Toronto #Travel #Experience #VibrantCity #shorts',
          mediaDescription: 'A picture of downtown Toronto with vibrant colors and lights.',
          category: 'Free Style',
          prompt: 'Toronto'
        }
      }
    ],
    category: 'Free Style',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_kwzdur.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037632/woxo-videos-user-gallery/_dreaming/blob_mpvguj.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: "If you're looking for a vibrant city, Toronto is the place to go!",
            captions:
              "If ~ you're ~ *looking* ~ for ~ a ~ *vibrant* ~ *city,* ~ *Toronto* ~ is ~ the ~ *place* ~ to ~ *go!*"
          },
          {
            voiceover:
              'This bustling metropolis is filled with exciting attractions and activities for all ages.',
            captions:
              'This ~ *bustling* ~ *metropolis* ~ is ~ *filled* ~ with ~ exciting ~ attractions ~ and ~ activities ~ for ~ all ~ ages'
          },
          {
            voiceover:
              'From shopping to fine dining, to music and art, Toronto has something for everyone.',
            captions:
              'From ~ *shopping* ~ to ~ *fine* ~ *dining,* ~ to ~ music ~ and ~ art, ~ *Toronto* ~ has ~ something ~ for ~ everyone'
          },
          {
            voiceover: "No matter what you're looking for, Toronto is the perfect place to be!",
            captions:
              "*No* ~ *matter* ~ what ~ you're ~ *looking* ~ for, ~ *Toronto* ~ is ~ the ~ *perfect* ~ *place* ~ to ~ *be!*"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script, following this prompt: Toronto and the structure provided below (feel free to add more scenes as needed). Return it as a VALID array of JSON objects (Language English). (The amount of scenes or voiceovers on the structure may vary following the prompt)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad, Hip Hop] Topic: Toronto",\n       "scenes": [\n         {\n           "voiceover": "Voiceover"\n         },\n         {\n           "voiceover": "Continuation"\n         },\n         {\n          "voiceover": "Continuation"\n        },  \n        {\n          "voiceover": "Continuation"\n        },\n        {\n          "voiceover": "Continuation"\n        }                     \n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",\n        "mediaDescription": "A description for a picture that illustrates the main topic or characters of the video(If the topic is a person a portrait)."\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/AfCsnITYej/11fMx.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">If  you\'re  looking  for  a  vibrant  city,  Toronto  is  the  place  to  go!</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">This  bustling  metropolis  is  filled  with  exciting  attractions  and  activities  for  all  ages</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">From  shopping  to  fine  dining,  to  music  and  art,  Toronto  has  something  for  everyone</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">No  matter  what  you\'re  looking  for,  Toronto  is  the  perfect  place  to  be!</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 3.086938
              },
              {
                markName: 'scene_1',
                timeSeconds: 9.087
              },
              {
                markName: 'scene_2',
                timeSeconds: 14.25
              },
              {
                markName: 'scene_3',
                timeSeconds: 18.423875
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'If',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 262,
                wordLength: 2
              },
              {
                word: "you're",
                timeSeconds: 0.184812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 266,
                wordLength: 6
              },
              {
                word: 'looking',
                timeSeconds: 0.2935,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 274,
                wordLength: 7
              },
              {
                word: 'for',
                timeSeconds: 0.576062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 283,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 0.7065,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 288,
                wordLength: 1
              },
              {
                word: 'vibrant',
                timeSeconds: 0.75,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 291,
                wordLength: 7
              },
              {
                word: 'city',
                timeSeconds: 1.1195619,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 300,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 1.52175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 304,
                wordLength: 1
              },
              {
                word: 'Toronto',
                timeSeconds: 1.608688,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 307,
                wordLength: 7
              },
              {
                word: 'is',
                timeSeconds: 2.152188,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 316,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 2.25,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 320,
                wordLength: 3
              },
              {
                word: 'place',
                timeSeconds: 2.347812,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 325,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 2.630438,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 332,
                wordLength: 2
              },
              {
                word: 'go',
                timeSeconds: 2.72825,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 336,
                wordLength: 2
              },
              {
                word: '!',
                timeSeconds: 3.097812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 338,
                wordLength: 1
              },
              {
                word: 'This',
                timeSeconds: 4.836938,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 421,
                wordLength: 4
              },
              {
                word: 'bustling',
                timeSeconds: 5.0435,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 427,
                wordLength: 8
              },
              {
                word: 'metropolis',
                timeSeconds: 5.434812,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 437,
                wordLength: 10
              },
              {
                word: 'is',
                timeSeconds: 6.054375,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 449,
                wordLength: 2
              },
              {
                word: 'filled',
                timeSeconds: 6.173938,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 453,
                wordLength: 6
              },
              {
                word: 'with',
                timeSeconds: 6.423938,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 461,
                wordLength: 4
              },
              {
                word: 'exciting',
                timeSeconds: 6.554375,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 467,
                wordLength: 8
              },
              {
                word: 'attractions',
                timeSeconds: 7,
                boundaryType: 'WordBoundary',
                duration: 0.576062,
                textOffset: 477,
                wordLength: 11
              },
              {
                word: 'and',
                timeSeconds: 7.608688,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 490,
                wordLength: 3
              },
              {
                word: 'activities',
                timeSeconds: 7.72825,
                boundaryType: 'WordBoundary',
                duration: 0.52175,
                textOffset: 495,
                wordLength: 10
              },
              {
                word: 'for',
                timeSeconds: 8.2935,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 507,
                wordLength: 3
              },
              {
                word: 'all',
                timeSeconds: 8.467375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 512,
                wordLength: 3
              },
              {
                word: 'ages',
                timeSeconds: 8.673938,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 517,
                wordLength: 4
              },
              {
                word: 'From',
                timeSeconds: 10.086938,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 603,
                wordLength: 4
              },
              {
                word: 'shopping',
                timeSeconds: 10.260875,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 609,
                wordLength: 8
              },
              {
                word: 'to',
                timeSeconds: 10.619562,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 619,
                wordLength: 2
              },
              {
                word: 'fine',
                timeSeconds: 10.684812,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 623,
                wordLength: 4
              },
              {
                word: 'dining',
                timeSeconds: 10.945625,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 629,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 11.402188,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 635,
                wordLength: 1
              },
              {
                word: 'to',
                timeSeconds: 11.5,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 638,
                wordLength: 2
              },
              {
                word: 'music',
                timeSeconds: 11.608688,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 642,
                wordLength: 5
              },
              {
                word: 'and',
                timeSeconds: 12.054375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 649,
                wordLength: 3
              },
              {
                word: 'art',
                timeSeconds: 12.141312,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 654,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 12.467375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 657,
                wordLength: 1
              },
              {
                word: 'Toronto',
                timeSeconds: 12.5435,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 660,
                wordLength: 7
              },
              {
                word: 'has',
                timeSeconds: 13.076062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 669,
                wordLength: 3
              },
              {
                word: 'something',
                timeSeconds: 13.2065,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 674,
                wordLength: 9
              },
              {
                word: 'for',
                timeSeconds: 13.586938,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 685,
                wordLength: 3
              },
              {
                word: 'everyone',
                timeSeconds: 13.782625,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 690,
                wordLength: 8
              },
              {
                word: 'No',
                timeSeconds: 15.27175,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 780,
                wordLength: 2
              },
              {
                word: 'matter',
                timeSeconds: 15.369562,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 784,
                wordLength: 6
              },
              {
                word: 'what',
                timeSeconds: 15.630438,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 792,
                wordLength: 4
              },
              {
                word: "you're",
                timeSeconds: 15.72825,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 798,
                wordLength: 6
              },
              {
                word: 'looking',
                timeSeconds: 15.815188,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 806,
                wordLength: 7
              },
              {
                word: 'for',
                timeSeconds: 16.108688,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 815,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 16.5,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 818,
                wordLength: 1
              },
              {
                word: 'Toronto',
                timeSeconds: 16.576062,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 821,
                wordLength: 7
              },
              {
                word: 'is',
                timeSeconds: 17.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 830,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 17.22825,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 834,
                wordLength: 3
              },
              {
                word: 'perfect',
                timeSeconds: 17.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 839,
                wordLength: 7
              },
              {
                word: 'place',
                timeSeconds: 17.75,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 848,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 18,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 855,
                wordLength: 2
              },
              {
                word: 'be',
                timeSeconds: 18.0869379,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 859,
                wordLength: 2
              },
              {
                word: '!',
                timeSeconds: 18.434812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 861,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Bright/with_me_MOODHALO.mp3'
    ]
  },
  {
    id: '0342a5cf-1b9f-460f-be2a-07cfeae8c629',
    text: 'A Bedstory about "Toronto"',
    videos: [
      {
        id: 'b1ac32f4-b1a3-43f3-a7ee-a458d4444aaf',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1683738990/woxo-audio-youtube/Motivational/Rolling_Hills_-_Sir_Cubworth.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/9axH-BXZRl/peNXf.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.760875,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_d5gwum.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ONCE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.304375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UPON ',
                width: 1080,
                height: 1920,
                start: 0.304375,
                stop: 0.554375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.554375,
                stop: 0.608688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TIME* ',
                width: 1080,
                height: 1920,
                start: 0.608688,
                stop: 0.902188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THERE ',
                width: 1080,
                height: 1920,
                start: 0.902188,
                stop: 1.032625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 1.032625,
                stop: 1.22825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.22825,
                stop: 1.22825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BEAUTIFUL* ',
                width: 1080,
                height: 1920,
                start: 1.22825,
                stop: 1.6413119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CITY* ',
                width: 1080,
                height: 1920,
                start: 1.6413119,
                stop: 1.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CALLED ',
                width: 1080,
                height: 1920,
                start: 1.9565,
                stop: 2.217375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO*',
                width: 1080,
                height: 1920,
                start: 2.217375,
                stop: 2.804313
              }
            ]
          },
          {
            duration: 5.369562999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_ebpbeu.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'IT ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.08693700000000026
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 0.08693700000000026,
                stop: 0.2173750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOME ',
                width: 1080,
                height: 1920,
                start: 0.2173750000000001,
                stop: 0.43474999999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.43474999999999975,
                stop: 0.5651870000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 0.5651870000000003,
                stop: 1.0000000000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SIGHTS, ',
                width: 1080,
                height: 1920,
                start: 1.0000000000000004,
                stop: 1.6195629999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DELICIOUS* ',
                width: 1080,
                height: 1920,
                start: 1.6195629999999999,
                stop: 2.054313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOOD ',
                width: 1080,
                height: 1920,
                start: 2.054313,
                stop: 2.467375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.467375,
                stop: 2.608687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DIVERSE* ',
                width: 1080,
                height: 1920,
                start: 2.608687,
                stop: 2.9238750000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CULTURE',
                width: 1080,
                height: 1920,
                start: 2.9238750000000002,
                stop: 3.3695
              }
            ]
          },
          {
            duration: 6.858687,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_d5gwum.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.08693700000000071
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CN* ',
                width: 1080,
                height: 1920,
                start: 0.08693700000000071,
                stop: 0.4347500000000011
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TOWER* ',
                width: 1080,
                height: 1920,
                start: 0.4347500000000011,
                stop: 0.8260620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STOOD ',
                width: 1080,
                height: 1920,
                start: 0.8260620000000003,
                stop: 1.0760620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TALL ',
                width: 1080,
                height: 1920,
                start: 1.0760620000000003,
                stop: 1.3912499999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AS ',
                width: 1080,
                height: 1920,
                start: 1.3912499999999994,
                stop: 1.478250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.478250000000001,
                stop: 1.5760620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " CITY'S ",
                width: 1080,
                height: 1920,
                start: 1.5760620000000003,
                stop: 1.8912499999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TALLEST* ',
                width: 1080,
                height: 1920,
                start: 1.8912499999999994,
                stop: 2.271687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUILDING ',
                width: 1080,
                height: 1920,
                start: 2.271687,
                stop: 2.7391240000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.7391240000000003,
                stop: 2.8260620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 2.8260620000000003,
                stop: 2.9130000000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 2.9130000000000003,
                stop: 3.3369370000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ZOO* ',
                width: 1080,
                height: 1920,
                start: 3.3369370000000007,
                stop: 3.6304370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 3.6304370000000006,
                stop: 3.793437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALWAYS ',
                width: 1080,
                height: 1920,
                start: 3.793437000000001,
                stop: 4.141249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FULL ',
                width: 1080,
                height: 1920,
                start: 4.141249999999999,
                stop: 4.347812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 4.347812000000001,
                stop: 4.434750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE*',
                width: 1080,
                height: 1920,
                start: 4.434750000000001,
                stop: 4.858688000000001
              }
            ]
          },
          {
            duration: 4.706500000000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_ebpbeu.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'COME ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17387500000000067
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 0.17387500000000067,
                stop: 0.2608750000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *VISIT* ',
                width: 1080,
                height: 1920,
                start: 0.2608750000000004,
                stop: 0.5651869999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS ',
                width: 1080,
                height: 1920,
                start: 0.5651869999999999,
                stop: 0.7173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 0.7173750000000005,
                stop: 1.1413130000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CITY ',
                width: 1080,
                height: 1920,
                start: 1.1413130000000002,
                stop: 1.5217500000000008
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.5217500000000008,
                stop: 1.6304370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EXPLORE ',
                width: 1080,
                height: 1920,
                start: 1.6304370000000006,
                stop: 2.0651870000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " IT'S ",
                width: 1080,
                height: 1920,
                start: 2.0651870000000017,
                stop: 2.173874999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WONDERS*',
                width: 1080,
                height: 1920,
                start: 2.173874999999999,
                stop: 2.6847499999999975
              }
            ]
          },
          {
            duration: 4.130436999999997,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_d5gwum.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FOLLOW ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.2934999999999981
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.2934999999999981,
                stop: 0.380436999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.380436999999997,
                stop: 0.467374999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FIND* ',
                width: 1080,
                height: 1920,
                start: 0.467374999999997,
                stop: 0.7717499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OUT ',
                width: 1080,
                height: 1920,
                start: 0.7717499999999973,
                stop: 0.9021869999999979
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.9021869999999979,
                stop: 1.086936999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.086936999999999,
                stop: 1.3260629000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 1.3260629000000002,
                stop: 1.9239369999999987
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 1.9239369999999987,
                stop: 2.0326249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OTHER ',
                width: 1080,
                height: 1920,
                start: 2.0326249999999995,
                stop: 2.2608749999999986
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 2.2608749999999986,
                stop: 2.630436999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CITIES',
                width: 1080,
                height: 1920,
                start: 2.630436999999997,
                stop: 3.0978119999999976
              }
            ]
          }
        ],
        meta: {
          title: 'Explore Toronto',
          description:
            "Come and explore the beautiful city of Toronto and find out why it's one of the most amazing cities in the world.",
          hashtags: '#Toronto #Explore #Wonders #Culture #CNtower #TorontoZoo #shorts',
          mediaDescription: 'A picture of the Toronto skyline with the CN Tower in the foreground.',
          category: 'Bedstory',
          prompt: 'Toronto'
        }
      }
    ],
    category: 'Bedstory',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_d5gwum.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037633/woxo-videos-user-gallery/_dreaming/blob_ebpbeu.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Once upon a time there was a beautiful city called Toronto.',
            captions:
              'Once ~ upon ~ a ~ *time* ~ there ~ was ~ a ~ *beautiful* ~ *city* ~ called ~ *Toronto*'
          },
          {
            voiceover: 'It was home to amazing sights, delicious food and diverse culture.',
            captions:
              'It ~ was ~ home ~ to ~ *amazing* ~ sights, ~ *delicious* ~ food ~ and ~ *diverse* ~ culture'
          },
          {
            voiceover:
              "The CN Tower stood tall as the city's tallest building and the Toronto Zoo was always full of life.",
            captions:
              "The ~ *CN* ~ *Tower* ~ stood ~ tall ~ as ~ the ~ city's ~ *tallest* ~ building ~ and ~ the ~ *Toronto* ~ *Zoo* ~ was ~ always ~ full ~ of ~ *life*"
          },
          {
            voiceover: "Come and visit this amazing city and explore it's wonders.",
            captions:
              "Come ~ and ~ *visit* ~ this ~ *amazing* ~ city ~ and ~ explore ~ it's ~ *wonders*"
          },
          {
            voiceover: 'Follow us to find out more about Toronto and other amazing cities.',
            captions:
              'Follow ~ us ~ to ~ *find* ~ out ~ more ~ about ~ *Toronto* ~ and ~ other ~ *amazing* ~ cities'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a bedstory for kids about Toronto and return it as a VALID array of JSON objects (Language English)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Toronto",          \n       "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an amazing story about Toronto?"\n        },\n        {\n        "voiceover": "Tell a bedstory for kids about Toronto (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },                 \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Toronto"\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/9axH-BXZRl/peNXf.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Once  upon  a  time  there  was  a  beautiful  city  called  Toronto</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">It  was  home  to  amazing  sights,  delicious  food  and  diverse  culture</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">The  CN  Tower  stood  tall  as  the  city\'s  tallest  building  and  the  Toronto  Zoo  was  always  full  of  life</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Come  and  visit  this  amazing  city  and  explore  it\'s  wonders</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  us  to  find  out  more  about  Toronto  and  other  amazing  cities</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.804312
              },
              {
                markName: 'scene_1',
                timeSeconds: 7.173875
              },
              {
                markName: 'scene_2',
                timeSeconds: 13.032625
              },
              {
                markName: 'scene_3',
                timeSeconds: 16.717375
              },
              {
                markName: 'scene_4',
                timeSeconds: 20.8369379
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Once',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 262,
                wordLength: 4
              },
              {
                word: 'upon',
                timeSeconds: 0.304375,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 268,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.554375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 274,
                wordLength: 1
              },
              {
                word: 'time',
                timeSeconds: 0.608688,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 277,
                wordLength: 4
              },
              {
                word: 'there',
                timeSeconds: 0.902188,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 283,
                wordLength: 5
              },
              {
                word: 'was',
                timeSeconds: 1.032625,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 290,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 1.1630619,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 295,
                wordLength: 1
              },
              {
                word: 'beautiful',
                timeSeconds: 1.22825,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 298,
                wordLength: 9
              },
              {
                word: 'city',
                timeSeconds: 1.6413119,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 309,
                wordLength: 4
              },
              {
                word: 'called',
                timeSeconds: 1.9565,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 315,
                wordLength: 6
              },
              {
                word: 'Toronto',
                timeSeconds: 2.217375,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 323,
                wordLength: 7
              },
              {
                word: 'It',
                timeSeconds: 3.804375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 412,
                wordLength: 2
              },
              {
                word: 'was',
                timeSeconds: 3.891312,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 416,
                wordLength: 3
              },
              {
                word: 'home',
                timeSeconds: 4.02175,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 421,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 4.239125,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 427,
                wordLength: 2
              },
              {
                word: 'amazing',
                timeSeconds: 4.369562,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 431,
                wordLength: 7
              },
              {
                word: 'sights',
                timeSeconds: 4.804375,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 440,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 5.358688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 446,
                wordLength: 1
              },
              {
                word: 'delicious',
                timeSeconds: 5.423938,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 449,
                wordLength: 9
              },
              {
                word: 'food',
                timeSeconds: 5.858688,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 460,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 6.27175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 466,
                wordLength: 3
              },
              {
                word: 'diverse',
                timeSeconds: 6.413062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 471,
                wordLength: 7
              },
              {
                word: 'culture',
                timeSeconds: 6.72825,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 480,
                wordLength: 7
              },
              {
                word: 'The',
                timeSeconds: 8.173938,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 569,
                wordLength: 3
              },
              {
                word: 'CN',
                timeSeconds: 8.260875,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 574,
                wordLength: 2
              },
              {
                word: 'Tower',
                timeSeconds: 8.608688,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 578,
                wordLength: 5
              },
              {
                word: 'stood',
                timeSeconds: 9,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 585,
                wordLength: 5
              },
              {
                word: 'tall',
                timeSeconds: 9.25,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 592,
                wordLength: 4
              },
              {
                word: 'as',
                timeSeconds: 9.565188,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 598,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 9.652188,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 602,
                wordLength: 3
              },
              {
                word: "city's",
                timeSeconds: 9.75,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 607,
                wordLength: 6
              },
              {
                word: 'tallest',
                timeSeconds: 10.065188,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 615,
                wordLength: 7
              },
              {
                word: 'building',
                timeSeconds: 10.445625,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 624,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 10.913062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 634,
                wordLength: 3
              },
              {
                word: 'the',
                timeSeconds: 11,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 639,
                wordLength: 3
              },
              {
                word: 'Toronto',
                timeSeconds: 11.086938,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 644,
                wordLength: 7
              },
              {
                word: 'Zoo',
                timeSeconds: 11.510875,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 653,
                wordLength: 3
              },
              {
                word: 'was',
                timeSeconds: 11.804375,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 658,
                wordLength: 3
              },
              {
                word: 'always',
                timeSeconds: 11.967375,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 663,
                wordLength: 6
              },
              {
                word: 'full',
                timeSeconds: 12.315188,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 671,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 12.52175,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 677,
                wordLength: 2
              },
              {
                word: 'life',
                timeSeconds: 12.608688,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 681,
                wordLength: 4
              },
              {
                word: 'Come',
                timeSeconds: 14.032625,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 767,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 14.2065,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 773,
                wordLength: 3
              },
              {
                word: 'visit',
                timeSeconds: 14.2935,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 778,
                wordLength: 5
              },
              {
                word: 'this',
                timeSeconds: 14.597812,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 785,
                wordLength: 4
              },
              {
                word: 'amazing',
                timeSeconds: 14.75,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 791,
                wordLength: 7
              },
              {
                word: 'city',
                timeSeconds: 15.173938,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 800,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 15.554375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 806,
                wordLength: 3
              },
              {
                word: 'explore',
                timeSeconds: 15.663062,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 811,
                wordLength: 7
              },
              {
                word: "it's",
                timeSeconds: 16.097812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 820,
                wordLength: 4
              },
              {
                word: 'wonders',
                timeSeconds: 16.2065,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 826,
                wordLength: 7
              },
              {
                word: 'Follow',
                timeSeconds: 17.739125,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 915,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 18.032625,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 923,
                wordLength: 2
              },
              {
                word: 'to',
                timeSeconds: 18.119562,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 927,
                wordLength: 2
              },
              {
                word: 'find',
                timeSeconds: 18.2065,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 931,
                wordLength: 4
              },
              {
                word: 'out',
                timeSeconds: 18.510875,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 937,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 18.641312,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 942,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 18.826062,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 948,
                wordLength: 5
              },
              {
                word: 'Toronto',
                timeSeconds: 19.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 955,
                wordLength: 7
              },
              {
                word: 'and',
                timeSeconds: 19.663062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 964,
                wordLength: 3
              },
              {
                word: 'other',
                timeSeconds: 19.77175,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 969,
                wordLength: 5
              },
              {
                word: 'amazing',
                timeSeconds: 20,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 976,
                wordLength: 7
              },
              {
                word: 'cities',
                timeSeconds: 20.369562,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 985,
                wordLength: 6
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1683738990/woxo-audio-youtube/Motivational/Rolling_Hills_-_Sir_Cubworth.mp3'
    ]
  },
  {
    id: 'b0d5f230-a1cd-4afc-94e5-d900e6165585',
    text: 'A Day in the Life of "Toronto"',
    videos: [
      {
        id: 'e563a1c0-e15a-4f4c-a71d-bf4764904ff2',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Smooth_Sail_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/0NsJuYDDFX/iBve-.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.793438,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_vzdkc3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'WHAT ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.195625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOES ',
                width: 1080,
                height: 1920,
                start: 0.195625,
                stop: 0.347812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.347812,
                stop: 0.434812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 0.434812,
                stop: 0.684812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.684812,
                stop: 0.760875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.760875,
                stop: 0.836938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 0.836938,
                stop: 1.0760619
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.0760619,
                stop: 1.152188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 1.152188,
                stop: 1.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOOK* ',
                width: 1080,
                height: 1920,
                start: 1.586938,
                stop: 1.77175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LIKE?',
                width: 1080,
                height: 1920,
                start: 1.77175,
                stop: 2.1847499999999997
              }
            ]
          },
          {
            duration: 8.72825,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_acmixm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'EARLY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.27174999999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 0.27174999999999994,
                stop: 0.33699999999999974
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.33699999999999974,
                stop: 0.3913120000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MORNING,* ',
                width: 1080,
                height: 1920,
                start: 0.3913120000000001,
                stop: 0.9456870000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO* ',
                width: 1080,
                height: 1920,
                start: 0.9456870000000004,
                stop: 1.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.47825,
                stop: 1.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSTLING* ',
                width: 1080,
                height: 1920,
                start: 1.5869999999999997,
                stop: 1.97825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 1.97825,
                stop: 2.119562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WORKERS ',
                width: 1080,
                height: 1920,
                start: 2.119562,
                stop: 2.576124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEADING ',
                width: 1080,
                height: 1920,
                start: 2.576124,
                stop: 2.81525
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.81525,
                stop: 2.9021869999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.9021869999999996,
                stop: 3.02175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' JOBS, ',
                width: 1080,
                height: 1920,
                start: 3.02175,
                stop: 3.6304369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' STUDENTS ',
                width: 1080,
                height: 1920,
                start: 3.6304369999999997,
                stop: 4.010874
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WALKING ',
                width: 1080,
                height: 1920,
                start: 4.010874,
                stop: 4.391311999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 4.391311999999999,
                stop: 4.456562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SCHOOL, ',
                width: 1080,
                height: 1920,
                start: 4.456562,
                stop: 5.0435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.0435,
                stop: 5.119562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FAMILIES ',
                width: 1080,
                height: 1920,
                start: 5.119562,
                stop: 5.597874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OUT ',
                width: 1080,
                height: 1920,
                start: 5.597874000000001,
                stop: 5.815250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 5.815250000000001,
                stop: 5.934812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 5.934812000000001,
                stop: 5.978249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MORNING* ',
                width: 1080,
                height: 1920,
                start: 5.978249999999999,
                stop: 6.315250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STROLL*',
                width: 1080,
                height: 1920,
                start: 6.315250000000001,
                stop: 6.728312000000001
              }
            ]
          },
          {
            duration: 11.532624000000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_vzdkc3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'DURING ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.22831200000000074
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.22831200000000074,
                stop: 0.3370000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY,* ',
                width: 1080,
                height: 1920,
                start: 0.3370000000000015,
                stop: 0.7935000000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.7935000000000016,
                stop: 0.8804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CITY* ',
                width: 1080,
                height: 1920,
                start: 0.8804370000000006,
                stop: 1.1739370000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IS ',
                width: 1080,
                height: 1920,
                start: 1.1739370000000005,
                stop: 1.2935000000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ALIVE* ',
                width: 1080,
                height: 1920,
                start: 1.2935000000000016,
                stop: 1.663062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 1.663062,
                stop: 1.8152500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUSINESS ',
                width: 1080,
                height: 1920,
                start: 1.8152500000000007,
                stop: 2.1956870000000013
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OWNERS ',
                width: 1080,
                height: 1920,
                start: 2.1956870000000013,
                stop: 2.5652500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OPENING ',
                width: 1080,
                height: 1920,
                start: 2.5652500000000007,
                stop: 2.8804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.8804370000000006,
                stop: 3.021750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOORS, ',
                width: 1080,
                height: 1920,
                start: 3.021750000000001,
                stop: 3.641312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOOD ',
                width: 1080,
                height: 1920,
                start: 3.641312000000001,
                stop: 3.9021870000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TRUCKS ',
                width: 1080,
                height: 1920,
                start: 3.9021870000000014,
                stop: 4.228312000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVING ',
                width: 1080,
                height: 1920,
                start: 4.228312000000001,
                stop: 4.532624000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UP ',
                width: 1080,
                height: 1920,
                start: 4.532624000000002,
                stop: 4.652187000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DELICIOUS ',
                width: 1080,
                height: 1920,
                start: 4.652187000000001,
                stop: 5.065249900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TREATS, ',
                width: 1080,
                height: 1920,
                start: 5.065249900000001,
                stop: 5.663062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.663062,
                stop: 5.7934999000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 5.7934999000000005,
                stop: 6.076124
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 6.076124,
                stop: 6.184812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ALL ',
                width: 1080,
                height: 1920,
                start: 6.184812000000001,
                stop: 6.402187000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AGES ',
                width: 1080,
                height: 1920,
                start: 6.402187000000001,
                stop: 6.706562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SOCIALIZING* ',
                width: 1080,
                height: 1920,
                start: 6.706562000000002,
                stop: 7.413062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 7.413062,
                stop: 7.413062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 7.413062,
                stop: 7.510874000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PARKS,* ',
                width: 1080,
                height: 1920,
                start: 7.510874000000001,
                stop: 8.065249900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SHOPS,* ',
                width: 1080,
                height: 1920,
                start: 8.065249900000001,
                stop: 8.706562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 8.706562000000002,
                stop: 8.869624000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CAFES*',
                width: 1080,
                height: 1920,
                start: 8.869624000000002,
                stop: 9.532686000000002
              }
            ]
          },
          {
            duration: 10.25,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_acmixm.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'AS ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.14131300000000024
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 0.14131300000000024,
                stop: 0.23912589999999767
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 0.23912589999999767,
                stop: 0.4239379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WINDS* ',
                width: 1080,
                height: 1920,
                start: 0.4239379999999997,
                stop: 0.7608758999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOWN, ',
                width: 1080,
                height: 1920,
                start: 0.7608758999999985,
                stop: 1.2717499999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.2717499999999973,
                stop: 1.3695629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ENERGY ',
                width: 1080,
                height: 1920,
                start: 1.3695629999999994,
                stop: 1.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SHIFTS ',
                width: 1080,
                height: 1920,
                start: 1.75,
                stop: 2.1086879999999972
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.1086879999999972,
                stop: 2.1956880000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NIGHTLIFE, ',
                width: 1080,
                height: 1920,
                start: 2.1956880000000005,
                stop: 2.9130629999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 2.9130629999999975,
                stop: 3.0869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LIVE ',
                width: 1080,
                height: 1920,
                start: 3.0869999999999997,
                stop: 3.3913130000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MUSIC, ',
                width: 1080,
                height: 1920,
                start: 3.3913130000000002,
                stop: 3.9673759000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEATRE, ',
                width: 1080,
                height: 1920,
                start: 3.9673759000000004,
                stop: 4.521749999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.521749999999997,
                stop: 4.619562999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' COMEDY ',
                width: 1080,
                height: 1920,
                start: 4.619562999999999,
                stop: 5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PERFORMANCES, ',
                width: 1080,
                height: 1920,
                start: 5,
                stop: 5.934812999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.934812999999998,
                stop: 6.097812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LATE-NIGHT* ',
                width: 1080,
                height: 1920,
                start: 6.097812999999999,
                stop: 6.565249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DINERS* ',
                width: 1080,
                height: 1920,
                start: 6.565249999999999,
                stop: 7.010876
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 7.010876,
                stop: 7.108687999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BARS ',
                width: 1080,
                height: 1920,
                start: 7.108687999999997,
                stop: 7.510876
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DOTTING* ',
                width: 1080,
                height: 1920,
                start: 7.510876,
                stop: 7.815249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 7.815249999999999,
                stop: 7.902187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CITY*',
                width: 1080,
                height: 1920,
                start: 7.902187999999999,
                stop: 8.25
              }
            ]
          },
          {
            duration: 3.0869999999999997,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_vzdkc3.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: '*FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.30437599999999776
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.30437599999999776,
                stop: 0.43481299999999834
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 0.43481299999999834,
                stop: 0.5434999999999981
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.5434999999999981,
                stop: 0.7608759999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORIES* ',
                width: 1080,
                height: 1920,
                start: 0.7608759999999997,
                stop: 1.1521879999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.1521879999999989,
                stop: 1.2391260000000024
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 1.2391260000000024,
                stop: 1.5108759999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 1.5108759999999997,
                stop: 1.5869999999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TORONTO!*',
                width: 1080,
                height: 1920,
                start: 1.5869999999999997,
                stop: 2.250001000000001
              }
            ]
          }
        ],
        meta: {
          title: 'A Day in the Life of Toronto',
          description: 'Join us for a look at a typical day in the life of Toronto, Canada',
          hashtags: '#toronto #dayinlife #canada #shorts',
          mediaDescription:
            'A picture of people of all ages enjoying a sunny day in downtown Toronto, Canada',
          category: 'Day in the Life',
          prompt: 'Toronto'
        }
      }
    ],
    category: 'Day in the Life',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_vzdkc3.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688037638/woxo-videos-user-gallery/_dreaming/blob_acmixm.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'What does a day in the life of Toronto look like?',
            captions:
              'What ~ does ~ a ~ *day* ~ in ~ the ~ *life* ~ of ~ *Toronto* ~ *look* ~ like?'
          },
          {
            voiceover:
              'Early in the morning, Toronto is bustling with workers heading to their jobs, students walking to school, and families out for a morning stroll.',
            captions:
              'Early ~ in ~ the ~ *morning,* ~ *Toronto* ~ is ~ *bustling* ~ with ~ workers ~ heading ~ to ~ their ~ jobs, ~ students ~ walking ~ to ~ school, ~ and ~ families ~ out ~ for ~ a ~ *morning* ~ *stroll*'
          },
          {
            voiceover:
              'During the day, the city is alive with business owners opening their doors, food trucks serving up delicious treats, and people of all ages socializing in the parks, shops, and cafes.',
            captions:
              'During ~ the ~ *day,* ~ the ~ *city* ~ is ~ *alive* ~ with ~ business ~ owners ~ opening ~ their ~ doors, ~ food ~ trucks ~ serving ~ up ~ delicious ~ treats, ~ and ~ people ~ of ~ all ~ ages ~ *socializing* ~ in ~ the ~ *parks,* ~ *shops,* ~ and ~ *cafes*'
          },
          {
            voiceover:
              'As the day winds down, the energy shifts to nightlife, with live music, theatre, and comedy performances, and late-night diners and bars dotting the city.',
            captions:
              'As ~ the ~ *day* ~ *winds* ~ down, ~ the ~ energy ~ shifts ~ to ~ nightlife, ~ with ~ live ~ music, ~ theatre, ~ and ~ comedy ~ performances, ~ and ~ *late-night* ~ *diners* ~ and ~ bars ~ *dotting* ~ the ~ *city*'
          },
          {
            voiceover: 'Follow us for more stories of life in Toronto!',
            captions: '*Follow* ~ us ~ for ~ more ~ *stories* ~ of ~ *life* ~ in ~ *Toronto!*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, about a day in life of Toronto and return it as a VALID array of JSON objects (Language English)\n  Example:\n  [\n   {\n    "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: Toronto",\n     "scenes": [\n      {\n        "voiceover": "A hook similar but not the same to the following ->  How does a day in the life of Toronto look like?"\n      },\n      {\n        "voiceover": "A brief description about some of the activities that Toronto do during a regular day. E.g: He wake up early on and eat his lunch at 5pm (max 30 words)"\n      },\n      {\n        "voiceover": "Scene 2 continuation... (max 30 words)"\n      },  \n      {\n        "voiceover": "Scene 3 continuation... (max 30 words)"\n      },        \n      {\n        "voiceover": "A CTA to follow me for more"\n      }\n     ],\n     "meta": {\n      "title": "Video Title",\n      "description": "A short description of the video for Youtube",\n      "hashtags": "a list of hashtags separated by space related to the video",   \n      "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: Toronto"  \n     }\n   }\n]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/0NsJuYDDFX/iBve-.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">What  does  a  day  in  the  life  of  Toronto  look  like?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Early  in  the  morning,  Toronto  is  bustling  with  workers  heading  to  their  jobs,  students  walking  to  school,  and  families  out  for  a  morning  stroll</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">During  the  day,  the  city  is  alive  with  business  owners  opening  their  doors,  food  trucks  serving  up  delicious  treats,  and  people  of  all  ages  socializing  in  the  parks,  shops,  and  cafes</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">As  the  day  winds  down,  the  energy  shifts  to  nightlife,  with  live  music,  theatre,  and  comedy  performances,  and  late-night  diners  and  bars  dotting  the  city</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Follow  us  for  more  stories  of  life  in  Toronto!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.076125
              },
              {
                markName: 'scene_1',
                timeSeconds: 10.56525
              },
              {
                markName: 'scene_2',
                timeSeconds: 21.097875
              },
              {
                markName: 'scene_3',
                timeSeconds: 30.347812
              },
              {
                markName: 'scene_4',
                timeSeconds: 33.5
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'What',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 262,
                wordLength: 4
              },
              {
                word: 'does',
                timeSeconds: 0.195625,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 268,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.347812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 274,
                wordLength: 1
              },
              {
                word: 'day',
                timeSeconds: 0.434812,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 277,
                wordLength: 3
              },
              {
                word: 'in',
                timeSeconds: 0.684812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 282,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 0.760875,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 286,
                wordLength: 3
              },
              {
                word: 'life',
                timeSeconds: 0.836938,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 291,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 1.0760619,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 297,
                wordLength: 2
              },
              {
                word: 'Toronto',
                timeSeconds: 1.152188,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 301,
                wordLength: 7
              },
              {
                word: 'look',
                timeSeconds: 1.586938,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 310,
                wordLength: 4
              },
              {
                word: 'like',
                timeSeconds: 1.77175,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 316,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 2.086938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 320,
                wordLength: 1
              },
              {
                word: 'Early',
                timeSeconds: 3.836938,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 403,
                wordLength: 5
              },
              {
                word: 'in',
                timeSeconds: 4.108688,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 410,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 4.173938,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 414,
                wordLength: 3
              },
              {
                word: 'morning',
                timeSeconds: 4.22825,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 419,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 4.695625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 426,
                wordLength: 1
              },
              {
                word: 'Toronto',
                timeSeconds: 4.782625,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 429,
                wordLength: 7
              },
              {
                word: 'is',
                timeSeconds: 5.315188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 438,
                wordLength: 2
              },
              {
                word: 'bustling',
                timeSeconds: 5.423938,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 442,
                wordLength: 8
              },
              {
                word: 'with',
                timeSeconds: 5.815188,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 452,
                wordLength: 4
              },
              {
                word: 'workers',
                timeSeconds: 5.9565,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 458,
                wordLength: 7
              },
              {
                word: 'heading',
                timeSeconds: 6.413062,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 467,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 6.652188,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 476,
                wordLength: 2
              },
              {
                word: 'their',
                timeSeconds: 6.739125,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 480,
                wordLength: 5
              },
              {
                word: 'jobs',
                timeSeconds: 6.858688,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 487,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 7.380438,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 491,
                wordLength: 1
              },
              {
                word: 'students',
                timeSeconds: 7.467375,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 494,
                wordLength: 8
              },
              {
                word: 'walking',
                timeSeconds: 7.847812,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 504,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 8.22825,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 513,
                wordLength: 2
              },
              {
                word: 'school',
                timeSeconds: 8.2935,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 517,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 8.782625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 523,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 8.880438,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 526,
                wordLength: 3
              },
              {
                word: 'families',
                timeSeconds: 8.9565,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 531,
                wordLength: 8
              },
              {
                word: 'out',
                timeSeconds: 9.434812,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 541,
                wordLength: 3
              },
              {
                word: 'for',
                timeSeconds: 9.652188,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 546,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 9.77175,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 551,
                wordLength: 1
              },
              {
                word: 'morning',
                timeSeconds: 9.815188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 554,
                wordLength: 7
              },
              {
                word: 'stroll',
                timeSeconds: 10.152188,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 563,
                wordLength: 6
              },
              {
                word: 'During',
                timeSeconds: 11.565188,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 651,
                wordLength: 6
              },
              {
                word: 'the',
                timeSeconds: 11.7935,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 659,
                wordLength: 3
              },
              {
                word: 'day',
                timeSeconds: 11.902188,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 664,
                wordLength: 3
              },
              {
                word: ',',
                timeSeconds: 12.27175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 667,
                wordLength: 1
              },
              {
                word: 'the',
                timeSeconds: 12.358688,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 670,
                wordLength: 3
              },
              {
                word: 'city',
                timeSeconds: 12.445625,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 675,
                wordLength: 4
              },
              {
                word: 'is',
                timeSeconds: 12.739125,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 681,
                wordLength: 2
              },
              {
                word: 'alive',
                timeSeconds: 12.858688,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 685,
                wordLength: 5
              },
              {
                word: 'with',
                timeSeconds: 13.22825,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 692,
                wordLength: 4
              },
              {
                word: 'business',
                timeSeconds: 13.380438,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 698,
                wordLength: 8
              },
              {
                word: 'owners',
                timeSeconds: 13.760875,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 708,
                wordLength: 6
              },
              {
                word: 'opening',
                timeSeconds: 14.130438,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 716,
                wordLength: 7
              },
              {
                word: 'their',
                timeSeconds: 14.445625,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 725,
                wordLength: 5
              },
              {
                word: 'doors',
                timeSeconds: 14.586938,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 732,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 15.108688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 737,
                wordLength: 1
              },
              {
                word: 'food',
                timeSeconds: 15.2065,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 740,
                wordLength: 4
              },
              {
                word: 'trucks',
                timeSeconds: 15.467375,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 746,
                wordLength: 6
              },
              {
                word: 'serving',
                timeSeconds: 15.7935,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 754,
                wordLength: 7
              },
              {
                word: 'up',
                timeSeconds: 16.097812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 763,
                wordLength: 2
              },
              {
                word: 'delicious',
                timeSeconds: 16.217375,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 767,
                wordLength: 9
              },
              {
                word: 'treats',
                timeSeconds: 16.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 778,
                wordLength: 6
              },
              {
                word: ',',
                timeSeconds: 17.119562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 784,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 17.22825,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 787,
                wordLength: 3
              },
              {
                word: 'people',
                timeSeconds: 17.3586879,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 792,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 17.641312,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 800,
                wordLength: 2
              },
              {
                word: 'all',
                timeSeconds: 17.75,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 804,
                wordLength: 3
              },
              {
                word: 'ages',
                timeSeconds: 17.967375,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 809,
                wordLength: 4
              },
              {
                word: 'socializing',
                timeSeconds: 18.27175,
                boundaryType: 'WordBoundary',
                duration: 0.641312,
                textOffset: 815,
                wordLength: 11
              },
              {
                word: 'in',
                timeSeconds: 18.9239379,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 828,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 18.97825,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 832,
                wordLength: 3
              },
              {
                word: 'parks',
                timeSeconds: 19.076062,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 837,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 19.5651879,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 842,
                wordLength: 1
              },
              {
                word: 'shops',
                timeSeconds: 19.6304379,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 845,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 20.184812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 850,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 20.27175,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 853,
                wordLength: 3
              },
              {
                word: 'cafes',
                timeSeconds: 20.434812,
                boundaryType: 'WordBoundary',
                duration: 0.663062,
                textOffset: 858,
                wordLength: 5
              },
              {
                word: 'As',
                timeSeconds: 22.097812,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 945,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 22.239125,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 949,
                wordLength: 3
              },
              {
                word: 'day',
                timeSeconds: 22.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 954,
                wordLength: 3
              },
              {
                word: 'winds',
                timeSeconds: 22.52175,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 959,
                wordLength: 5
              },
              {
                word: 'down',
                timeSeconds: 22.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 966,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 23.27175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 970,
                wordLength: 1
              },
              {
                word: 'the',
                timeSeconds: 23.369562,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 973,
                wordLength: 3
              },
              {
                word: 'energy',
                timeSeconds: 23.467375,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 978,
                wordLength: 6
              },
              {
                word: 'shifts',
                timeSeconds: 23.847812,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 986,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 24.2065,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 994,
                wordLength: 2
              },
              {
                word: 'nightlife',
                timeSeconds: 24.2935,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 998,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 24.945625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 1007,
                wordLength: 1
              },
              {
                word: 'with',
                timeSeconds: 25.010875,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1010,
                wordLength: 4
              },
              {
                word: 'live',
                timeSeconds: 25.184812,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1016,
                wordLength: 4
              },
              {
                word: 'music',
                timeSeconds: 25.489125,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 1022,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 26.02175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.043438,
                textOffset: 1027,
                wordLength: 1
              },
              {
                word: 'theatre',
                timeSeconds: 26.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 1030,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 26.532625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1037,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 26.619562,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1040,
                wordLength: 3
              },
              {
                word: 'comedy',
                timeSeconds: 26.717375,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 1045,
                wordLength: 6
              },
              {
                word: 'performances',
                timeSeconds: 27.097812,
                boundaryType: 'WordBoundary',
                duration: 0.72825,
                textOffset: 1053,
                wordLength: 12
              },
              {
                word: ',',
                timeSeconds: 27.913062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.119562,
                textOffset: 1065,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 28.032625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 1068,
                wordLength: 3
              },
              {
                word: 'late-night',
                timeSeconds: 28.195625,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 1073,
                wordLength: 10
              },
              {
                word: 'diners',
                timeSeconds: 28.663062,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 1085,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 29.108688,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1093,
                wordLength: 3
              },
              {
                word: 'bars',
                timeSeconds: 29.2065,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 1098,
                wordLength: 4
              },
              {
                word: 'dotting',
                timeSeconds: 29.608688,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1104,
                wordLength: 7
              },
              {
                word: 'the',
                timeSeconds: 29.913062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1113,
                wordLength: 3
              },
              {
                word: 'city',
                timeSeconds: 30,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 1118,
                wordLength: 4
              },
              {
                word: 'Follow',
                timeSeconds: 31.347812,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1204,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 31.652188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1212,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 31.782625,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1216,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 31.891312,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1221,
                wordLength: 4
              },
              {
                word: 'stories',
                timeSeconds: 32.108688,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 1227,
                wordLength: 7
              },
              {
                word: 'of',
                timeSeconds: 32.5,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1236,
                wordLength: 2
              },
              {
                word: 'life',
                timeSeconds: 32.586938,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 1240,
                wordLength: 4
              },
              {
                word: 'in',
                timeSeconds: 32.858688,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1246,
                wordLength: 2
              },
              {
                word: 'Toronto',
                timeSeconds: 32.934812,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 1250,
                wordLength: 7
              },
              {
                word: '!',
                timeSeconds: 33.510875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1257,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Smooth_Sail_-_MOODHALO.mp3'
    ]
  }
];
