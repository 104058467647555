export const data = [
  {
    id: '3afbab94-a1c5-46d9-85f2-9f819d08e92f',
    text: 'Free creation',
    videos: [
      {
        id: 'd99154e9-8ab2-4b7f-b615-eb1e5c9447cb',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Tokyo_Night_Pop__BPM130.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/erVrux8AOM/HMU_C.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 6.260875,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_qnhygo.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ARE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.184812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.184812,
                stop: 0.304375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.304375,
                stop: 0.369562
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SMALL* ',
                width: 1080,
                height: 1920,
                start: 0.369562,
                stop: 0.684812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSINESS* ',
                width: 1080,
                height: 1920,
                start: 0.684812,
                stop: 1.02175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LOOKING ',
                width: 1080,
                height: 1920,
                start: 1.02175,
                stop: 1.304375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.304375,
                stop: 1.3913119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PROMOTE* ',
                width: 1080,
                height: 1920,
                start: 1.3913119,
                stop: 1.652188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 1.652188,
                stop: 1.760875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SERVICES* ',
                width: 1080,
                height: 1920,
                start: 1.760875,
                stop: 2.358688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUT ',
                width: 1080,
                height: 1920,
                start: 2.358688,
                stop: 2.510875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " DON'T ",
                width: 1080,
                height: 1920,
                start: 2.510875,
                stop: 2.684812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KNOW* ',
                width: 1080,
                height: 1920,
                start: 2.684812,
                stop: 2.804375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOW ',
                width: 1080,
                height: 1920,
                start: 2.804375,
                stop: 2.913062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.913062,
                stop: 2.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GET* ',
                width: 1080,
                height: 1920,
                start: 2.989125,
                stop: 3.130438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STARTED?*',
                width: 1080,
                height: 1920,
                start: 3.130438,
                stop: 3.6521869999999996
              }
            ]
          },
          {
            duration: 8.021687,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_shktsl.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'LET ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.15212499999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.15212499999999984,
                stop: 0.26081299999999974
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HELP* ',
                width: 1080,
                height: 1920,
                start: 0.26081299999999974,
                stop: 0.4891249999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU! ',
                width: 1080,
                height: 1920,
                start: 0.4891249999999996,
                stop: 1.5108129999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OUR ',
                width: 1080,
                height: 1920,
                start: 1.5108129999999997,
                stop: 1.6847499999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TEAM ',
                width: 1080,
                height: 1920,
                start: 1.6847499999999993,
                stop: 1.9021249999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.9021249999999998,
                stop: 2
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 2,
                stop: 2.4129999999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EXPERTS* ',
                width: 1080,
                height: 1920,
                start: 2.4129999999999994,
                stop: 2.8695629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WILL ',
                width: 1080,
                height: 1920,
                start: 2.8695629999999994,
                stop: 3.010812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WORK ',
                width: 1080,
                height: 1920,
                start: 3.010812999999999,
                stop: 3.1956249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 3.1956249999999997,
                stop: 3.3586869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 3.3586869999999998,
                stop: 3.478249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.478249999999999,
                stop: 3.5760629999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CREATE ',
                width: 1080,
                height: 1920,
                start: 3.5760629999999995,
                stop: 3.9130000000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 3.9130000000000003,
                stop: 3.9130000000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CUSTOM* ',
                width: 1080,
                height: 1920,
                start: 3.9130000000000003,
                stop: 4.293436999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLAN* ',
                width: 1080,
                height: 1920,
                start: 4.293436999999999,
                stop: 4.586936999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 4.586936999999999,
                stop: 4.739125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FITS ',
                width: 1080,
                height: 1920,
                start: 4.739125,
                stop: 4.978249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 4.978249999999999,
                stop: 5.097813
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUDGET* ',
                width: 1080,
                height: 1920,
                start: 5.097813,
                stop: 5.4565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 5.4565,
                stop: 5.565187
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GOALS*',
                width: 1080,
                height: 1920,
                start: 5.565187,
                stop: 6.021687
              }
            ]
          },
          {
            duration: 7.728313,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_qnhygo.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "WE'LL ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09787599999999941
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HELP* ',
                width: 1080,
                height: 1920,
                start: 0.09787599999999941,
                stop: 0.27174999999999905
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.27174999999999905,
                stop: 0.39131300000000024
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATE* ',
                width: 1080,
                height: 1920,
                start: 0.39131300000000024,
                stop: 0.6739379999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 0.6739379999999997,
                stop: 0.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EFFECTIVE* ',
                width: 1080,
                height: 1920,
                start: 0.75,
                stop: 1.1848130000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ONLINE* ',
                width: 1080,
                height: 1920,
                start: 1.1848130000000001,
                stop: 1.5543759999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PRESENCE, ',
                width: 1080,
                height: 1920,
                start: 1.5543759999999995,
                stop: 2.228313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUILD ',
                width: 1080,
                height: 1920,
                start: 2.228313,
                stop: 2.4456880000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 2.4456880000000005,
                stop: 2.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LASTING* ',
                width: 1080,
                height: 1920,
                start: 2.5,
                stop: 2.902187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RELATIONSHIP* ',
                width: 1080,
                height: 1920,
                start: 2.902187999999999,
                stop: 3.489125999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 3.489125999999999,
                stop: 3.6087500000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 3.6087500000000006,
                stop: 3.7174380000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CUSTOMERS*, ',
                width: 1080,
                height: 1920,
                start: 3.7174380000000014,
                stop: 4.489125900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.489125900000001,
                stop: 4.608750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MAXIMIZE* ',
                width: 1080,
                height: 1920,
                start: 4.608750000000001,
                stop: 5.152187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 5.152187999999999,
                stop: 5.2608758999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *IMPACT*',
                width: 1080,
                height: 1920,
                start: 5.2608758999999985,
                stop: 5.728250899999999
              }
            ]
          },
          {
            duration: 3.5543128999999993,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_shktsl.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "LET'S ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.16300000000000026
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GET* ',
                width: 1080,
                height: 1920,
                start: 0.16300000000000026,
                stop: 0.32606290000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STARTED* ',
                width: 1080,
                height: 1920,
                start: 0.32606290000000016,
                stop: 0.7282499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 0.7282499999999992,
                stop: 0.8260629000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MAKE* ',
                width: 1080,
                height: 1920,
                start: 0.8260629000000002,
                stop: 1
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 1,
                stop: 1.097812900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DREAMS* ',
                width: 1080,
                height: 1920,
                start: 1.097812900000001,
                stop: 1.4456249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.4456249999999997,
                stop: 1.5325628999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SUCCESS* ',
                width: 1080,
                height: 1920,
                start: 1.5325628999999985,
                stop: 2.0108129000000012
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 2.0108129000000012,
                stop: 2.0543128999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *REALITY!*',
                width: 1080,
                height: 1920,
                start: 2.0543128999999993,
                stop: 2.7173750000000005
              }
            ]
          }
        ],
        meta: {
          title: 'Marketing Services for Small Businesses',
          description:
            'Need help promoting your services? Our team of marketing experts can create a custom plan to fit your goals and budget.',
          hashtags: '#marketing #promotion #smallbusiness #success #shorts',
          mediaDescription:
            'A portrait of a smiling businessperson on their laptop in their office.',
          category: 'Free Style',
          prompt: 'marketing services'
        }
      }
    ],
    category: 'Free Style',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_qnhygo.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042189/woxo-videos-user-gallery/_dreaming/blob_shktsl.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover:
              "Are you a small business looking to promote your services but don't know how to get started?",
            captions:
              "Are ~ you ~ a ~ *small* ~ *business* ~ looking ~ to ~ *promote* ~ your ~ *services* ~ but ~ don't ~ *know* ~ how ~ to ~ *get* ~ *started?*"
          },
          {
            voiceover:
              'Let us help you! Our team of marketing experts will work with you to create a custom plan that fits your budget and goals.',
            captions:
              'Let ~ us ~ *help* ~ you! ~ Our ~ team ~ of ~ *marketing* ~ *experts* ~ will ~ work ~ with ~ you ~ to ~ create ~ a ~ *custom* ~ *plan* ~ that ~ fits ~ your ~ *budget* ~ and ~ *goals*'
          },
          {
            voiceover:
              "We'll help you create an effective online presence, build a lasting relationship with your customers, and maximize your impact.",
            captions:
              "We'll ~ *help* ~ you ~ *create* ~ an ~ *effective* ~ *online* ~ presence, ~ build ~ a ~ *lasting* ~ *relationship* ~ with ~ your ~ *customers*, ~ and ~ *maximize* ~ your ~ *impact*"
          },
          {
            voiceover: "Let's get started and make your dreams of success a reality!",
            captions:
              "Let's ~ *get* ~ *started* ~ and ~ *make* ~ your ~ *dreams* ~ of ~ *success* ~ a ~ *reality!*"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script, following this prompt: marketing services and the structure provided below (feel free to add more scenes as needed). Return it as a VALID array of JSON objects (Language English). (The amount of scenes or voiceovers on the structure may vary following the prompt)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad, Hip Hop] Topic: marketing services",\n       "scenes": [\n         {\n           "voiceover": "Voiceover"\n         },\n         {\n           "voiceover": "Continuation"\n         },\n         {\n          "voiceover": "Continuation"\n        },  \n        {\n          "voiceover": "Continuation"\n        },\n        {\n          "voiceover": "Continuation"\n        }                     \n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",\n        "mediaDescription": "A description for a picture that illustrates the main topic or characters of the video(If the topic is a person a portrait)."\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/erVrux8AOM/HMU_C.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Are  you  a  small  business  looking  to  promote  your  services  but  don\'t  know  how  to  get  started?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Let  us  help  you!  Our  team  of  marketing  experts  will  work  with  you  to  create  a  custom  plan  that  fits  your  budget  and  goals</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">We\'ll  help  you  create  an  effective  online  presence,  build  a  lasting  relationship  with  your  customers,  and  maximize  your  impact</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Let\'s  get  started  and  make  your  dreams  of  success  a  reality!</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 3.5435
              },
              {
                markName: 'scene_1',
                timeSeconds: 11.326062
              },
              {
                markName: 'scene_2',
                timeSeconds: 18.054312
              },
              {
                markName: 'scene_3',
                timeSeconds: 21.673875
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Are',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'you',
                timeSeconds: 0.184812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 267,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 0.304375,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 272,
                wordLength: 1
              },
              {
                word: 'small',
                timeSeconds: 0.369562,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 275,
                wordLength: 5
              },
              {
                word: 'business',
                timeSeconds: 0.684812,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 282,
                wordLength: 8
              },
              {
                word: 'looking',
                timeSeconds: 1.02175,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 292,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 1.304375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 301,
                wordLength: 2
              },
              {
                word: 'promote',
                timeSeconds: 1.3913119,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 305,
                wordLength: 7
              },
              {
                word: 'your',
                timeSeconds: 1.652188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 314,
                wordLength: 4
              },
              {
                word: 'services',
                timeSeconds: 1.760875,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 320,
                wordLength: 8
              },
              {
                word: 'but',
                timeSeconds: 2.358688,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 330,
                wordLength: 3
              },
              {
                word: "don't",
                timeSeconds: 2.510875,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 335,
                wordLength: 5
              },
              {
                word: 'know',
                timeSeconds: 2.684812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 342,
                wordLength: 4
              },
              {
                word: 'how',
                timeSeconds: 2.804375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 348,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 2.913062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 353,
                wordLength: 2
              },
              {
                word: 'get',
                timeSeconds: 2.989125,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 357,
                wordLength: 3
              },
              {
                word: 'started',
                timeSeconds: 3.130438,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 362,
                wordLength: 7
              },
              {
                word: '?',
                timeSeconds: 3.554375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 369,
                wordLength: 1
              },
              {
                word: 'Let',
                timeSeconds: 5.304375,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 452,
                wordLength: 3
              },
              {
                word: 'us',
                timeSeconds: 5.4565,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 457,
                wordLength: 2
              },
              {
                word: 'help',
                timeSeconds: 5.565188,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 461,
                wordLength: 4
              },
              {
                word: 'you',
                timeSeconds: 5.7935,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 467,
                wordLength: 3
              },
              {
                word: '!',
                timeSeconds: 6.076062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 470,
                wordLength: 1
              },
              {
                word: 'Our',
                timeSeconds: 6.815188,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 473,
                wordLength: 3
              },
              {
                word: 'team',
                timeSeconds: 6.989125,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 478,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 7.2065,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 484,
                wordLength: 2
              },
              {
                word: 'marketing',
                timeSeconds: 7.304375,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 488,
                wordLength: 9
              },
              {
                word: 'experts',
                timeSeconds: 7.717375,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 499,
                wordLength: 7
              },
              {
                word: 'will',
                timeSeconds: 8.173938,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 508,
                wordLength: 4
              },
              {
                word: 'work',
                timeSeconds: 8.315188,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 514,
                wordLength: 4
              },
              {
                word: 'with',
                timeSeconds: 8.5,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 520,
                wordLength: 4
              },
              {
                word: 'you',
                timeSeconds: 8.663062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 526,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 8.782625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 531,
                wordLength: 2
              },
              {
                word: 'create',
                timeSeconds: 8.880438,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 535,
                wordLength: 6
              },
              {
                word: 'a',
                timeSeconds: 9.163062,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 543,
                wordLength: 1
              },
              {
                word: 'custom',
                timeSeconds: 9.217375,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 546,
                wordLength: 6
              },
              {
                word: 'plan',
                timeSeconds: 9.597812,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 554,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 9.891312,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 560,
                wordLength: 4
              },
              {
                word: 'fits',
                timeSeconds: 10.0435,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 566,
                wordLength: 4
              },
              {
                word: 'your',
                timeSeconds: 10.282625,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 572,
                wordLength: 4
              },
              {
                word: 'budget',
                timeSeconds: 10.402188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 578,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 10.760875,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 586,
                wordLength: 3
              },
              {
                word: 'goals',
                timeSeconds: 10.869562,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 591,
                wordLength: 5
              },
              {
                word: "We'll",
                timeSeconds: 12.326062,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 678,
                wordLength: 5
              },
              {
                word: 'help',
                timeSeconds: 12.423938,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 685,
                wordLength: 4
              },
              {
                word: 'you',
                timeSeconds: 12.597812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 691,
                wordLength: 3
              },
              {
                word: 'create',
                timeSeconds: 12.717375,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 696,
                wordLength: 6
              },
              {
                word: 'an',
                timeSeconds: 13,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 704,
                wordLength: 2
              },
              {
                word: 'effective',
                timeSeconds: 13.076062,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 708,
                wordLength: 9
              },
              {
                word: 'online',
                timeSeconds: 13.510875,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 719,
                wordLength: 6
              },
              {
                word: 'presence',
                timeSeconds: 13.880438,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 727,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 14.467375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 735,
                wordLength: 1
              },
              {
                word: 'build',
                timeSeconds: 14.554375,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 738,
                wordLength: 5
              },
              {
                word: 'a',
                timeSeconds: 14.77175,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 745,
                wordLength: 1
              },
              {
                word: 'lasting',
                timeSeconds: 14.826062,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 748,
                wordLength: 7
              },
              {
                word: 'relationship',
                timeSeconds: 15.22825,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 757,
                wordLength: 12
              },
              {
                word: 'with',
                timeSeconds: 15.815188,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 771,
                wordLength: 4
              },
              {
                word: 'your',
                timeSeconds: 15.934812,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 777,
                wordLength: 4
              },
              {
                word: 'customers',
                timeSeconds: 16.0435,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 783,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 16.717375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 792,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 16.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 795,
                wordLength: 3
              },
              {
                word: 'maximize',
                timeSeconds: 16.934812,
                boundaryType: 'WordBoundary',
                duration: 0.532625,
                textOffset: 800,
                wordLength: 8
              },
              {
                word: 'your',
                timeSeconds: 17.47825,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 810,
                wordLength: 4
              },
              {
                word: 'impact',
                timeSeconds: 17.5869379,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 816,
                wordLength: 6
              },
              {
                word: "Let's",
                timeSeconds: 19.054375,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 904,
                wordLength: 5
              },
              {
                word: 'get',
                timeSeconds: 19.217375,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 911,
                wordLength: 3
              },
              {
                word: 'started',
                timeSeconds: 19.3804379,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 916,
                wordLength: 7
              },
              {
                word: 'and',
                timeSeconds: 19.782625,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 925,
                wordLength: 3
              },
              {
                word: 'make',
                timeSeconds: 19.8804379,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 930,
                wordLength: 4
              },
              {
                word: 'your',
                timeSeconds: 20.054375,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 936,
                wordLength: 4
              },
              {
                word: 'dreams',
                timeSeconds: 20.1521879,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 942,
                wordLength: 6
              },
              {
                word: 'of',
                timeSeconds: 20.5,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 950,
                wordLength: 2
              },
              {
                word: 'success',
                timeSeconds: 20.5869379,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 954,
                wordLength: 7
              },
              {
                word: 'a',
                timeSeconds: 21.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 963,
                wordLength: 1
              },
              {
                word: 'reality',
                timeSeconds: 21.1086879,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 966,
                wordLength: 7
              },
              {
                word: '!',
                timeSeconds: 21.684812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 973,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1680500414/woxo-video/audios-v4/Happy/Tokyo_Night_Pop__BPM130.mp3'
    ]
  },
  {
    id: '3993bdb2-8523-46ae-8eed-5e25edcf305a',
    text: 'A Bedstory about "marketing services"',
    videos: [
      {
        id: 'c6f53f54-44d3-4651-b64f-9b24e9ba1eaa',
        proportion: 0.3,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/I4fxoZ04t8/8JxE_.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 9.304312,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_cbggzp.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.260875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' KIDS, ',
                width: 1080,
                height: 1920,
                start: 0.260875,
                stop: 0.847812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVER ',
                width: 1080,
                height: 1920,
                start: 0.847812,
                stop: 1.0760619
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WONDERED* ',
                width: 1080,
                height: 1920,
                start: 1.0760619,
                stop: 1.402188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOW ',
                width: 1080,
                height: 1920,
                start: 1.402188,
                stop: 1.554375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMPANIES* ',
                width: 1080,
                height: 1920,
                start: 1.554375,
                stop: 1.945625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GET ',
                width: 1080,
                height: 1920,
                start: 1.945625,
                stop: 2.097812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.097812,
                stop: 2.217375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SERVICES* ',
                width: 1080,
                height: 1920,
                start: 2.217375,
                stop: 2.641312
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OUT ',
                width: 1080,
                height: 1920,
                start: 2.641312,
                stop: 2.782625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *THERE?* ',
                width: 1080,
                height: 1920,
                start: 2.782625,
                stop: 3.815188
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LET ',
                width: 1080,
                height: 1920,
                start: 3.815188,
                stop: 3.989125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 3.989125,
                stop: 4.097812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TELL ',
                width: 1080,
                height: 1920,
                start: 4.097812,
                stop: 4.336938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 4.336938,
                stop: 4.445625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AN ',
                width: 1080,
                height: 1920,
                start: 4.445625,
                stop: 4.52175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AMAZING* ',
                width: 1080,
                height: 1920,
                start: 4.52175,
                stop: 4.945625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORY* ',
                width: 1080,
                height: 1920,
                start: 4.945625,
                stop: 5.326062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 5.326062,
                stop: 5.576062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 5.576062,
                stop: 5.97825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES!',
                width: 1080,
                height: 1920,
                start: 5.97825,
                stop: 6.6956240000000005
              }
            ]
          },
          {
            duration: 11.108687999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_y3cvev.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ONCE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.26087600000000144
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UPON ',
                width: 1080,
                height: 1920,
                start: 0.26087600000000144,
                stop: 0.5108760000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.5108760000000014,
                stop: 0.5652500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME, ',
                width: 1080,
                height: 1920,
                start: 0.5652500000000007,
                stop: 1.0761260000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *THERE* ',
                width: 1080,
                height: 1920,
                start: 1.0761260000000004,
                stop: 1.206563000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 1.206563000000001,
                stop: 1.4021880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.4021880000000007,
                stop: 1.4021880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SMALL* ',
                width: 1080,
                height: 1920,
                start: 1.4021880000000007,
                stop: 1.7826260000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSINESS* ',
                width: 1080,
                height: 1920,
                start: 1.7826260000000005,
                stop: 2.1521880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *OWNER* ',
                width: 1080,
                height: 1920,
                start: 2.1521880000000007,
                stop: 2.4456880000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHO ',
                width: 1080,
                height: 1920,
                start: 2.4456880000000005,
                stop: 2.5435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 2.5435,
                stop: 2.684813
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *EAGER* ',
                width: 1080,
                height: 1920,
                start: 2.684813,
                stop: 2.9239380000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.9239380000000015,
                stop: 2.9891260000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SPREAD ',
                width: 1080,
                height: 1920,
                start: 2.9891260000000006,
                stop: 3.3152500000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.3152500000000007,
                stop: 3.4021880000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WORD ',
                width: 1080,
                height: 1920,
                start: 3.4021880000000007,
                stop: 3.608688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 3.608688000000001,
                stop: 3.8261260000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 3.8261260000000004,
                stop: 3.9456880000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES. ',
                width: 1080,
                height: 1920,
                start: 3.9456880000000005,
                stop: 5.315250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO ',
                width: 1080,
                height: 1920,
                start: 5.315250000000001,
                stop: 5.5326260000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 5.5326260000000005,
                stop: 5.641313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DECIDED ',
                width: 1080,
                height: 1920,
                start: 5.641313,
                stop: 6.0870000000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 6.0870000000000015,
                stop: 6.1739380000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PUT ',
                width: 1080,
                height: 1920,
                start: 6.1739380000000015,
                stop: 6.358688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TOGETHER ',
                width: 1080,
                height: 1920,
                start: 6.358688000000001,
                stop: 6.728250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 6.728250000000001,
                stop: 6.771750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 6.771750000000001,
                stop: 7.1956880000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STRATEGY* ',
                width: 1080,
                height: 1920,
                start: 7.1956880000000005,
                stop: 7.717376
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 7.717376,
                stop: 7.8043759999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DO ',
                width: 1080,
                height: 1920,
                start: 7.8043759999999995,
                stop: 7.978250000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO.',
                width: 1080,
                height: 1920,
                start: 7.978250000000001,
                stop: 8.456501000000001
              }
            ]
          },
          {
            duration: 12.434812,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_cbggzp.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.09787500000000193
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SMALL* ',
                width: 1080,
                height: 1920,
                start: 0.09787500000000193,
                stop: 0.43481200000000086
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSINESS* ',
                width: 1080,
                height: 1920,
                start: 0.43481200000000086,
                stop: 0.8043750000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *OWNER* ',
                width: 1080,
                height: 1920,
                start: 0.8043750000000003,
                stop: 1.119562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' RESEARCHED ',
                width: 1080,
                height: 1920,
                start: 1.119562000000002,
                stop: 1.597875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DIFFERENT ',
                width: 1080,
                height: 1920,
                start: 1.597875000000002,
                stop: 1.8586879000000032
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 1.8586879000000032,
                stop: 2.2283120000000025
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TACTICS* ',
                width: 1080,
                height: 1920,
                start: 2.2283120000000025,
                stop: 2.782625000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.782625000000003,
                stop: 2.9021879000000013
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CHOSE ',
                width: 1080,
                height: 1920,
                start: 2.9021879000000013,
                stop: 3.184812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.184812000000001,
                stop: 3.271750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ONES ',
                width: 1080,
                height: 1920,
                start: 3.271750000000001,
                stop: 3.5652500000000025
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THAT ',
                width: 1080,
                height: 1920,
                start: 3.5652500000000025,
                stop: 3.673937900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WERE ',
                width: 1080,
                height: 1920,
                start: 3.673937900000002,
                stop: 3.760875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 3.760875000000002,
                stop: 3.858687900000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BEST* ',
                width: 1080,
                height: 1920,
                start: 3.858687900000003,
                stop: 4.184812000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FIT* ',
                width: 1080,
                height: 1920,
                start: 4.184812000000001,
                stop: 4.445687900000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 4.445687900000003,
                stop: 4.5652500000000025
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 4.5652500000000025,
                stop: 4.673937900000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SERVICES.* ',
                width: 1080,
                height: 1920,
                start: 4.673937900000002,
                stop: 6.021750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 6.021750000000001,
                stop: 6.163062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INVESTED* ',
                width: 1080,
                height: 1920,
                start: 6.163062,
                stop: 6.760875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 6.760875000000002,
                stop: 6.760875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 6.760875000000002,
                stop: 6.8152500000000025
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WEBSITE, ',
                width: 1080,
                height: 1920,
                start: 6.8152500000000025,
                stop: 7.489125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SOCIAL* ',
                width: 1080,
                height: 1920,
                start: 7.489125000000001,
                stop: 7.891312000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MEDIA,* ',
                width: 1080,
                height: 1920,
                start: 7.891312000000003,
                stop: 8.489125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 8.489125000000001,
                stop: 8.619562000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' EVEN ',
                width: 1080,
                height: 1920,
                start: 8.619562000000002,
                stop: 8.847875000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOME ',
                width: 1080,
                height: 1920,
                start: 8.847875000000002,
                stop: 9.054375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PRINT* ',
                width: 1080,
                height: 1920,
                start: 9.054375,
                stop: 9.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ADS.*',
                width: 1080,
                height: 1920,
                start: 9.27175,
                stop: 9.782624000000002
              }
            ]
          },
          {
            duration: 10.152188000000002,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_y3cvev.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'WITHIN ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.3043130000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NO* ',
                width: 1080,
                height: 1920,
                start: 0.3043130000000005,
                stop: 0.46737600000000157
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME, ',
                width: 1080,
                height: 1920,
                start: 0.46737600000000157,
                stop: 0.9782499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 0.9782499999999992,
                stop: 1.0760630000000013
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAD ',
                width: 1080,
                height: 1920,
                start: 1.0760630000000013,
                stop: 1.2391260000000024
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOTS* ',
                width: 1080,
                height: 1920,
                start: 1.2391260000000024,
                stop: 1.5108759999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.5108759999999997,
                stop: 1.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' PEOPLE ',
                width: 1080,
                height: 1920,
                start: 1.6195629999999994,
                stop: 1.913063000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *INTERESTED* ',
                width: 1080,
                height: 1920,
                start: 1.913063000000001,
                stop: 2.4456260000000043
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 2.4456260000000043,
                stop: 2.4456260000000043
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.4456260000000043,
                stop: 2.5760629999999978
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES ',
                width: 1080,
                height: 1920,
                start: 2.5760629999999978,
                stop: 3.239125999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.239125999999999,
                stop: 3.3695629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 3.3695629999999994,
                stop: 3.4564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WERE ',
                width: 1080,
                height: 1920,
                start: 3.4564999999999984,
                stop: 3.597813000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABLE ',
                width: 1080,
                height: 1920,
                start: 3.597813000000002,
                stop: 3.7935000000000016
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.7935000000000016,
                stop: 3.858688000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GROW* ',
                width: 1080,
                height: 1920,
                start: 3.858688000000001,
                stop: 4.076062999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 4.076062999999998,
                stop: 4.228250000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUSINESS. ',
                width: 1080,
                height: 1920,
                start: 4.228250000000003,
                stop: 5.445626000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SO ',
                width: 1080,
                height: 1920,
                start: 5.445626000000004,
                stop: 5.695626000000004
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IF ',
                width: 1080,
                height: 1920,
                start: 5.695626000000004,
                stop: 5.793500000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 5.793500000000002,
                stop: 5.869562999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WANT ',
                width: 1080,
                height: 1920,
                start: 5.869562999999999,
                stop: 6.021749999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 6.021749999999997,
                stop: 6.097813000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LEARN* ',
                width: 1080,
                height: 1920,
                start: 6.097813000000002,
                stop: 6.315187999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MORE,* ',
                width: 1080,
                height: 1920,
                start: 6.315187999999999,
                stop: 6.739125999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STAY* ',
                width: 1080,
                height: 1920,
                start: 6.739125999999999,
                stop: 6.967376000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TUNED!*',
                width: 1080,
                height: 1920,
                start: 6.967376000000002,
                stop: 7.5
              }
            ]
          },
          {
            duration: 4.282561999999999,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_cbggzp.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FOR ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17387499999999534
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.17387499999999534,
                stop: 0.38043799999999806
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STORIES* ',
                width: 1080,
                height: 1920,
                start: 0.38043799999999806,
                stop: 0.8260620000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LIKE ',
                width: 1080,
                height: 1920,
                start: 0.8260620000000003,
                stop: 1.0325619999999986
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS, ',
                width: 1080,
                height: 1920,
                start: 1.0325619999999986,
                stop: 1.4891250000000014
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOLLOW ',
                width: 1080,
                height: 1920,
                start: 1.4891250000000014,
                stop: 1.804311999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ME ',
                width: 1080,
                height: 1920,
                start: 1.804311999999996,
                stop: 2.0216879999999975
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.0216879999999975,
                stop: 2.0978119999999976
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " LET'S ",
                width: 1080,
                height: 1920,
                start: 2.0978119999999976,
                stop: 2.293438000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' GET ',
                width: 1080,
                height: 1920,
                start: 2.293438000000002,
                stop: 2.4456249999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATIVE* ',
                width: 1080,
                height: 1920,
                start: 2.4456249999999997,
                stop: 2.7825619999999986
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TOGETHER!*',
                width: 1080,
                height: 1920,
                start: 2.7825619999999986,
                stop: 3.3478119999999976
              }
            ]
          }
        ],
        meta: {
          title: 'Marketing Services Story for Kids',
          description:
            'Learn all about marketing services with this fun bedtime story for kids! Follow along as we take a look at how one small business owner grew their business.',
          hashtags: '#marketing #services #kidsstory #bedtimestory #shorts',
          mediaDescription:
            'A small business owner looking at their marketing strategy with a smile on their face.',
          category: 'Bedstory',
          prompt: 'marketing services'
        }
      }
    ],
    category: 'Bedstory',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_cbggzp.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042837/woxo-videos-user-gallery/_dreaming/blob_y3cvev.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover:
              'Hey kids, ever wondered how companies get their services out there? Let me tell you an amazing story about marketing services!',
            captions:
              'Hey ~ kids, ~ ever ~ *wondered* ~ how ~ *companies* ~ get ~ their ~ *services* ~ out ~ *there?* ~ Let ~ me ~ tell ~ you ~ an ~ *amazing* ~ *story* ~ about ~ *marketing* ~ services!'
          },
          {
            voiceover:
              'Once upon a time, there was a small business owner who was eager to spread the word about their services. So they decided to put together a marketing strategy to do so.',
            captions:
              'Once ~ upon ~ a ~ time, ~ *there* ~ was ~ a ~ *small* ~ *business* ~ *owner* ~ who ~ was ~ *eager* ~ to ~ spread ~ the ~ word ~ about ~ their ~ services. ~ So ~ they ~ decided ~ to ~ put ~ together ~ a ~ *marketing* ~ *strategy* ~ to ~ do ~ so.'
          },
          {
            voiceover:
              'The small business owner researched different marketing tactics and chose the ones that were the best fit for their services. They invested in a website, social media, and even some print ads.',
            captions:
              'The ~ *small* ~ *business* ~ *owner* ~ researched ~ different ~ *marketing* ~ *tactics* ~ and ~ chose ~ the ~ ones ~ that ~ were ~ the ~ *best* ~ *fit* ~ for ~ their ~ *services.* ~ They ~ *invested* ~ in ~ a ~ website, ~ *social* ~ *media,* ~ and ~ even ~ some ~ *print* ~ *ads.*'
          },
          {
            voiceover:
              'Within no time, they had lots of people interested in their services and they were able to grow their business. So if you want to learn more, stay tuned!',
            captions:
              'Within ~ *no* ~ time, ~ they ~ had ~ *lots* ~ of ~ people ~ *interested* ~ in ~ their ~ services ~ and ~ they ~ were ~ able ~ to ~ *grow* ~ their ~ business. ~ So ~ if ~ you ~ want ~ to ~ *learn* ~ *more,* ~ *stay* ~ *tuned!*'
          },
          {
            voiceover: "For more stories like this, follow me and let's get creative together!",
            captions:
              "For ~ more ~ *stories* ~ like ~ this, ~ follow ~ me ~ and ~ let's ~ get ~ *creative* ~ *together!*"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a bedstory for kids about marketing services and return it as a VALID array of JSON objects (Language English)\n    Example:\n    [\n     {\n       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: marketing services",          \n       "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an amazing story about marketing services?"\n        },\n        {\n        "voiceover": "Tell a bedstory for kids about marketing services (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },                 \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n       ],\n       "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: marketing services"\n       }\n     }\n  ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/I4fxoZ04t8/8JxE_.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Hey  kids,  ever  wondered  how  companies  get  their  services  out  there?  Let  me  tell  you  an  amazing  story  about  marketing  services!</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Once  upon  a  time,  there  was  a  small  business  owner  who  was  eager  to  spread  the  word  about  their  services.  So  they  decided  to  put  together  a  marketing  strategy  to  do  so.</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">The  small  business  owner  researched  different  marketing  tactics  and  chose  the  ones  that  were  the  best  fit  for  their  services.  They  invested  in  a  website,  social  media,  and  even  some  print  ads.</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Within  no  time,  they  had  lots  of  people  interested  in  their  services  and  they  were  able  to  grow  their  business.  So  if  you  want  to  learn  more,  stay  tuned!</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">For  more  stories  like  this,  follow  me  and  let\'s  get  creative  together!</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 6.586938
              },
              {
                markName: 'scene_1',
                timeSeconds: 16.7065
              },
              {
                markName: 'scene_2',
                timeSeconds: 28.130438
              },
              {
                markName: 'scene_3',
                timeSeconds: 37.282625
              },
              {
                markName: 'scene_4',
                timeSeconds: 42.282562
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Hey',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'kids',
                timeSeconds: 0.260875,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 267,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 0.77175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 271,
                wordLength: 1
              },
              {
                word: 'ever',
                timeSeconds: 0.847812,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 274,
                wordLength: 4
              },
              {
                word: 'wondered',
                timeSeconds: 1.0760619,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 280,
                wordLength: 8
              },
              {
                word: 'how',
                timeSeconds: 1.402188,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 290,
                wordLength: 3
              },
              {
                word: 'companies',
                timeSeconds: 1.554375,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 295,
                wordLength: 9
              },
              {
                word: 'get',
                timeSeconds: 1.945625,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 306,
                wordLength: 3
              },
              {
                word: 'their',
                timeSeconds: 2.097812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 311,
                wordLength: 5
              },
              {
                word: 'services',
                timeSeconds: 2.217375,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 318,
                wordLength: 8
              },
              {
                word: 'out',
                timeSeconds: 2.641312,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 328,
                wordLength: 3
              },
              {
                word: 'there',
                timeSeconds: 2.782625,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 333,
                wordLength: 5
              },
              {
                word: '?',
                timeSeconds: 3.065188,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 338,
                wordLength: 1
              },
              {
                word: 'Let',
                timeSeconds: 3.815188,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 341,
                wordLength: 3
              },
              {
                word: 'me',
                timeSeconds: 3.989125,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 346,
                wordLength: 2
              },
              {
                word: 'tell',
                timeSeconds: 4.097812,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 350,
                wordLength: 4
              },
              {
                word: 'you',
                timeSeconds: 4.336938,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 356,
                wordLength: 3
              },
              {
                word: 'an',
                timeSeconds: 4.445625,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 361,
                wordLength: 2
              },
              {
                word: 'amazing',
                timeSeconds: 4.52175,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 365,
                wordLength: 7
              },
              {
                word: 'story',
                timeSeconds: 4.945625,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 374,
                wordLength: 5
              },
              {
                word: 'about',
                timeSeconds: 5.326062,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 381,
                wordLength: 5
              },
              {
                word: 'marketing',
                timeSeconds: 5.576062,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 388,
                wordLength: 9
              },
              {
                word: 'services',
                timeSeconds: 5.97825,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 399,
                wordLength: 8
              },
              {
                word: '!',
                timeSeconds: 6.597812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 407,
                wordLength: 1
              },
              {
                word: 'Once',
                timeSeconds: 8.347812,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 490,
                wordLength: 4
              },
              {
                word: 'upon',
                timeSeconds: 8.608688,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 496,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 8.858688,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 502,
                wordLength: 1
              },
              {
                word: 'time',
                timeSeconds: 8.913062,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 505,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 9.358688,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 509,
                wordLength: 1
              },
              {
                word: 'there',
                timeSeconds: 9.423938,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 512,
                wordLength: 5
              },
              {
                word: 'was',
                timeSeconds: 9.554375,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 519,
                wordLength: 3
              },
              {
                word: 'a',
                timeSeconds: 9.684812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 524,
                wordLength: 1
              },
              {
                word: 'small',
                timeSeconds: 9.75,
                boundaryType: 'WordBoundary',
                duration: 0.369562,
                textOffset: 527,
                wordLength: 5
              },
              {
                word: 'business',
                timeSeconds: 10.130438,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 534,
                wordLength: 8
              },
              {
                word: 'owner',
                timeSeconds: 10.5,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 544,
                wordLength: 5
              },
              {
                word: 'who',
                timeSeconds: 10.7935,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 551,
                wordLength: 3
              },
              {
                word: 'was',
                timeSeconds: 10.891312,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 556,
                wordLength: 3
              },
              {
                word: 'eager',
                timeSeconds: 11.032625,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 561,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 11.27175,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 568,
                wordLength: 2
              },
              {
                word: 'spread',
                timeSeconds: 11.336938,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 572,
                wordLength: 6
              },
              {
                word: 'the',
                timeSeconds: 11.663062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 580,
                wordLength: 3
              },
              {
                word: 'word',
                timeSeconds: 11.75,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 585,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 11.9565,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 591,
                wordLength: 5
              },
              {
                word: 'their',
                timeSeconds: 12.173938,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 598,
                wordLength: 5
              },
              {
                word: 'services',
                timeSeconds: 12.2935,
                boundaryType: 'WordBoundary',
                duration: 0.608688,
                textOffset: 605,
                wordLength: 8
              },
              {
                word: '.',
                timeSeconds: 12.913062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 613,
                wordLength: 1
              },
              {
                word: 'So',
                timeSeconds: 13.663062,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 616,
                wordLength: 2
              },
              {
                word: 'they',
                timeSeconds: 13.880438,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 620,
                wordLength: 4
              },
              {
                word: 'decided',
                timeSeconds: 13.989125,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 626,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 14.434812,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 635,
                wordLength: 2
              },
              {
                word: 'put',
                timeSeconds: 14.52175,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 639,
                wordLength: 3
              },
              {
                word: 'together',
                timeSeconds: 14.7065,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 644,
                wordLength: 8
              },
              {
                word: 'a',
                timeSeconds: 15.076062,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 654,
                wordLength: 1
              },
              {
                word: 'marketing',
                timeSeconds: 15.119562,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 657,
                wordLength: 9
              },
              {
                word: 'strategy',
                timeSeconds: 15.5435,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 668,
                wordLength: 8
              },
              {
                word: 'to',
                timeSeconds: 16.065188,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 678,
                wordLength: 2
              },
              {
                word: 'do',
                timeSeconds: 16.152188,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 682,
                wordLength: 2
              },
              {
                word: 'so',
                timeSeconds: 16.326062,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 686,
                wordLength: 2
              },
              {
                word: '.',
                timeSeconds: 16.717375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 688,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 18.4565,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 771,
                wordLength: 3
              },
              {
                word: 'small',
                timeSeconds: 18.554375,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 776,
                wordLength: 5
              },
              {
                word: 'business',
                timeSeconds: 18.891312,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 783,
                wordLength: 8
              },
              {
                word: 'owner',
                timeSeconds: 19.260875,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 793,
                wordLength: 5
              },
              {
                word: 'researched',
                timeSeconds: 19.576062,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 800,
                wordLength: 10
              },
              {
                word: 'different',
                timeSeconds: 20.054375,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 812,
                wordLength: 9
              },
              {
                word: 'marketing',
                timeSeconds: 20.3151879,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 823,
                wordLength: 9
              },
              {
                word: 'tactics',
                timeSeconds: 20.684812,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 834,
                wordLength: 7
              },
              {
                word: 'and',
                timeSeconds: 21.239125,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 843,
                wordLength: 3
              },
              {
                word: 'chose',
                timeSeconds: 21.3586879,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 848,
                wordLength: 5
              },
              {
                word: 'the',
                timeSeconds: 21.641312,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 855,
                wordLength: 3
              },
              {
                word: 'ones',
                timeSeconds: 21.72825,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 860,
                wordLength: 4
              },
              {
                word: 'that',
                timeSeconds: 22.02175,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 866,
                wordLength: 4
              },
              {
                word: 'were',
                timeSeconds: 22.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 872,
                wordLength: 4
              },
              {
                word: 'the',
                timeSeconds: 22.217375,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 878,
                wordLength: 3
              },
              {
                word: 'best',
                timeSeconds: 22.3151879,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 883,
                wordLength: 4
              },
              {
                word: 'fit',
                timeSeconds: 22.641312,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 889,
                wordLength: 3
              },
              {
                word: 'for',
                timeSeconds: 22.9021879,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 894,
                wordLength: 3
              },
              {
                word: 'their',
                timeSeconds: 23.02175,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 899,
                wordLength: 5
              },
              {
                word: 'services',
                timeSeconds: 23.1304379,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 906,
                wordLength: 8
              },
              {
                word: '.',
                timeSeconds: 23.72825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 914,
                wordLength: 1
              },
              {
                word: 'They',
                timeSeconds: 24.47825,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 917,
                wordLength: 4
              },
              {
                word: 'invested',
                timeSeconds: 24.619562,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 923,
                wordLength: 8
              },
              {
                word: 'in',
                timeSeconds: 25.119562,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 933,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 25.217375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 937,
                wordLength: 1
              },
              {
                word: 'website',
                timeSeconds: 25.27175,
                boundaryType: 'WordBoundary',
                duration: 0.510875,
                textOffset: 940,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 25.869562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 947,
                wordLength: 1
              },
              {
                word: 'social',
                timeSeconds: 25.945625,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 950,
                wordLength: 6
              },
              {
                word: 'media',
                timeSeconds: 26.347812,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 958,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 26.847812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 963,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 26.945625,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 966,
                wordLength: 3
              },
              {
                word: 'even',
                timeSeconds: 27.076062,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 971,
                wordLength: 4
              },
              {
                word: 'some',
                timeSeconds: 27.304375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 977,
                wordLength: 4
              },
              {
                word: 'print',
                timeSeconds: 27.510875,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 983,
                wordLength: 5
              },
              {
                word: 'ads',
                timeSeconds: 27.72825,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 990,
                wordLength: 3
              },
              {
                word: '.',
                timeSeconds: 28.141312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 993,
                wordLength: 1
              },
              {
                word: 'Within',
                timeSeconds: 29.891312,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 1076,
                wordLength: 6
              },
              {
                word: 'no',
                timeSeconds: 30.195625,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 1084,
                wordLength: 2
              },
              {
                word: 'time',
                timeSeconds: 30.358688,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 1088,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 30.804375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 1092,
                wordLength: 1
              },
              {
                word: 'they',
                timeSeconds: 30.869562,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1095,
                wordLength: 4
              },
              {
                word: 'had',
                timeSeconds: 30.967375,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 1101,
                wordLength: 3
              },
              {
                word: 'lots',
                timeSeconds: 31.130438,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 1106,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 31.402188,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1112,
                wordLength: 2
              },
              {
                word: 'people',
                timeSeconds: 31.510875,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 1116,
                wordLength: 6
              },
              {
                word: 'interested',
                timeSeconds: 31.804375,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 1124,
                wordLength: 10
              },
              {
                word: 'in',
                timeSeconds: 32.260875,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1136,
                wordLength: 2
              },
              {
                word: 'their',
                timeSeconds: 32.336938,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1140,
                wordLength: 5
              },
              {
                word: 'services',
                timeSeconds: 32.467375,
                boundaryType: 'WordBoundary',
                duration: 0.597812,
                textOffset: 1147,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 33.130438,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1157,
                wordLength: 3
              },
              {
                word: 'they',
                timeSeconds: 33.260875,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1162,
                wordLength: 4
              },
              {
                word: 'were',
                timeSeconds: 33.347812,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1168,
                wordLength: 4
              },
              {
                word: 'able',
                timeSeconds: 33.489125,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 1174,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 33.684812,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 1180,
                wordLength: 2
              },
              {
                word: 'grow',
                timeSeconds: 33.75,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1184,
                wordLength: 4
              },
              {
                word: 'their',
                timeSeconds: 33.967375,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1190,
                wordLength: 5
              },
              {
                word: 'business',
                timeSeconds: 34.119562,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 1197,
                wordLength: 8
              },
              {
                word: '.',
                timeSeconds: 34.597812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1205,
                wordLength: 1
              },
              {
                word: 'So',
                timeSeconds: 35.336938,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1208,
                wordLength: 2
              },
              {
                word: 'if',
                timeSeconds: 35.586938,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 1212,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 35.684812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1216,
                wordLength: 3
              },
              {
                word: 'want',
                timeSeconds: 35.760875,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1221,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 35.913062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1227,
                wordLength: 2
              },
              {
                word: 'learn',
                timeSeconds: 35.989125,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 1231,
                wordLength: 5
              },
              {
                word: 'more',
                timeSeconds: 36.2065,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 1238,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 36.532625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1242,
                wordLength: 1
              },
              {
                word: 'stay',
                timeSeconds: 36.630438,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 1245,
                wordLength: 4
              },
              {
                word: 'tuned',
                timeSeconds: 36.858688,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 1251,
                wordLength: 5
              },
              {
                word: '!',
                timeSeconds: 37.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1256,
                wordLength: 1
              },
              {
                word: 'For',
                timeSeconds: 39.0435,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1339,
                wordLength: 3
              },
              {
                word: 'more',
                timeSeconds: 39.217375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1344,
                wordLength: 4
              },
              {
                word: 'stories',
                timeSeconds: 39.423938,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 1350,
                wordLength: 7
              },
              {
                word: 'like',
                timeSeconds: 39.869562,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 1359,
                wordLength: 4
              },
              {
                word: 'this',
                timeSeconds: 40.076062,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 1365,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 40.47825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.054375,
                textOffset: 1369,
                wordLength: 1
              },
              {
                word: 'follow',
                timeSeconds: 40.532625,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1372,
                wordLength: 6
              },
              {
                word: 'me',
                timeSeconds: 40.847812,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 1380,
                wordLength: 2
              },
              {
                word: 'and',
                timeSeconds: 41.065188,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1384,
                wordLength: 3
              },
              {
                word: "let's",
                timeSeconds: 41.141312,
                boundaryType: 'WordBoundary',
                duration: 0.184812,
                textOffset: 1389,
                wordLength: 5
              },
              {
                word: 'get',
                timeSeconds: 41.336938,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1396,
                wordLength: 3
              },
              {
                word: 'creative',
                timeSeconds: 41.489125,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 1401,
                wordLength: 8
              },
              {
                word: 'together',
                timeSeconds: 41.826062,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 1411,
                wordLength: 8
              },
              {
                word: '!',
                timeSeconds: 42.2935,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1419,
                wordLength: 1
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Shake_it_-_MOODHALO.mp3'
    ]
  },
  {
    id: '5a14651d-c83a-4f07-992a-cc6959b7b2b7',
    text: 'A Day in the Life of "marketing services"',
    videos: [
      {
        id: 'cf125f9c-8dfb-4a90-a626-57fd4f59c28a',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Barton_Springs_-_Bird_Creek.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/TLtgM0jGrh/rUsPS.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.7173750000000005,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_uad33u.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'EVER ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.304375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WONDERED* ',
                width: 1080,
                height: 1920,
                start: 0.304375,
                stop: 0.630438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WHAT ',
                width: 1080,
                height: 1920,
                start: 0.630438,
                stop: 0.847812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.847812,
                stop: 0.847812
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 0.847812,
                stop: 1.054375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 1.054375,
                stop: 1.130438
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.130438,
                stop: 1.2065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIFE* ',
                width: 1080,
                height: 1920,
                start: 1.2065,
                stop: 1.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 1.47825,
                stop: 1.5435
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.5435,
                stop: 1.5978119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 1.5978119,
                stop: 2.010875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES ',
                width: 1080,
                height: 1920,
                start: 2.010875,
                stop: 2.467375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LOOKS* ',
                width: 1080,
                height: 1920,
                start: 2.467375,
                stop: 2.7065
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LIKE?*',
                width: 1080,
                height: 1920,
                start: 2.7065,
                stop: 3.1086869999999998
              }
            ]
          },
          {
            duration: 9.423937,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_ea2wax.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FROM ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.21737499999999965
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *STRATEGIZING* ',
                width: 1080,
                height: 1920,
                start: 0.21737499999999965,
                stop: 0.9673749999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DIGITAL* ',
                width: 1080,
                height: 1920,
                start: 0.9673749999999997,
                stop: 1.2934999999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CAMPAIGNS,* ',
                width: 1080,
                height: 1920,
                start: 1.2934999999999999,
                stop: 2.1521869999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 2.1521869999999996,
                stop: 2.2608749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATING* ',
                width: 1080,
                height: 1920,
                start: 2.2608749999999995,
                stop: 2.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ENGAGING* ',
                width: 1080,
                height: 1920,
                start: 2.6195629999999994,
                stop: 3.0760629999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CONTENT, ',
                width: 1080,
                height: 1920,
                start: 3.0760629999999995,
                stop: 3.7173749999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.7173749999999988,
                stop: 3.836936999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ANALYZING* ',
                width: 1080,
                height: 1920,
                start: 3.836936999999999,
                stop: 4.391313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DATA ',
                width: 1080,
                height: 1920,
                start: 4.391313,
                stop: 4.717374999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.717374999999999,
                stop: 4.7826249999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MANAGING* ',
                width: 1080,
                height: 1920,
                start: 4.7826249999999995,
                stop: 5.217374999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUDGETS,* ',
                width: 1080,
                height: 1920,
                start: 5.217374999999999,
                stop: 5.869562999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: " IT'S ",
                width: 1080,
                height: 1920,
                start: 5.869562999999999,
                stop: 6
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 6,
                stop: 6.108687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DAY* ',
                width: 1080,
                height: 1920,
                start: 6.108687,
                stop: 6.380436999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FILLED ',
                width: 1080,
                height: 1920,
                start: 6.380436999999999,
                stop: 6.652187
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 6.652187,
                stop: 6.771749999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ACTIVITIES',
                width: 1080,
                height: 1920,
                start: 6.771749999999999,
                stop: 7.423938
              }
            ]
          },
          {
            duration: 6.989125899999998,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_uad33u.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'MARKETING ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.4021259999999991
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES ',
                width: 1080,
                height: 1920,
                start: 0.4021259999999991,
                stop: 0.9021259999999991
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 80,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PROFESSIONALS* ',
                width: 1080,
                height: 1920,
                start: 0.9021259999999991,
                stop: 1.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' NEED ',
                width: 1080,
                height: 1920,
                start: 1.586938,
                stop: 1.7716879999999993
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.7716879999999993,
                stop: 1.8478129999999986
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BE ',
                width: 1080,
                height: 1920,
                start: 1.8478129999999986,
                stop: 1.9891259999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CREATIVE,* ',
                width: 1080,
                height: 1920,
                start: 1.9891259999999988,
                stop: 2.6195629999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ANALYTICAL* ',
                width: 1080,
                height: 1920,
                start: 2.6195629999999994,
                stop: 3.3043130000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.3043130000000005,
                stop: 3.3912499999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ORGANIZED* ',
                width: 1080,
                height: 1920,
                start: 3.3912499999999994,
                stop: 3.8478129999999986
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.8478129999999986,
                stop: 3.9238758999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SUCCEED* ',
                width: 1080,
                height: 1920,
                start: 3.9238758999999988,
                stop: 4.391249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 4.391249999999999,
                stop: 4.4673759
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 4.4673759,
                stop: 4.5543130000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FIELD*',
                width: 1080,
                height: 1920,
                start: 4.5543130000000005,
                stop: 4.989125000000001
              }
            ]
          },
          {
            duration: 4.271687100000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_ea2wax.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'FOLLOW ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.31518710000000283
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 0.31518710000000283,
                stop: 0.40212410000000176
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.40212410000000176,
                stop: 0.5
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LEARN* ',
                width: 1080,
                height: 1920,
                start: 0.5,
                stop: 0.7173741000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' MORE ',
                width: 1080,
                height: 1920,
                start: 0.7173741000000007,
                stop: 0.891250000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 0.891250000000003,
                stop: 1.1304371000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 1.1304371000000017,
                stop: 1.5216871000000012
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES ',
                width: 1080,
                height: 1920,
                start: 1.5216871000000012,
                stop: 2.119562100000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 2.119562100000003,
                stop: 2.25
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ITS ',
                width: 1080,
                height: 1920,
                start: 2.25,
                stop: 2.3804371000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *VARIOUS* ',
                width: 1080,
                height: 1920,
                start: 2.3804371000000017,
                stop: 2.771687100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FACETS*',
                width: 1080,
                height: 1920,
                start: 2.771687100000001,
                stop: 3.315187100000003
              }
            ]
          }
        ],
        meta: {
          title: 'A Day in the Life of Marketing Services',
          description:
            'Get a glimpse into the activities and responsibilities of a professional in the field of marketing services.',
          hashtags:
            '#marketingservices #digitalmarketing #contentstrategy #dataanalytics #creative #analytical #organized #shorts',
          mediaDescription:
            'A picture of a marketing services professional in the office, surrounded by cutting-edge technology and organized workspace.',
          category: 'Day in the Life',
          prompt: 'marketing services'
        }
      }
    ],
    category: 'Day in the Life',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_uad33u.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688043355/woxo-videos-user-gallery/_dreaming/blob_ea2wax.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Ever wondered what a day in the life of a marketing services looks like?',
            captions:
              'Ever ~ *wondered* ~ what ~ a ~ *day* ~ in ~ the ~ *life* ~ of ~ a ~ *marketing* ~ services ~ *looks* ~ *like?*'
          },
          {
            voiceover:
              "From strategizing digital campaigns, to creating engaging content, to analyzing data and managing budgets, it's a day filled with activities. ",
            captions:
              "From ~ *strategizing* ~ *digital* ~ *campaigns,* ~ to ~ *creating* ~ *engaging* ~ content, ~ to ~ *analyzing* ~ data ~ and ~ *managing* ~ *budgets,* ~ it's ~ a ~ *day* ~ filled ~ with ~ activities"
          },
          {
            voiceover:
              'Marketing Services professionals need to be creative, analytical and organized to succeed in the field.',
            captions:
              'Marketing ~ Services ~ *professionals* ~ need ~ to ~ be ~ *creative,* ~ *analytical* ~ and ~ *organized* ~ to ~ *succeed* ~ in ~ the ~ *field*'
          },
          {
            voiceover: 'Follow us to learn more about marketing services and its various facets.',
            captions:
              'Follow ~ us ~ to ~ *learn* ~ more ~ about ~ *marketing* ~ services ~ and ~ its ~ *various* ~ *facets*'
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, about a day in life of marketing services and return it as a VALID array of JSON objects (Language English)\n  Example:\n  [\n   {\n    "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: marketing services",\n     "scenes": [\n      {\n        "voiceover": "A hook similar but not the same to the following ->  How does a day in the life of marketing services look like?"\n      },\n      {\n        "voiceover": "A brief description about some of the activities that marketing services do during a regular day. E.g: He wake up early on and eat his lunch at 5pm (max 30 words)"\n      },\n      {\n        "voiceover": "Scene 2 continuation... (max 30 words)"\n      },  \n      {\n        "voiceover": "Scene 3 continuation... (max 30 words)"\n      },        \n      {\n        "voiceover": "A CTA to follow me for more"\n      }\n     ],\n     "meta": {\n      "title": "Video Title",\n      "description": "A short description of the video for Youtube",\n      "hashtags": "a list of hashtags separated by space related to the video",   \n      "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: marketing services"  \n     }\n   }\n]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/TLtgM0jGrh/rUsPS.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Ever  wondered  what  a  day  in  the  life  of  a  marketing  services  looks  like?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">From  strategizing  digital  campaigns,  to  creating  engaging  content,  to  analyzing  data  and  managing  budgets,  it\'s  a  day  filled  with  activities</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">Marketing  Services  professionals  need  to  be  creative,  analytical  and  organized  to  succeed  in  the  field</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Follow  us  to  learn  more  about  marketing  services  and  its  various  facets</prosody><break time="1000ms"/><bookmark name="scene_3"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 3
              },
              {
                markName: 'scene_1',
                timeSeconds: 12.184812
              },
              {
                markName: 'scene_2',
                timeSeconds: 18.1739379
              },
              {
                markName: 'scene_3',
                timeSeconds: 22.489125
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Ever',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 262,
                wordLength: 4
              },
              {
                word: 'wondered',
                timeSeconds: 0.304375,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 268,
                wordLength: 8
              },
              {
                word: 'what',
                timeSeconds: 0.630438,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 278,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.782625,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 284,
                wordLength: 1
              },
              {
                word: 'day',
                timeSeconds: 0.847812,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 287,
                wordLength: 3
              },
              {
                word: 'in',
                timeSeconds: 1.054375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 292,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 1.130438,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 296,
                wordLength: 3
              },
              {
                word: 'life',
                timeSeconds: 1.2065,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 301,
                wordLength: 4
              },
              {
                word: 'of',
                timeSeconds: 1.47825,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 307,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 1.5435,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 311,
                wordLength: 1
              },
              {
                word: 'marketing',
                timeSeconds: 1.5978119,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 314,
                wordLength: 9
              },
              {
                word: 'services',
                timeSeconds: 2.010875,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 325,
                wordLength: 8
              },
              {
                word: 'looks',
                timeSeconds: 2.467375,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 335,
                wordLength: 5
              },
              {
                word: 'like',
                timeSeconds: 2.7065,
                boundaryType: 'WordBoundary',
                duration: 0.2935,
                textOffset: 342,
                wordLength: 4
              },
              {
                word: '?',
                timeSeconds: 3.010875,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 346,
                wordLength: 1
              },
              {
                word: 'From',
                timeSeconds: 4.760875,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 429,
                wordLength: 4
              },
              {
                word: 'strategizing',
                timeSeconds: 4.97825,
                boundaryType: 'WordBoundary',
                duration: 0.739125,
                textOffset: 435,
                wordLength: 12
              },
              {
                word: 'digital',
                timeSeconds: 5.72825,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 449,
                wordLength: 7
              },
              {
                word: 'campaigns',
                timeSeconds: 6.054375,
                boundaryType: 'WordBoundary',
                duration: 0.684812,
                textOffset: 458,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 6.826062,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 467,
                wordLength: 1
              },
              {
                word: 'to',
                timeSeconds: 6.913062,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 470,
                wordLength: 2
              },
              {
                word: 'creating',
                timeSeconds: 7.02175,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 474,
                wordLength: 8
              },
              {
                word: 'engaging',
                timeSeconds: 7.380438,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 484,
                wordLength: 8
              },
              {
                word: 'content',
                timeSeconds: 7.836938,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 494,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 8.391312,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 501,
                wordLength: 1
              },
              {
                word: 'to',
                timeSeconds: 8.47825,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 504,
                wordLength: 2
              },
              {
                word: 'analyzing',
                timeSeconds: 8.597812,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 508,
                wordLength: 9
              },
              {
                word: 'data',
                timeSeconds: 9.152188,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 519,
                wordLength: 4
              },
              {
                word: 'and',
                timeSeconds: 9.47825,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 525,
                wordLength: 3
              },
              {
                word: 'managing',
                timeSeconds: 9.5435,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 530,
                wordLength: 8
              },
              {
                word: 'budgets',
                timeSeconds: 9.97825,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 540,
                wordLength: 7
              },
              {
                word: ',',
                timeSeconds: 10.52175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.108688,
                textOffset: 547,
                wordLength: 1
              },
              {
                word: "it's",
                timeSeconds: 10.630438,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 550,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 10.760875,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 556,
                wordLength: 1
              },
              {
                word: 'day',
                timeSeconds: 10.869562,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 559,
                wordLength: 3
              },
              {
                word: 'filled',
                timeSeconds: 11.141312,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 564,
                wordLength: 6
              },
              {
                word: 'with',
                timeSeconds: 11.413062,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 572,
                wordLength: 4
              },
              {
                word: 'activities',
                timeSeconds: 11.532625,
                boundaryType: 'WordBoundary',
                duration: 0.652188,
                textOffset: 578,
                wordLength: 10
              },
              {
                word: 'Marketing',
                timeSeconds: 13.184812,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 670,
                wordLength: 9
              },
              {
                word: 'Services',
                timeSeconds: 13.586938,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 681,
                wordLength: 8
              },
              {
                word: 'professionals',
                timeSeconds: 14.086938,
                boundaryType: 'WordBoundary',
                duration: 0.630438,
                textOffset: 691,
                wordLength: 13
              },
              {
                word: 'need',
                timeSeconds: 14.77175,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 706,
                wordLength: 4
              },
              {
                word: 'to',
                timeSeconds: 14.9565,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 712,
                wordLength: 2
              },
              {
                word: 'be',
                timeSeconds: 15.032625,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 716,
                wordLength: 2
              },
              {
                word: 'creative',
                timeSeconds: 15.173938,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 720,
                wordLength: 8
              },
              {
                word: ',',
                timeSeconds: 15.717375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 728,
                wordLength: 1
              },
              {
                word: 'analytical',
                timeSeconds: 15.804375,
                boundaryType: 'WordBoundary',
                duration: 0.586938,
                textOffset: 731,
                wordLength: 10
              },
              {
                word: 'and',
                timeSeconds: 16.489125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 743,
                wordLength: 3
              },
              {
                word: 'organized',
                timeSeconds: 16.576062,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 748,
                wordLength: 9
              },
              {
                word: 'to',
                timeSeconds: 17.032625,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 759,
                wordLength: 2
              },
              {
                word: 'succeed',
                timeSeconds: 17.1086879,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 763,
                wordLength: 7
              },
              {
                word: 'in',
                timeSeconds: 17.576062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 772,
                wordLength: 2
              },
              {
                word: 'the',
                timeSeconds: 17.6521879,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 776,
                wordLength: 3
              },
              {
                word: 'field',
                timeSeconds: 17.739125,
                boundaryType: 'WordBoundary',
                duration: 0.434812,
                textOffset: 781,
                wordLength: 5
              },
              {
                word: 'Follow',
                timeSeconds: 19.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 868,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 19.489125,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 876,
                wordLength: 2
              },
              {
                word: 'to',
                timeSeconds: 19.576062,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 880,
                wordLength: 2
              },
              {
                word: 'learn',
                timeSeconds: 19.6739379,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 884,
                wordLength: 5
              },
              {
                word: 'more',
                timeSeconds: 19.891312,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 891,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 20.0651879,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 897,
                wordLength: 5
              },
              {
                word: 'marketing',
                timeSeconds: 20.304375,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 904,
                wordLength: 9
              },
              {
                word: 'services',
                timeSeconds: 20.695625,
                boundaryType: 'WordBoundary',
                duration: 0.554375,
                textOffset: 915,
                wordLength: 8
              },
              {
                word: 'and',
                timeSeconds: 21.2935,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 925,
                wordLength: 3
              },
              {
                word: 'its',
                timeSeconds: 21.4239379,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 930,
                wordLength: 3
              },
              {
                word: 'various',
                timeSeconds: 21.554375,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 935,
                wordLength: 7
              },
              {
                word: 'facets',
                timeSeconds: 21.945625,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 944,
                wordLength: 6
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Inspirational/Barton_Springs_-_Bird_Creek.mp3'
    ]
  },
  {
    id: '69f34df1-e416-4063-87d6-608b5fc04f0e',
    text: 'A Horror Story about "marketing services"',
    videos: [
      {
        id: '3bf6772f-b913-4b35-855b-962024f099bb',
        proportion: 0.232,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/One_mission_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/qCp1B_gfN8/P8uTJ.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 5.2391250000000005,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_kacxyz.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ARE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.1739379
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU ',
                width: 1080,
                height: 1920,
                start: 0.1739379,
                stop: 0.27175
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *READY* ',
                width: 1080,
                height: 1920,
                start: 0.27175,
                stop: 0.489125
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.489125,
                stop: 0.554375
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HEAR ',
                width: 1080,
                height: 1920,
                start: 0.554375,
                stop: 0.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.826062,
                stop: 0.826062
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CHILLING* ',
                width: 1080,
                height: 1920,
                start: 0.826062,
                stop: 1.1413119
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TALE* ',
                width: 1080,
                height: 1920,
                start: 1.1413119,
                stop: 1.358688
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ABOUT ',
                width: 1080,
                height: 1920,
                start: 1.358688,
                stop: 1.586938
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 1.586938,
                stop: 1.9565
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SERVICES?',
                width: 1080,
                height: 1920,
                start: 1.9565,
                stop: 2.6304369999999997
              }
            ]
          },
          {
            duration: 10.27175,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_hwjpo5.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'ONCE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.2608749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UPON ',
                width: 1080,
                height: 1920,
                start: 0.2608749999999995,
                stop: 0.5108749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 0.5108749999999995,
                stop: 0.5651869999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TIME, ',
                width: 1080,
                height: 1920,
                start: 0.5651869999999999,
                stop: 1.0869369999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 1.0869369999999998,
                stop: 1.1304369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMPANY* ',
                width: 1080,
                height: 1920,
                start: 1.1304369999999997,
                stop: 1.4564999999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DECIDED ',
                width: 1080,
                height: 1920,
                start: 1.4564999999999992,
                stop: 1.8804369999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.8804369999999997,
                stop: 1.9673749999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *OUTSOURCE* ',
                width: 1080,
                height: 1920,
                start: 1.9673749999999997,
                stop: 2.47825
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.47825,
                stop: 2.586937
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKETING* ',
                width: 1080,
                height: 1920,
                start: 2.586937,
                stop: 3.0108749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3.0108749999999995,
                stop: 3.1521869999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 3.1521869999999996,
                stop: 3.2064999999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MYSTERIOUS* ',
                width: 1080,
                height: 1920,
                start: 3.2064999999999992,
                stop: 3.7608749999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *AGENCY*. ',
                width: 1080,
                height: 1920,
                start: 3.7608749999999995,
                stop: 5.054313
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LITTLE ',
                width: 1080,
                height: 1920,
                start: 5.054313,
                stop: 5.282562999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DID ',
                width: 1080,
                height: 1920,
                start: 5.282562999999999,
                stop: 5.43475
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEY ',
                width: 1080,
                height: 1920,
                start: 5.43475,
                stop: 5.543437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *KNOW*, ',
                width: 1080,
                height: 1920,
                start: 5.543437,
                stop: 5.93475
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS ',
                width: 1080,
                height: 1920,
                start: 5.93475,
                stop: 6.108686999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AGENCY ',
                width: 1080,
                height: 1920,
                start: 6.108686999999999,
                stop: 6.543437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 6.543437,
                stop: 6.706499999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' RUN ',
                width: 1080,
                height: 1920,
                start: 6.706499999999999,
                stop: 6.923875
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BY ',
                width: 1080,
                height: 1920,
                start: 6.923875,
                stop: 7.065186999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 7.065186999999999,
                stop: 7.141312999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COVEN* ',
                width: 1080,
                height: 1920,
                start: 7.141312999999999,
                stop: 7.43475
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 7.43475,
                stop: 7.543437
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WICKED* ',
                width: 1080,
                height: 1920,
                start: 7.543437,
                stop: 7.826063
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITCHES',
                width: 1080,
                height: 1920,
                start: 7.826063,
                stop: 8.271688000000001
              }
            ]
          },
          {
            duration: 11.3043129,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_kacxyz.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'THE ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.08693699999999893
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *WITCHES* ',
                width: 1080,
                height: 1920,
                start: 0.08693699999999893,
                stop: 0.41300000000000026
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' USED ',
                width: 1080,
                height: 1920,
                start: 0.41300000000000026,
                stop: 0.6630000000000003
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *DARK* ',
                width: 1080,
                height: 1920,
                start: 0.6630000000000003,
                stop: 0.9238749999999989
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MAGIC* ',
                width: 1080,
                height: 1920,
                start: 0.9238749999999989,
                stop: 1.3804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 1.3804370000000006,
                stop: 1.4782499999999992
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *LURE* ',
                width: 1080,
                height: 1920,
                start: 1.4782499999999992,
                stop: 1.7065000000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IN ',
                width: 1080,
                height: 1920,
                start: 1.7065000000000001,
                stop: 1.793436999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' UNSUSPECTING ',
                width: 1080,
                height: 1920,
                start: 1.793436999999999,
                stop: 2.4564999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CUSTOMERS, ',
                width: 1080,
                height: 1920,
                start: 2.4564999999999984,
                stop: 3.2173750000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 3.2173750000000005,
                stop: 3.3586869999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SOON ',
                width: 1080,
                height: 1920,
                start: 3.3586869999999998,
                stop: 3.6412499999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 3.6412499999999994,
                stop: 3.7825628999999985
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSINESS* ',
                width: 1080,
                height: 1920,
                start: 3.7825628999999985,
                stop: 4.141249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SOARED*. ',
                width: 1080,
                height: 1920,
                start: 4.141249999999999,
                stop: 5.293437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' BUT ',
                width: 1080,
                height: 1920,
                start: 5.293437000000001,
                stop: 5.413
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' IT ',
                width: 1080,
                height: 1920,
                start: 5.413,
                stop: 5.489125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CAME ',
                width: 1080,
                height: 1920,
                start: 5.489125000000001,
                stop: 5.695625
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITH ',
                width: 1080,
                height: 1920,
                start: 5.695625,
                stop: 5.815186999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' A ',
                width: 1080,
                height: 1920,
                start: 5.815186999999998,
                stop: 5.880437000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PRICE*. ',
                width: 1080,
                height: 1920,
                start: 5.880437000000001,
                stop: 7.141249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 7.141249999999999,
                stop: 7.260812900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WITCHES ',
                width: 1080,
                height: 1920,
                start: 7.260812900000001,
                stop: 7.608687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HAD ',
                width: 1080,
                height: 1920,
                start: 7.608687,
                stop: 7.739125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *CURSED* ',
                width: 1080,
                height: 1920,
                start: 7.739125000000001,
                stop: 8.021687
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 8.021687,
                stop: 8.097812900000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMPANY*...',
                width: 1080,
                height: 1920,
                start: 8.097812900000001,
                stop: 8.652124999999998
              }
            ]
          },
          {
            duration: 10.6956871,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_hwjpo5.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'EVERY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.2608740999999988
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *NIGHT*, ',
                width: 1080,
                height: 1920,
                start: 0.2608740999999988,
                stop: 0.6739370999999998
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *GHOSTLY* ',
                width: 1080,
                height: 1920,
                start: 0.6739370999999998,
                stop: 1.065249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FIGURES* ',
                width: 1080,
                height: 1920,
                start: 1.065249999999999,
                stop: 1.4891241000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *ROAMED* ',
                width: 1080,
                height: 1920,
                start: 1.4891241000000015,
                stop: 1.6956871000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 1.6956871000000007,
                stop: 1.75
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HALLS ',
                width: 1080,
                height: 1920,
                start: 1.75,
                stop: 2.0326240999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' OF ',
                width: 1080,
                height: 1920,
                start: 2.0326240999999996,
                stop: 2.0869371
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 2.0869371,
                stop: 2.228249999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *OFFICE*. ',
                width: 1080,
                height: 1920,
                start: 2.228249999999999,
                stop: 3.4456871000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' CUSTOMERS ',
                width: 1080,
                height: 1920,
                start: 3.4456871000000007,
                stop: 3.9456871000000007
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DESERTED ',
                width: 1080,
                height: 1920,
                start: 3.9456871000000007,
                stop: 4.358687100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEM, ',
                width: 1080,
                height: 1920,
                start: 4.358687100000001,
                stop: 4.7391241000000015
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.7391241000000015,
                stop: 4.793500099999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 4.793500099999999,
                stop: 4.945687100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PROFITS* ',
                width: 1080,
                height: 1920,
                start: 4.945687100000001,
                stop: 5.358687100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *PLUMMETED*. ',
                width: 1080,
                height: 1920,
                start: 5.358687100000001,
                stop: 6.597812099999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THE ',
                width: 1080,
                height: 1920,
                start: 6.597812099999999,
                stop: 6.7065000999999995
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *COMPANY* ',
                width: 1080,
                height: 1920,
                start: 6.7065000999999995,
                stop: 7.021750100000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' WAS ',
                width: 1080,
                height: 1920,
                start: 7.021750100000002,
                stop: 7.1739371
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' DOOMED ',
                width: 1080,
                height: 1920,
                start: 7.1739371,
                stop: 7.413062100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 7.413062100000001,
                stop: 7.4782501
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' SUFFER ',
                width: 1080,
                height: 1920,
                start: 7.4782501,
                stop: 7.8152501
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THEIR ',
                width: 1080,
                height: 1920,
                start: 7.8152501,
                stop: 7.945687100000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FATE* ',
                width: 1080,
                height: 1920,
                start: 7.945687100000001,
                stop: 8.2065001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FOREVER*',
                width: 1080,
                height: 1920,
                start: 8.2065001,
                stop: 8.6956251
              }
            ]
          },
          {
            duration: 5.989125000000001,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_kacxyz.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: "DON'T ",
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.17387500000000244
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' LET ',
                width: 1080,
                height: 1920,
                start: 0.17387500000000244,
                stop: 0.3043130000000005
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' THIS ',
                width: 1080,
                height: 1920,
                start: 0.3043130000000005,
                stop: 0.45649999999999835
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *HAPPEN* ',
                width: 1080,
                height: 1920,
                start: 0.45649999999999835,
                stop: 0.717374999999997
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 0.717374999999997,
                stop: 0.7934369999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOU. ',
                width: 1080,
                height: 1920,
                start: 0.7934369999999973,
                stop: 1.8586869999999962
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *FOLLOW* ',
                width: 1080,
                height: 1920,
                start: 1.8586869999999962,
                stop: 2.2064999999999984
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' US ',
                width: 1080,
                height: 1920,
                start: 2.2064999999999984,
                stop: 2.2934369999999973
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' FOR ',
                width: 1080,
                height: 1920,
                start: 2.2934369999999973,
                stop: 2.434750000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *TIPS* ',
                width: 1080,
                height: 1920,
                start: 2.434750000000001,
                stop: 2.760812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' ON ',
                width: 1080,
                height: 1920,
                start: 2.760812999999999,
                stop: 2.8804370000000006
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' HOW ',
                width: 1080,
                height: 1920,
                start: 2.8804370000000006,
                stop: 3
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' TO ',
                width: 1080,
                height: 1920,
                start: 3,
                stop: 3.0651870000000017
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *MARKET* ',
                width: 1080,
                height: 1920,
                start: 3.0651870000000017,
                stop: 3.3912499999999994
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' YOUR ',
                width: 1080,
                height: 1920,
                start: 3.3912499999999994,
                stop: 3.510812999999999
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFE00',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *BUSINESS* ',
                width: 1080,
                height: 1920,
                start: 3.510812999999999,
                stop: 3.847813000000002
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 120,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *SAFELY* ',
                width: 1080,
                height: 1920,
                start: 3.847813000000002,
                stop: 4.358686999999996
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' AND ',
                width: 1080,
                height: 1920,
                start: 4.358686999999996,
                stop: 4.489125000000001
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#00FB2F',
                backgroundColor: '#00FB2F',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: ' *RESPONSIBLY*',
                width: 1080,
                height: 1920,
                start: 4.489125000000001,
                stop: 5.206499999999998
              }
            ]
          }
        ],
        meta: {
          title: 'The Curse of the Marketing Witches',
          description:
            'Marketing can be a tricky business. Learn how to avoid dark magic and save your company from a cursed fate.',
          hashtags: '#Marketing #Witches #Cursed #Fate #Business #Tip #shorts',
          mediaDescription:
            'A group of witches hovering over a cauldron, casting a spell of dark magic.',
          category: 'Horror Story',
          prompt: 'marketing services'
        }
      }
    ],
    category: 'Horror Story',
    isLoading: true,
    errors: [],
    medias: [
      [
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_kacxyz.png',
        'http://res.cloudinary.com/dakp804eh/image/upload/v1688042198/woxo-videos-user-gallery/_dreaming/blob_hwjpo5.png'
      ]
    ],
    voiceover: [
      {
        scenes: [
          {
            voiceover: 'Are you ready to hear a chilling tale about marketing services?',
            captions:
              'Are ~ you ~ *ready* ~ to ~ hear ~ a ~ *chilling* ~ *tale* ~ about ~ *marketing* ~ services?'
          },
          {
            voiceover:
              'Once upon a time, a company decided to outsource their marketing to a mysterious agency. Little did they know, this agency was run by a coven of wicked witches.',
            captions:
              'Once ~ upon ~ a ~ time, ~ a ~ *company* ~ decided ~ to ~ *outsource* ~ their ~ *marketing* ~ to ~ a ~ *mysterious* ~ *agency*. ~ Little ~ did ~ they ~ *know*, ~ this ~ agency ~ was ~ run ~ by ~ a ~ *coven* ~ of ~ *wicked* ~ witches'
          },
          {
            voiceover:
              'The witches used dark magic to lure in unsuspecting customers, and soon their business soared. But it came with a price. The witches had cursed the company...',
            captions:
              'The ~ *witches* ~ used ~ *dark* ~ *magic* ~ to ~ *lure* ~ in ~ unsuspecting ~ customers, ~ and ~ soon ~ their ~ *business* ~ *soared*. ~ But ~ it ~ came ~ with ~ a ~ *price*. ~ The ~ witches ~ had ~ *cursed* ~ the ~ *company*...'
          },
          {
            voiceover:
              'Every night, ghostly figures roamed the halls of their office. Customers deserted them, and their profits plummeted. The company was doomed to suffer their fate forever.',
            captions:
              'Every ~ *night*, ~ *ghostly* ~ *figures* ~ *roamed* ~ the ~ halls ~ of ~ their ~ *office*. ~ Customers ~ deserted ~ them, ~ and ~ their ~ *profits* ~ *plummeted*. ~ The ~ *company* ~ was ~ doomed ~ to ~ suffer ~ their ~ *fate* ~ *forever*'
          },
          {
            voiceover:
              "Don't let this happen to you. Follow us for tips on how to market your business safely and responsibly.",
            captions:
              "Don't ~ let ~ this ~ *happen* ~ to ~ you. ~ *Follow* ~ us ~ for ~ *tips* ~ on ~ how ~ to ~ *market* ~ your ~ *business* ~ *safely* ~ and ~ *responsibly*"
          }
        ]
      }
    ],
    creation: {
      time: '00:00',
      step: 0
    },
    prompts:
      'Write 1 short video script of 5 scenes, with a horror story about marketing services and return it as a VALID array of JSON objects (Language English)\n   Example:\n   [\n    {\n      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: marketing services",          \n      "scenes": [\n        {\n          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an scary story about marketing services?"\n        },\n        {\n          "voiceover": "Write an horror story about marketing services (max 30 words)"\n        },\n        {\n          "voiceover": "Scene 2 continuation... (max 30 words)"\n        },  \n        {\n          "voiceover": "Scene 3 continuation... (max 30 words)"\n        },          \n        {\n          "voiceover": "A CTA to follow me for more"\n        }\n      ],\n      "meta": {\n        "title": "Video Title",\n        "description": "A short description of the video for Youtube",\n        "hashtags": "a list of hashtags separated by space related to the video",   \n        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: marketing services"\n      }\n    }\n ]',
    voiceoverAudio: {
      disabled: false,
      audios: [
        {
          audio: {
            path: 'https://vod.front10.cloud/output/qCp1B_gfN8/P8uTJ.mp3',
            text: '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-TonyNeural"><mstts:express-as style="Default"><prosody rate="+15.00%">Are  you  ready  to  hear  a  chilling  tale  about  marketing  services?</prosody><break time="1000ms"/><bookmark name="scene_0"/><prosody rate="+15.00%">Once  upon  a  time,  a  company  decided  to  outsource  their  marketing  to  a  mysterious  agency.  Little  did  they  know,  this  agency  was  run  by  a  coven  of  wicked  witches</prosody><break time="1000ms"/><bookmark name="scene_1"/><prosody rate="+15.00%">The  witches  used  dark  magic  to  lure  in  unsuspecting  customers,  and  soon  their  business  soared.  But  it  came  with  a  price.  The  witches  had  cursed  the  company...</prosody><break time="1000ms"/><bookmark name="scene_2"/><prosody rate="+15.00%">Every  night,  ghostly  figures  roamed  the  halls  of  their  office.  Customers  deserted  them,  and  their  profits  plummeted.  The  company  was  doomed  to  suffer  their  fate  forever</prosody><break time="1000ms"/><bookmark name="scene_3"/><prosody rate="+15.00%">Don\'t  let  this  happen  to  you.  Follow  us  for  tips  on  how  to  market  your  business  safely  and  responsibly</prosody><break time="1000ms"/><bookmark name="scene_4"/></mstts:express-as></voice></speak>',
            timepoints: [
              {
                markName: 'scene_0',
                timeSeconds: 2.521688
              },
              {
                markName: 'scene_1',
                timeSeconds: 12.554312
              },
              {
                markName: 'scene_2',
                timeSeconds: 22.1086879
              },
              {
                markName: 'scene_3',
                timeSeconds: 32.554312
              },
              {
                markName: 'scene_4',
                timeSeconds: 38.760875
              }
            ],
            wordBoundaryTimepoints: [
              {
                word: 'Are',
                timeSeconds: 0.0435,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 262,
                wordLength: 3
              },
              {
                word: 'you',
                timeSeconds: 0.1739379,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 267,
                wordLength: 3
              },
              {
                word: 'ready',
                timeSeconds: 0.27175,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 272,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 0.489125,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 279,
                wordLength: 2
              },
              {
                word: 'hear',
                timeSeconds: 0.554375,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 283,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 0.760875,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 289,
                wordLength: 1
              },
              {
                word: 'chilling',
                timeSeconds: 0.826062,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 292,
                wordLength: 8
              },
              {
                word: 'tale',
                timeSeconds: 1.1413119,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 302,
                wordLength: 4
              },
              {
                word: 'about',
                timeSeconds: 1.358688,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 308,
                wordLength: 5
              },
              {
                word: 'marketing',
                timeSeconds: 1.586938,
                boundaryType: 'WordBoundary',
                duration: 0.358688,
                textOffset: 315,
                wordLength: 9
              },
              {
                word: 'services',
                timeSeconds: 1.9565,
                boundaryType: 'WordBoundary',
                duration: 0.565188,
                textOffset: 326,
                wordLength: 8
              },
              {
                word: '?',
                timeSeconds: 2.532625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 334,
                wordLength: 1
              },
              {
                word: 'Once',
                timeSeconds: 4.282625,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 417,
                wordLength: 4
              },
              {
                word: 'upon',
                timeSeconds: 4.5435,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 423,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 4.7935,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 429,
                wordLength: 1
              },
              {
                word: 'time',
                timeSeconds: 4.847812,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 432,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 5.27175,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 436,
                wordLength: 1
              },
              {
                word: 'a',
                timeSeconds: 5.369562,
                boundaryType: 'WordBoundary',
                duration: 0.032625,
                textOffset: 439,
                wordLength: 1
              },
              {
                word: 'company',
                timeSeconds: 5.413062,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 442,
                wordLength: 7
              },
              {
                word: 'decided',
                timeSeconds: 5.739125,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 451,
                wordLength: 7
              },
              {
                word: 'to',
                timeSeconds: 6.163062,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 460,
                wordLength: 2
              },
              {
                word: 'outsource',
                timeSeconds: 6.25,
                boundaryType: 'WordBoundary',
                duration: 0.47825,
                textOffset: 464,
                wordLength: 9
              },
              {
                word: 'their',
                timeSeconds: 6.760875,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 475,
                wordLength: 5
              },
              {
                word: 'marketing',
                timeSeconds: 6.869562,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 482,
                wordLength: 9
              },
              {
                word: 'to',
                timeSeconds: 7.2935,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 493,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 7.434812,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 497,
                wordLength: 1
              },
              {
                word: 'mysterious',
                timeSeconds: 7.489125,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 500,
                wordLength: 10
              },
              {
                word: 'agency',
                timeSeconds: 8.0435,
                boundaryType: 'WordBoundary',
                duration: 0.5435,
                textOffset: 512,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 8.597812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 518,
                wordLength: 1
              },
              {
                word: 'Little',
                timeSeconds: 9.336938,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 521,
                wordLength: 6
              },
              {
                word: 'did',
                timeSeconds: 9.565188,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 529,
                wordLength: 3
              },
              {
                word: 'they',
                timeSeconds: 9.717375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 534,
                wordLength: 4
              },
              {
                word: 'know',
                timeSeconds: 9.826062,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 540,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 10.152188,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 544,
                wordLength: 1
              },
              {
                word: 'this',
                timeSeconds: 10.217375,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 547,
                wordLength: 4
              },
              {
                word: 'agency',
                timeSeconds: 10.391312,
                boundaryType: 'WordBoundary',
                duration: 0.413062,
                textOffset: 553,
                wordLength: 6
              },
              {
                word: 'was',
                timeSeconds: 10.826062,
                boundaryType: 'WordBoundary',
                duration: 0.1521879,
                textOffset: 561,
                wordLength: 3
              },
              {
                word: 'run',
                timeSeconds: 10.989125,
                boundaryType: 'WordBoundary',
                duration: 0.2065,
                textOffset: 566,
                wordLength: 3
              },
              {
                word: 'by',
                timeSeconds: 11.2065,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 571,
                wordLength: 2
              },
              {
                word: 'a',
                timeSeconds: 11.347812,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 575,
                wordLength: 1
              },
              {
                word: 'coven',
                timeSeconds: 11.423938,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 578,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 11.717375,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 585,
                wordLength: 2
              },
              {
                word: 'wicked',
                timeSeconds: 11.826062,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 589,
                wordLength: 6
              },
              {
                word: 'witches',
                timeSeconds: 12.108688,
                boundaryType: 'WordBoundary',
                duration: 0.445625,
                textOffset: 597,
                wordLength: 7
              },
              {
                word: 'The',
                timeSeconds: 13.554375,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 686,
                wordLength: 3
              },
              {
                word: 'witches',
                timeSeconds: 13.641312,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 691,
                wordLength: 7
              },
              {
                word: 'used',
                timeSeconds: 13.967375,
                boundaryType: 'WordBoundary',
                duration: 0.239125,
                textOffset: 700,
                wordLength: 4
              },
              {
                word: 'dark',
                timeSeconds: 14.217375,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 706,
                wordLength: 4
              },
              {
                word: 'magic',
                timeSeconds: 14.47825,
                boundaryType: 'WordBoundary',
                duration: 0.423938,
                textOffset: 712,
                wordLength: 5
              },
              {
                word: 'to',
                timeSeconds: 14.934812,
                boundaryType: 'WordBoundary',
                duration: 0.086938,
                textOffset: 719,
                wordLength: 2
              },
              {
                word: 'lure',
                timeSeconds: 15.032625,
                boundaryType: 'WordBoundary',
                duration: 0.217375,
                textOffset: 723,
                wordLength: 4
              },
              {
                word: 'in',
                timeSeconds: 15.260875,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 729,
                wordLength: 2
              },
              {
                word: 'unsuspecting',
                timeSeconds: 15.347812,
                boundaryType: 'WordBoundary',
                duration: 0.652188,
                textOffset: 733,
                wordLength: 12
              },
              {
                word: 'customers',
                timeSeconds: 16.010875,
                boundaryType: 'WordBoundary',
                duration: 0.597812,
                textOffset: 747,
                wordLength: 9
              },
              {
                word: ',',
                timeSeconds: 16.695625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.076062,
                textOffset: 756,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 16.77175,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 759,
                wordLength: 3
              },
              {
                word: 'soon',
                timeSeconds: 16.913062,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 764,
                wordLength: 4
              },
              {
                word: 'their',
                timeSeconds: 17.195625,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 770,
                wordLength: 5
              },
              {
                word: 'business',
                timeSeconds: 17.3369379,
                boundaryType: 'WordBoundary',
                duration: 0.347812,
                textOffset: 777,
                wordLength: 8
              },
              {
                word: 'soared',
                timeSeconds: 17.695625,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 787,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 18.097812,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 793,
                wordLength: 1
              },
              {
                word: 'But',
                timeSeconds: 18.847812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 796,
                wordLength: 3
              },
              {
                word: 'it',
                timeSeconds: 18.967375,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 801,
                wordLength: 2
              },
              {
                word: 'came',
                timeSeconds: 19.0435,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 805,
                wordLength: 4
              },
              {
                word: 'with',
                timeSeconds: 19.25,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 811,
                wordLength: 4
              },
              {
                word: 'a',
                timeSeconds: 19.369562,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 817,
                wordLength: 1
              },
              {
                word: 'price',
                timeSeconds: 19.434812,
                boundaryType: 'WordBoundary',
                duration: 0.5,
                textOffset: 820,
                wordLength: 5
              },
              {
                word: '.',
                timeSeconds: 19.945625,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 825,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 20.695625,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 828,
                wordLength: 3
              },
              {
                word: 'witches',
                timeSeconds: 20.8151879,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 833,
                wordLength: 7
              },
              {
                word: 'had',
                timeSeconds: 21.163062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 842,
                wordLength: 3
              },
              {
                word: 'cursed',
                timeSeconds: 21.2935,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 847,
                wordLength: 6
              },
              {
                word: 'the',
                timeSeconds: 21.576062,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 855,
                wordLength: 3
              },
              {
                word: 'company',
                timeSeconds: 21.6521879,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 860,
                wordLength: 7
              },
              {
                word: '...',
                timeSeconds: 22.119562,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 867,
                wordLength: 3
              },
              {
                word: 'Every',
                timeSeconds: 23.8586879,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 952,
                wordLength: 5
              },
              {
                word: 'night',
                timeSeconds: 24.119562,
                boundaryType: 'WordBoundary',
                duration: 0.260875,
                textOffset: 959,
                wordLength: 5
              },
              {
                word: ',',
                timeSeconds: 24.467375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.065188,
                textOffset: 964,
                wordLength: 1
              },
              {
                word: 'ghostly',
                timeSeconds: 24.532625,
                boundaryType: 'WordBoundary',
                duration: 0.380438,
                textOffset: 967,
                wordLength: 7
              },
              {
                word: 'figures',
                timeSeconds: 24.9239379,
                boundaryType: 'WordBoundary',
                duration: 0.391312,
                textOffset: 976,
                wordLength: 7
              },
              {
                word: 'roamed',
                timeSeconds: 25.347812,
                boundaryType: 'WordBoundary',
                duration: 0.195625,
                textOffset: 985,
                wordLength: 6
              },
              {
                word: 'the',
                timeSeconds: 25.554375,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 993,
                wordLength: 3
              },
              {
                word: 'halls',
                timeSeconds: 25.6086879,
                boundaryType: 'WordBoundary',
                duration: 0.27175,
                textOffset: 998,
                wordLength: 5
              },
              {
                word: 'of',
                timeSeconds: 25.891312,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 1005,
                wordLength: 2
              },
              {
                word: 'their',
                timeSeconds: 25.945625,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1009,
                wordLength: 5
              },
              {
                word: 'office',
                timeSeconds: 26.0869379,
                boundaryType: 'WordBoundary',
                duration: 0.4565,
                textOffset: 1016,
                wordLength: 6
              },
              {
                word: '.',
                timeSeconds: 26.554375,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1022,
                wordLength: 1
              },
              {
                word: 'Customers',
                timeSeconds: 27.304375,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 1025,
                wordLength: 9
              },
              {
                word: 'deserted',
                timeSeconds: 27.804375,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 1036,
                wordLength: 8
              },
              {
                word: 'them',
                timeSeconds: 28.217375,
                boundaryType: 'WordBoundary',
                duration: 0.1739379,
                textOffset: 1046,
                wordLength: 4
              },
              {
                word: ',',
                timeSeconds: 28.47825,
                boundaryType: 'PunctuationBoundary',
                duration: 0.119562,
                textOffset: 1050,
                wordLength: 1
              },
              {
                word: 'and',
                timeSeconds: 28.597812,
                boundaryType: 'WordBoundary',
                duration: 0.0435,
                textOffset: 1053,
                wordLength: 3
              },
              {
                word: 'their',
                timeSeconds: 28.652188,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1058,
                wordLength: 5
              },
              {
                word: 'profits',
                timeSeconds: 28.804375,
                boundaryType: 'WordBoundary',
                duration: 0.402188,
                textOffset: 1065,
                wordLength: 7
              },
              {
                word: 'plummeted',
                timeSeconds: 29.217375,
                boundaryType: 'WordBoundary',
                duration: 0.47825,
                textOffset: 1074,
                wordLength: 9
              },
              {
                word: '.',
                timeSeconds: 29.7065,
                boundaryType: 'PunctuationBoundary',
                duration: 0.097812,
                textOffset: 1083,
                wordLength: 1
              },
              {
                word: 'The',
                timeSeconds: 30.4565,
                boundaryType: 'WordBoundary',
                duration: 0.097812,
                textOffset: 1086,
                wordLength: 3
              },
              {
                word: 'company',
                timeSeconds: 30.565188,
                boundaryType: 'WordBoundary',
                duration: 0.304375,
                textOffset: 1091,
                wordLength: 7
              },
              {
                word: 'was',
                timeSeconds: 30.880438,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1100,
                wordLength: 3
              },
              {
                word: 'doomed',
                timeSeconds: 31.032625,
                boundaryType: 'WordBoundary',
                duration: 0.22825,
                textOffset: 1105,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 31.27175,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 1113,
                wordLength: 2
              },
              {
                word: 'suffer',
                timeSeconds: 31.336938,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 1117,
                wordLength: 6
              },
              {
                word: 'their',
                timeSeconds: 31.673938,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1125,
                wordLength: 5
              },
              {
                word: 'fate',
                timeSeconds: 31.804375,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 1132,
                wordLength: 4
              },
              {
                word: 'forever',
                timeSeconds: 32.065188,
                boundaryType: 'WordBoundary',
                duration: 0.489125,
                textOffset: 1138,
                wordLength: 7
              },
              {
                word: "Don't",
                timeSeconds: 33.554375,
                boundaryType: 'WordBoundary',
                duration: 0.163062,
                textOffset: 1227,
                wordLength: 5
              },
              {
                word: 'let',
                timeSeconds: 33.72825,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1234,
                wordLength: 3
              },
              {
                word: 'this',
                timeSeconds: 33.858688,
                boundaryType: 'WordBoundary',
                duration: 0.141312,
                textOffset: 1239,
                wordLength: 4
              },
              {
                word: 'happen',
                timeSeconds: 34.010875,
                boundaryType: 'WordBoundary',
                duration: 0.25,
                textOffset: 1245,
                wordLength: 6
              },
              {
                word: 'to',
                timeSeconds: 34.27175,
                boundaryType: 'WordBoundary',
                duration: 0.065188,
                textOffset: 1253,
                wordLength: 2
              },
              {
                word: 'you',
                timeSeconds: 34.347812,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 1257,
                wordLength: 3
              },
              {
                word: '.',
                timeSeconds: 34.673938,
                boundaryType: 'PunctuationBoundary',
                duration: 0.086938,
                textOffset: 1260,
                wordLength: 1
              },
              {
                word: 'Follow',
                timeSeconds: 35.413062,
                boundaryType: 'WordBoundary',
                duration: 0.336938,
                textOffset: 1263,
                wordLength: 6
              },
              {
                word: 'us',
                timeSeconds: 35.760875,
                boundaryType: 'WordBoundary',
                duration: 0.076062,
                textOffset: 1271,
                wordLength: 2
              },
              {
                word: 'for',
                timeSeconds: 35.847812,
                boundaryType: 'WordBoundary',
                duration: 0.1304379,
                textOffset: 1275,
                wordLength: 3
              },
              {
                word: 'tips',
                timeSeconds: 35.989125,
                boundaryType: 'WordBoundary',
                duration: 0.282625,
                textOffset: 1280,
                wordLength: 4
              },
              {
                word: 'on',
                timeSeconds: 36.315188,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1286,
                wordLength: 2
              },
              {
                word: 'how',
                timeSeconds: 36.434812,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1290,
                wordLength: 3
              },
              {
                word: 'to',
                timeSeconds: 36.554375,
                boundaryType: 'WordBoundary',
                duration: 0.054375,
                textOffset: 1295,
                wordLength: 2
              },
              {
                word: 'market',
                timeSeconds: 36.619562,
                boundaryType: 'WordBoundary',
                duration: 0.315188,
                textOffset: 1299,
                wordLength: 6
              },
              {
                word: 'your',
                timeSeconds: 36.945625,
                boundaryType: 'WordBoundary',
                duration: 0.108688,
                textOffset: 1307,
                wordLength: 4
              },
              {
                word: 'business',
                timeSeconds: 37.065188,
                boundaryType: 'WordBoundary',
                duration: 0.326062,
                textOffset: 1313,
                wordLength: 8
              },
              {
                word: 'safely',
                timeSeconds: 37.402188,
                boundaryType: 'WordBoundary',
                duration: 0.467375,
                textOffset: 1323,
                wordLength: 6
              },
              {
                word: 'and',
                timeSeconds: 37.913062,
                boundaryType: 'WordBoundary',
                duration: 0.119562,
                textOffset: 1331,
                wordLength: 3
              },
              {
                word: 'responsibly',
                timeSeconds: 38.0435,
                boundaryType: 'WordBoundary',
                duration: 0.717375,
                textOffset: 1336,
                wordLength: 11
              }
            ]
          }
        }
      ]
    },
    music: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Dark/One_mission_-_MOODHALO.mp3'
    ]
  }
];
